import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';

import { StorageService } from '../storage/storage.service';


export class TranslationService extends Service<typeof config>{
	
	public wordings:any;
	public locale:string;
	
	
	
	translate(arg:string):string
	{
		let output:string;
		if(!this.wordings || !this.wordings[arg]) output = arg;
		else output = this.wordings[arg][this.locale];
		return output;
	}
	
	replaceVar(str:string, key:string, value:any):string
	{
		str = str.replace(`[${key}]`, value);
		return str;
	}
	
	
	
	
	onDestroy()
	{
		// broadcaster.removeListener('audio_evt');
		
	}
}


let serviceClass:any = TranslationService;
try{ serviceClass = getDependency<TranslationService>(require('theme-iso/base/services/translation/translation.service.user.ts')); }catch(e){}


import * as configSystem from './translation.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/translation/translation.config.user').default;} catch(e){}
config = resolveConfig('translation', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'translation', config)