import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		let t = new gsap.core.Timeline()
		.fromTo('h1', {opacity:0, scale: 0.5}, {opacity:1, scale:1, duration:0.35, ease: "back.out(1.5)"})
		.fromTo('p.legend', {opacity:0, scale: 0.5}, {opacity:1, scale:1, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		;
		
		let domlist = dom.querySelector('.list');
		let count = 0;
		for(let k in domlist.children){
			let dombtn = domlist.children[k];
			t = t.fromTo(dombtn, {scale: 0}, {scale:1, duration:0.2, ease: "back.out(1.9)"}, count === 0 ? '>-0.18' : '>-0.1');
			count++;
			if(count >= 10) break;	//beyond 10, items are overflow hidden
		}
		t = t.fromTo('.btn.validate', {marginBottom: -200}, {marginBottom: 0, duration:0.43, ease: "back.out(2.5)"}, '>+0.1')
		
		return t;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}