import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';



export class HomeModule extends Module<typeof config>{
	
	override onInit(): void {
		let audioService = this.getServiceInstance<AudioService>('audio');
    	audioService.stop('background');
	}
	
	
	playSequence(index:Number)
	{
		console.log('HomeModule.clickPlay');
		
		let userData = this.getServiceInstance<UserDataService>('userdata');
		userData.updateData('indexSequence', index);
		let data = userData.getCurrentData();
		
		
		router.navigate('game');

	}
	
}



let moduleClass:any = HomeModule;
try{ moduleClass = getDependency<HomeModule>(require('theme-iso/base/modules/pages/home/home.module.user.ts')); }catch(e){}


let template:string = getDependency<string>(require('./home.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/home/home.module.user.html'));} catch(e){}



let css = getCSSTemplate(require('./home.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/home/home.module.user.scss'));} catch(e){}
injectStyle(css);



import * as configSystem from './home.config';
import { AudioService } from 'theme/base/services/audio/audio.service';
import userdataConfig from 'theme/base/services/userdata/userdata.config';

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/home/home.config.user').default;} catch(e){}
config = resolveConfig('home', configSystem.default, configUser, process.env.RUNTIME_ENV)



let animsSystem:any = getDependency<string>(require('./home.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/home/home.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};




registerModuleDef(moduleClass, 'home', template, config, anims, css)