import gsap from 'gsap';
export default {
	
	/* 
	transition_from:(dom:HTMLElement):GSAPTimeline =>{
		const timeline:GSAPTimeline = new gsap.core.Timeline();
		let btns = dom.querySelectorAll('div[import="vhs-button"]');
		btns.forEach((a, index) => {
			let multiplier = index % 2 === 0 ? -1 : +1;
			timeline.set(btns[index], {opacity:0, marginLeft:400 * multiplier});
		});
		return timeline;
	},
	*/
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		const timeline:GSAPTimeline = new gsap.core.Timeline();
		timeline.delay(-0.15);
		let btns = dom.querySelectorAll('div[import="vhs-button"]');
		btns.forEach((a, index) => {
			let multiplier = index % 2 === 0 ? -1 : +1;
			timeline.fromTo(btns[index], {opacity:0, marginLeft:400 * multiplier}, {marginLeft:0, opacity: 1, duration: 0.2, delay:-0.07});
			// timeline.to(btns[index], {marginLeft:0, opacity: 1, duration: 0.2, delay:-0.07});
		});
		
		return timeline;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}