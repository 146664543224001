import _, { upperFirst } from 'lodash';


/**
 * resolve config system and user depending on environement
 * check if configUser only defines property present in config
 * merge both config
 */

export function resolveConfig(idmodule:string, config:any, configUser:any, env:string, _runtimeCheckErrors:boolean = null)
{
	// resolve config system and user depending on environement
	let runtimeCheckErrors:boolean = mainConfig.checkRuntimeErrors;
	if(_runtimeCheckErrors !== null) runtimeCheckErrors = _runtimeCheckErrors;
	
	//test that specific env don't define extra props
	//only props that are present in default are part of the schematic
	if(runtimeCheckErrors) checkEnvSchematics(idmodule, config);
	
	//test validity of environments only if runtimeCheckErrors
	let listEnv2 = runtimeCheckErrors ? mainConfig.environments : null;
	config = mergeConfigEnv(env, config, listEnv2);
	if(configUser) configUser = mergeConfigEnv(env, configUser, listEnv2);
	
	// check if configUser only defines property present in config
	if(runtimeCheckErrors){
		for(let k in configUser){
			if(config[k] === undefined) throw new Error(`module "${idmodule}" config : property "${k}" doesn't belong to schematic`);
		}
	}
	
	//merge arrays of 1st level (otherwise, numeric key get overwritten)
	for(var k in config){
		if(configUser && configUser[k] && Array.isArray(configUser[k])){
			//if prefix is _ => merge array
			if(k[0] === '_'){
				config[k] = [...config[k], ...configUser[k]];
				delete configUser[k];
			}
			//else, replace
			else{
				if(configUser[k] !== undefined) config[k] = configUser[k];
			}
		}
	}
	
	// deep merge both config
	return _.merge(config, configUser);
}


/**
 * merge the config object depending on the current environemnt
 * if listEnv is passed : performed a validity check over the env found in config
 */

export function mergeConfigEnv(env:string, config:any, listEnv:string[] = null):any
{
	if(listEnv){
		for(let env in config) if(env !== "default" && listEnv.indexOf(env) === -1) throw new Error(`env "${env}" is not defined`);
	}
	
	//merge arrays of 1st level (otherwise, numeric key get overwritten)
	for(var k in config.default){
		if(config[env] && config[env][k] && Array.isArray(config[env][k])){
			config.default[k] = [...config.default[k], ...config[env][k]];
			delete config[env][k];
		}
	}
	
	return _.merge(config.default, config[env]);
}



/**
 * check that all properties in specific environments are also in "default"
 * (requirement of the framework)
 */

function checkEnvSchematics(idmodule:string, config:any):void
{
	//loop over env
	for(let env in config){
		if(env !== 'default'){
			for(let prop in config[env]){
				if(config.default[prop] === undefined) throw new Error(`module "${idmodule}" system config : property "${prop}" doesn't belong to schematic (all properties must exist in "default")`);
			}
		}
	}
}





//get main config
import * as mainConfigSystem from 'theme/base/config/main.config';
let mainConfig = mainConfigSystem.default.default;
let mainConfigUser; try{ mainConfigUser = require('theme-iso/base/config/main.config.user').default;} catch(e){}

//resolveConfig is executed 2 times here, once without runtime check and once with
//this is done because mainConfig.environments must be resolved first to be able to perform the runtime checks
mainConfig = resolveConfig('main', mainConfigSystem.default, mainConfigUser, process.env.RUNTIME_ENV, false);
mainConfig = resolveConfig('main', mainConfigSystem.default, mainConfigUser, process.env.RUNTIME_ENV);

if(mainConfig.environments.indexOf(process.env.RUNTIME_ENV) == -1){
	throw new Error(`RUNTIME_ENV "${process.env.RUNTIME_ENV}" is not defined in mainConfig.environments (${mainConfig.environments})`);
}


export function getMainConfig():typeof mainConfig{
	return mainConfig;
}