import gsap from 'gsap';
export default {
	
	/* 
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	},
	 */
	
	/* 
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
	 */
}