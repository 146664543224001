import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';


export class PreloadService extends Service<typeof config>{
	
	length:number;
	counter:number;
	progress: number;
	
	public onInit(): void {
		this.counter = 0;
		this.length = 0;
	}
	preloadImgs(tab:string[], totalLength: number) {
		var img;
		this.length = tab.length;
		//this.counter = 0;
        this.progress = (this.counter / this.length) * 100;
		
		let output:Promise<any> = new Promise((resolve, reject) => {
            var preloadProgressDiv = <HTMLDivElement>document.querySelector('.loadingText');
			let translate = this.getServiceInstance<TranslationService>('translation');
			let loadingText = translate.translate('ui.loading');
			for (var i = 0; i < this.length; i++) {

                    let url:string = this.config.assetsBaseUrl + tab[i];

					img = new Image();
					img.onload = () => {
						this.counter++;
						console.log("img success")
						let progressPercent = Math.ceil(this.counter * 100 / totalLength);
						if(this.counter == totalLength){
							preloadProgressDiv.innerHTML = " " + loadingText + "100 %";
							this.complete(resolve, reject);
							this.buttonAppearAnimation();
						}

						preloadProgressDiv.innerHTML = " " + loadingText + progressPercent + " %";
                        
					}
					img.onerror = () => {
						this.counter++;
						console.log("img error")
						if(this.counter == totalLength){
							preloadProgressDiv.innerHTML = " " + loadingText + "100 %";
							this.complete(resolve, reject);
							this.buttonAppearAnimation();
						}
					}
					img.src = url;
			}
		});
		return output;
	}

	preloadAudios(tab:string[], totalLength: number) {
		var audio;
		this.length = tab.length;
		//this.counter = 0;
        this.progress = (this.counter / totalLength) * 100;
		
		let output:Promise<any> = new Promise((resolve, reject) => {
            var preloadProgressDiv = <HTMLDivElement>document.querySelector('.loadingText');
			let translate = this.getServiceInstance<TranslationService>('translation');
			let loadingText = translate.translate('ui.loading');
			for (var i = 0; i < this.length; i++) {

                    let url:string = this.config.assetsBaseUrl + tab[i];

					audio = new Audio();
					audio.oncanplaythrough = () => {
						this.counter++;
						console.log("audio success")
						let progressPercent = Math.ceil(this.counter * 100 / totalLength);
						if(this.counter == totalLength){
							preloadProgressDiv.innerHTML = " " + loadingText + "100 %";
							this.complete(resolve, reject);
							this.buttonAppearAnimation();
						}

						preloadProgressDiv.innerHTML = " " + loadingText + progressPercent + " %";
                        
					}
					audio.onerror = () => {
						this.counter++;
						console.log("audio error")
						if(this.counter == totalLength){
							preloadProgressDiv.innerHTML = " " + loadingText + "100 %";
							this.complete(resolve, reject);
							this.buttonAppearAnimation();
						}
					}
					audio.src = url;

					// we create an audio element in DOM to force preload 
					var audiocontainer = document.createElement("audio");
					audiocontainer.src = url;
					document.querySelector("body").appendChild(audiocontainer);
					document.querySelector("body").removeChild(audiocontainer); // then we delete it for accessibilty purposes
			}
		});
		return output;
	}

	buttonAppearAnimation(): void{
		let continueButton = <HTMLElement>document.querySelector('#continue_button');
		let translationService = this.getServiceInstance<TranslationService>('translation');
		continueButton.innerHTML = translationService.translate('ui.next.button');

		continueButton.style.opacity = "1";
	}
	
	
	complete(resolve:any, reject:any)
	{
		if(this.config.mockTime === 0) resolve(true);
		else setTimeout(() => {
			resolve(true);
		}, this.config.mockTime * 1000);
	}
	
}



let serviceClass:any = PreloadService;
try{ serviceClass = getDependency<PreloadService>(require('theme-iso/base/services/preload/preload.service.user.ts')); }catch(e){}


import * as configSystem from './preload.config';
import { TranslationService } from '../translation/translation.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/preload/preload.config.user').default;} catch(e){}
config = resolveConfig('preload', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'preload', config)
