/**
 * Dowimoteur
 * 
 * Base module "card-choice"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './card-choice.config';
import { createGameMock, createMock, createPlayerMock, Game, Player, UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';
import { CardModel } from 'theme-iso/base/interface/interfaces';
import { TranslationService } from 'theme/base/services/translation/translation.service';
import { Evt } from 'theme/base/config/events';
import { dataGlobalHelp } from '../help/help.global';
import gsap from 'gsap';

export class CardChoiceModule extends Module<typeof config>{
	
	private game:Game;
	private gameService:GameService;
	private playedCardsRound:{[idcard:string]:boolean}[];
	private translationService:TranslationService;
	private cards:CardModel[];
	private playerCollective:Player;
	private cardClicked:boolean = false;
	
	override onInit(): void
	{
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		this.gameService = this.getServiceInstance<GameService>('game');
		this.translationService = this.getServiceInstance<TranslationService>('translation');
		
		this.game = userdata.getCurrentGame();
		this.gameService.initGame();
		
		
		
		/* 
		if(getMainConfig().debugMode){
			if(!this.game){
				userdata.createMock();
				this.game = this.gameService.initGame();
			}
			this.game.roundIndex = 2;
		}
		 */
		this.attrBinding.isCollective = this.gameService.isCollectiveRound(this.game.roundIndex);
		
		
		//add points to players
		if(this.game.roundIndex > 0 && !this.attrBinding.isCollective){
			this.gameService.addPointsPlayers();
		}
		
		
		//card played at current round
		
		this.playedCardsRound = this.game.players.map(p => { return {};});
		
		
		
		
		//individual
		if(!this.attrBinding.isCollective){
			
			this.attrBinding.selectedPlayerIndex = 0;
			this.attrBinding.players = this.game.players;
			
			let player = this.game.players[this.attrBinding.selectedPlayerIndex];
			let playedCardsRound = this.playedCardsRound[this.attrBinding.selectedPlayerIndex];
			
			this.cards = this.gameService.getCardsState(this.game.roundIndex, player.cardsPlayed, playedCardsRound);
			
			this.attrBinding.currentPlayer = this.game.players[this.attrBinding.selectedPlayerIndex];
			this.attrBinding.currentPlayerTitle = `${this.translationService.replaceVar(this.translationService.replaceVar(this.translationService.translate('ui.individual.selection'), 'currentPlayerName', this.attrBinding.currentPlayer ? '<b>' + this.attrBinding.currentPlayer.name + '</b>' : null), 'pointsLeft', this.attrBinding.currentPoints)}`
			this.attrBinding.currentPoints = this.attrBinding.currentPlayer.points;
			
		}
		//collective
		else{
			this.attrBinding.selectedPlayerIndex = 0;
			this.attrBinding.players = [
				{name: 'Complete team', score:0}
			];
			this.attrBinding.currentPlayer = this.game.players[this.attrBinding.selectedPlayerIndex];
			this.attrBinding.currentPlayerTitle = `${this.translationService.replaceVar(this.translationService.replaceVar(this.translationService.translate('ui.individual.selection'), 'currentPlayerName', this.attrBinding.currentPlayer ? '<b>' + this.attrBinding.currentPlayer.name + '</b>' : null), 'pointsLeft', this.attrBinding.currentPoints)}`
			this.cards = this.gameService.getCardsCollective(this.game.roundIndex);
			this.playerCollective = createPlayerMock();
		}
		
		
		this.attrBinding.cards = this.cards;
		this.updateLoop('cards');
		this.updateLoop('players');
		
		this.attrBinding.roundIndex = this.game.roundIndex;
	}
	
	
	
	
	
	public clickCard(index:number):void
	{
		//set selected state
		
		let selector = this.attrBinding.isCollective ? '.grid-collective' : '.grid';
		let container = this.dom.querySelector(selector);
		
		let player:Player;
		if(this.attrBinding.isCollective) player = this.playerCollective;
		else player = this.game.players[this.attrBinding.selectedPlayerIndex];
		
		
		
		let playedCardsRound = this.playedCardsRound[this.attrBinding.selectedPlayerIndex];
		
		let selectedCard:CardModel = this.cards[index];
		let cost = this.calculateCost(selectedCard.cost);
		
		if(selectedCard.state === 'locked'){
			
			//if not yet
			if(selectedCard.faceoffRound > this.game.roundIndex + 1){
				dataGlobalHelp.type = 'FEEDBACK_LOCKED_ROUND';
			}
			//if locked by another card
			else{
				dataGlobalHelp.type = 'FEEDBACK_LOCKED_PARENT';
				
				let idParent = selectedCard.availabilityConditions.replace('this.', '');
				let cardParent = this.gameService.getCardById(idParent);
				
				if(cardParent) dataGlobalHelp.cardParent = cardParent.label;	
				else dataGlobalHelp.type = 'FEEDBACK_LOCKED_ROUND';
			}
			router.navigate('help', 'modal');
			return;
		}
		else if(selectedCard.state === 'played_prevround'){
			
			dataGlobalHelp.type = 'FEEDBACK_ALREADY_PLAYED';
			router.navigate('help', 'modal');
			return;
		}
		
		
		let cardDom = container.children[index];
		//selection (take cost into account)
		if(!cardDom.classList.contains('selected')){
			
			//if collective turn : disable other card (1 choice only)
			if(this.playerCollective){
				for(let dom of container.children){
					dom.classList.remove('selected');
				}
				playedCardsRound = {};
				this.playedCardsRound[0] = playedCardsRound;
			}
			
			if(!this.playerCollective){
				//if player has enough points
				
				
				if(player.points >= cost){
					player.points -= cost;
					cardDom.classList.add('selected');
					playedCardsRound[selectedCard.id] = true;
				}
				//else, show feedback
				else{
					dataGlobalHelp.type = 'FEEDBACK_POINTS';
					dataGlobalHelp.fastTransition = true;
					router.navigate('help', 'modal');
				}
			}
			//collective : no cost
			else{
				cardDom.classList.add('selected');
				playedCardsRound[selectedCard.id] = true;
			}
		}
		//deselection
		else{
			cardDom.classList.remove('selected');
			delete playedCardsRound[selectedCard.id];
			if(!this.playerCollective) player.points += cost;
		}
		
		
		//set player has played
		player.played = false;
		this.cardClicked = false;
		for(let i in playedCardsRound){
			if(playedCardsRound[i]){
				player.played = true;
				this.cardClicked = true;
				break;
			}
		}
		
		broadcaster.dispatchEvent(Evt.UPDATE_FUNCTION_STATE, ['isBtnEnabled'])
		
		
		
		
		
		let diff:{[id:string]:CardModel};
		let newCards:CardModel[];
		if(!this.playerCollective){
			
			//get new states after this change, and make a diff
			//duplicate cards here to avoid changing the initial one
			newCards = this.gameService.getCardsState(this.game.roundIndex, player.cardsPlayed, playedCardsRound, true);
			diff = this.gameService.getDiffCards(this.cards, newCards);
		}
		
		//if there is 1+ card that deselect (beside the selected one), send a warning
		let unselectedCards = [];
		for(let id in diff){
			let card = diff[id];
			let cardDom = container.children[card.index];
			
			//another card becomes locked
			if(playedCardsRound[id] && card.prevState === 'available' && card.state === 'locked') unselectedCards.push(card);
		}
		
		if(unselectedCards.length > 0){
			dataGlobalHelp.type = 'FEEDBACK_LINKED';
			dataGlobalHelp.cardLinks = unselectedCards.map(card => card.label);
			dataGlobalHelp.callbackConfirm = function(){
				setTimeout(function(){
					
					this.cards = newCards;
					selectedCard = this.cards[index];
					
					this.clickCard2(selectedCard, player, container, cardDom, diff, playedCardsRound);
				}.bind(this), 350);
			}.bind(this);
			dataGlobalHelp.callbackCancel = function(){
				cardDom.classList.add('selected');
				player.points -= cost;
				playedCardsRound[selectedCard.id] = true;
			}.bind(this);
			router.navigate('help', 'modal');
		}
		else{
			
			if(!this.playerCollective){
				this.cards = newCards;
				selectedCard = this.cards[index];
			}
			
			this.clickCard2(selectedCard, player, container, cardDom, diff, playedCardsRound);
		}
		
	}
	
	
	
	
	private clickCard2(selectedCard:CardModel, player:Player, container:Element, cardDom:Element, diff:{[id:string]:CardModel}, playedCardsRound:{[idcard:string]:boolean}):void
	{
		
		
		//increment the selected card "nb played" (for multiple selection)
		
		if(selectedCard.multipleSelection){
			
			let dom:HTMLElement = cardDom.querySelector('.played-times');
			let nb = selectedCard.nbplayed;
			
			if(nb > 0){
				let str = this.translationService.translate('ui.individual.played');
				str = str.replace('[timesPlayed]', String(nb));
				dom.innerHTML = str;
				dom.style.display = 'block';
			}
			else dom.style.display = 'none';
		}
		
		
		//handle cards which becomes unavailable / available
		
		if(!this.playerCollective){
			
			//get new states after this change, and make a diff
			
			let count = 0;
			for(let id in diff){
				let card = diff[id];
				let cardDom = container.children[card.index];
				
				//another card becomes locked
				if(card.prevState === 'available' && card.state === 'locked'){
					
					(<HTMLElement>cardDom.querySelector('.pts')).style.display = 'none';
					(<HTMLElement>cardDom.querySelector('.lock')).style.display = 'block';
					cardDom.classList.add('state-locked');
					cardDom.classList.remove('state-available');
					
					let domPlayedTimes:HTMLElement = cardDom.querySelector('.played-times');
					domPlayedTimes.style.display = 'none';
					
					//if card was selected, need to unselect it
					if(playedCardsRound[card.id]){
						cardDom.classList.remove('selected');
						playedCardsRound[card.id] = false;
						
						let cost = this.calculateCost(card.cost);
						player.points += cost;
					}
				}
				//another card becomes unlocked
				else if(card.prevState === 'locked' && card.state === 'available'){
					
					(<HTMLElement>cardDom.querySelector('.pts')).style.display = 'block';
					(<HTMLElement>cardDom.querySelector('.lock')).style.display = 'none';
					cardDom.classList.remove('state-locked');
					cardDom.classList.add('state-available');
				}
				
				//anim card that had an interraction (unlock, relock, deselect)
				setTimeout(() => {
					new gsap.core.Timeline()
					.fromTo(cardDom, {scale:0.60 }, {scale:1, duration:0.29, ease: "back.out(3.9)"})
					.fromTo(cardDom, {filter:'brightness(0.3)'}, {filter:'brightness(1)', duration:0.29, ease: "back.out(3.9)"}, '<+0')
				}, count * 140);
				
					count++;
			}
		}
		
		//update players list because nb points changes
		
		this.attrBinding.currentPoints = this.attrBinding.currentPlayer.points;
		this.updateLoop('players');
		
	}
	
	
	
	public calculateCost(cost:string):number
	{
		return Math.max(1, Math.floor(parseInt(cost) * this.game.ctFactorCost));
		
	}
	
	
	//this function shouldn't be needed, but the framework currently can't handle spaces in string
	public strPoints(nbpts:number):string
	{
		let str = this.translationService.translate('ui.cards.cost');
		str = str.replace('[points]', String(nbpts ? nbpts : 0));
		return str;
	}
	public strPointsPlayers(nbpts:number):string
	{
		let wording = this.translationService.translate('ui.individual.points');
		wording = wording.replace('[pointsLeft]', String(nbpts ? nbpts : 0));
		return wording;
	}
	
	
	
	public clickPlayer(index:number):void
	{
		//resync state for player
		
		this.attrBinding.selectedPlayerIndex = index;
		this.updateLoop('players');
		
		this.attrBinding.currentPlayer = this.game.players[index];
		this.attrBinding.currentPlayerTitle = `${this.translationService.replaceVar(this.translationService.replaceVar(this.translationService.translate('ui.individual.selection'), 'currentPlayerName', this.attrBinding.currentPlayer ? '<b>' + this.attrBinding.currentPlayer.name + '</b>' : null), 'pointsLeft', this.attrBinding.currentPoints)}`;
		this.attrBinding.currentPoints = this.attrBinding.currentPlayer.points;
		
		let player = this.game.players[this.attrBinding.selectedPlayerIndex];
		let playedCardsRound = this.playedCardsRound[this.attrBinding.selectedPlayerIndex];
		
		this.cards = this.gameService.getCardsState(this.game.roundIndex, player.cardsPlayed, playedCardsRound);
		this.attrBinding.cards = this.cards;
		
		
		//sync interface with playedCardsRound
		let selector = this.attrBinding.isCollective ? '.grid-collective' : '.grid';
		let container = this.dom.querySelector(selector);
		for(let card of this.cards){
			if(playedCardsRound[card.id]){
				let dom = this.dom.querySelector('.' + card.id);
				dom.classList.add('selected');
			}
		}
		
	}
	
	
	public getClassCard(id:string):string
	{
		return 'item-card '+id;
	
	}
	
	
		
	public clickHelp():void
	{
		dataGlobalHelp.type = 'HELP_CARD_CHOICE';
		router.navigate('help', 'modal');
	}
	
	
	private btnEnabled:boolean;
	isBtnEnabled(players:Player[]):boolean
	{
		return true;
	}
	
	
	public clickValidate():void
	{
		//display pedagogic content
		// let index = getMainConfig().debugMode ? 2 : this.game.roundIndex;
		
		let index = this.game.roundIndex;
		
		this.gameService.firstCards = this.gameService.getFirstCards(this.playedCardsRound);
		
		if(!this.game.pedagogicalFlags) this.game.pedagogicalFlags = {};
		let contents = this.gameService.getPedagogicContent(this.game.pedagogicalFlags, index + 1, 'beforeScore', this.gameService.firstCards);
		
		dataGlobalHelp.type = 'FEEDBACK_CONFIRM';
		dataGlobalHelp.pedagogicContents = contents;
		dataGlobalHelp.fastTransition = false;
		dataGlobalHelp.callbackConfirm = this.clickValidateConfirm.bind(this);
		router.navigate('help', 'modal');
		
	}
	
	clickValidateConfirm()
	{
		//players cards, round index
		let isColllective = this.playerCollective != null;
		
		if(!isColllective) this.gameService.finishTurn(this.playedCardsRound, isColllective);
		else{
			//the 1st item represent the card played by the group
			let playedCardsRound = this.playedCardsRound[this.attrBinding.selectedPlayerIndex];
			this.gameService.finishTurn([playedCardsRound], isColllective);
		}
		
		
		//flag the pedagogicContents as already seen in game data
		for(const content of dataGlobalHelp.pedagogicContents){
			this.game.pedagogicalFlags[content.id] = true;
		}
		
		router.navigate('results');
	}
	
	
}



let moduleClass:any = CardChoiceModule;
try{ moduleClass = getDependency<CardChoiceModule>(require('theme-iso/base/modules/pages/card-choice/card-choice.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./card-choice.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/card-choice/card-choice.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./card-choice.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/card-choice/card-choice.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/card-choice/card-choice.config.user').default;} catch(e){}
config = resolveConfig('card-choice', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./card-choice.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/card-choice/card-choice.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'card-choice', template, config, anims, css)