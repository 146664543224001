import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';



export class DebugService extends Service<typeof config>{
	
	public section:string;
	
	
	override onViewReady(){
		
		const mainConfig = getMainConfig();
		if (!mainConfig.enableDebugModule) return;
		
		window.document.addEventListener('keydown', evt => {
			if(evt.code === 'F2'){
				
				let isDisplayed = (router.getState('debug') != null);
				if(!isDisplayed) router.navigate('1', 'debug');
				else{
					router.navigate(null, 'debug');
					router.navigate(null, 'debug-content');
				}
			}
		});
	}
	
	
	 	
}



let serviceClass:any = DebugService;
try{ serviceClass = getDependency<DebugService>(require('theme-iso/base/services/debug/debug.service.user.ts')); }catch(e){}


import * as configSystem from './debug.config';
import router from 'core/lib/router';
import mainConfig from 'theme/base/config/main.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/debug/debug.config.user').default;} catch(e){}
config = resolveConfig('debug', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'debug', config)
