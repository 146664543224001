export default {
	
	//default config
	default : {
		languages: [
			'fr_FR', 
			'en_UK', 
		],
		selectedLocale: null
	},
	
	//dev
	dev : {
		
	},
	
	
	//prod
	prod : {
		
	}
}
