/**
 * Dowimoteur
 * 
 * Base module "debug-userdata-editor"
 * Created on May 19, 2023
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './debug-userdata-editor.config';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';

export class DebugUserdataEditorModule extends Module<typeof config>{
	
	private userdata:UserDataService;
	
	override onInit(): void
	{
		this.userdata = this.getServiceInstance<UserDataService>('userdata');
		let userdata:any = this.userdata.getCurrentData();
		this.attrBinding.isReadOnly = this.userdata.getCurrentKey() !== 'n';
		
		let dataroot:any[] = [];
		dataroot.push({
			sectionKey: null, title: 'root', data: {}
		});
		dataroot.push({
			sectionKey: 'flowchartVariables', title: 'flowchartVariables', data: {}
		});
		
		for(let k in dataroot){
			let section = dataroot[k];
			let objsrc = section.sectionKey === null ? userdata : userdata[section.sectionKey];
			for(let i in objsrc){
				if(objsrc[i] === null || typeof objsrc[i] !== 'object'){
					section.data[i] = objsrc[i];
				}
			}
		}
		
		this.attrBinding.dataroot = dataroot;
		
		/*
		need to filter out some key (if object or array)
		need to display additional list (no indentation, ça sera moche)
		
		so multiple list, filtered, i need another data struct
		*/
	}
	
	
	/**
	 * when hit enter on a field : save the change in userdata
	 */
	onSaveInput(evt:KeyboardEvent):void
	{
		let input = <HTMLInputElement>evt.currentTarget;
		let key:string = input.dataset.sectionkey;
		let value:any = this.transformValue(input.value);
		//root
		if(!key) this.userdata.updateData(input.name, value);
		//global
		else{
			let userdata:any = this.userdata.getCurrentData();
			userdata[key][input.name] = value;
		}
		
		this.userdata.saveIfN();
		input.classList.add('updated');
		
	}
	
	
	
	/**
	 * when leave the input text (blur event), reset the change to previous value
	 */
	onLeaveInput(evt:FocusEvent):void
	{
		let input = <HTMLInputElement>evt.currentTarget;
		let key:string = input.dataset.sectionKey;
		//root
		if(!key) input.value = (<any>this.userdata.getCurrentData())[input.name];
		else input.value = (<any>this.userdata.getCurrentData())[key][input.name];
		input.classList.remove('updated');
	}
	
	
	
	/**
	 * auto cast boolean, null, number and string
	 */
	private transformValue(str:string):any
	{
		if(str === 'false') return false;
		else if(str === 'true') return true;
		else if(str === 'null') return null;
		else if(str === '') return null;
		else if(parseInt(str)+'' === str) return parseInt(str);	//if is number
		else return str;
	}
	
}



let moduleClass:any = DebugUserdataEditorModule;
try{ moduleClass = getDependency<DebugUserdataEditorModule>(require('theme-iso/base/modules/debug/submodules/debug-userdata-editor/debug-userdata-editor.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./debug-userdata-editor.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-userdata-editor/debug-userdata-editor.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./debug-userdata-editor.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/debug/submodules/debug-userdata-editor/debug-userdata-editor.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/debug/submodules/debug-userdata-editor/debug-userdata-editor.config.user').default;} catch(e){}
config = resolveConfig('debug-userdata-editor', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./debug-userdata-editor.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-userdata-editor/debug-userdata-editor.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'debug-userdata-editor', template, config, anims, css)