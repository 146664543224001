import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';
import { AES, enc } from 'crypto-js';
import * as CryptoJS from 'crypto-js';



export class EncryptService extends Service<typeof config>{
	
	public encode(value:string):string
	{
		let cypherConfig = {
			iv: CryptoJS.lib.WordArray.random(16),
			mode: CryptoJS.mode.CBC,
		};
		return AES.encrypt(value, this.config.x0dx0ax20x20x20x20, cypherConfig).toString();
	}
	
	
	public decode(value:string):string
	{
		return AES.decrypt(value, this.config.x0dx0ax20x20x20x20).toString(enc.Utf8);
	}
	
	
	
	public test():void
	{
		let value = 'value to be encoded';
		let output = AES.decrypt(AES.encrypt(value, 'test'), 'test').toString(enc.Utf8);
		console.log('test encrypt : '+output);
		
		
		
		let cypherConfig = {
			iv: CryptoJS.lib.WordArray.random(16),
			mode: CryptoJS.mode.CBC,
		};
		
		let value2 = 'value to be encoded';
		let value2encoded = AES.encrypt(value2, 'testqsdfqsfdqs', cypherConfig).toString();
		let output2 = AES.decrypt(value2encoded, 'testqsdfqsfdqs').toString(enc.Utf8);
		console.log('test encrypt 2 : '+output2);
		
	}
}




let serviceClass:any = EncryptService;
try{ serviceClass = getDependency<EncryptService>(require('theme-iso/base/services/encrypt/encrypt.service.user.ts')); }catch(e){}


import * as configSystem from './encrypt.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/encrypt/encrypt.config.user').default;} catch(e){}
config = resolveConfig('encrypt', configSystem.default, configUser, process.env.RUNTIME_ENV)



registerServiceDef(serviceClass, 'encrypt', config)
