import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router, { Route } from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './debug-router.config';

export class DebugRouterModule extends Module<typeof config>{
	
	
	private outletDefs:any;
	
	/**
	 * setup a 2 dimensional data structure with outlets / routes
	 * to be displayed in the template
	 * outlets : debug and debug-content are filtered
	 */
	
	
	override onInit(): void {
		
		this.outletDefs = router.getOutletDefs();
		let routes = router.getRoutes();
		
		this.attrBinding.testprop = 'root';
		let outlets = [];
		
		for(let name in this.outletDefs){
			
			if(['debug', 'debug-content'].indexOf(name) === -1){
				let tab:any[] = routes.filter((route:Route) => route.outlet === name || (name === 'root' && !route.outlet));
				
				outlets.push({
					curPath: router.getState(name),
					isLinked: this.outletDefs[name].isLinked,
					name, 
					routes: tab
				});
			}
		}
		
		this.attrBinding.outlets = outlets;
		
	}
	
	
	
	navigate(path:string, outlet:string):void
	{
		if(outlet === null) outlet = 'root';
		router.navigate(path, outlet);
		
		
		//update property cur
		for(let name in this.attrBinding.outlets){
			let dataOutlet:any = this.attrBinding.outlets[name];
			dataOutlet.curPath = router.getState(dataOutlet.name);
		}
		// this.updateLoop('outlets');
		this.attrBinding.outlets = this.attrBinding.outlets;
	}
	closeOutlet(outlet:string):void
	{
		router.navigate(null, outlet);
	}
	isRouteActive(curPath:string, path:string):boolean
	{
		return path === curPath;
	}
	mustDisplayClose(outlet:string):boolean
	{
		let ok = (<any>outlet).something;
		return outlet !== 'root' && !this.outletDefs[outlet].isLinked;
	}
	
}



let moduleClass:any = DebugRouterModule;
try{ moduleClass = getDependency<DebugRouterModule>(require('theme-iso/base/modules/debug/submodules/debug-router/debug-router.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./debug-router.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-router/debug-router.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./debug-router.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/debug/submodules/debug-router/debug-router.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/debug/submodules/debug-router/debug-router.config.user').default;} catch(e){}
config = resolveConfig('debug-router', configSystem.default, configUser, process.env.RUNTIME_ENV)


registerModuleDef(moduleClass, 'debug-router', template, config, null, css)