import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('.container h1', {opacity:0, marginTop:100}, {opacity:1, marginTop:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('.container .desc', {opacity:0, y:100}, {opacity:1, y:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}