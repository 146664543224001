/**
 * Dowimoteur
 * 
 * Base module "pause-game"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './pause-game.config';
import { dataGlobalHelp } from '../../pages/help/help.global';

export class PauseGameModule extends Module<typeof config>{
	
	override onInit(): void
	{
		
	}
	
	clickResume():void
	{
		broadcaster.dispatchEvent('START_TIMER');
		router.navigate(null, 'modal');
	}
	clickTutorial():void
	{
		dataGlobalHelp.type = 'TUTORIAL';
		dataGlobalHelp.cancelNavigation = true;
		router.navigate('help', 'modal');
	}
	clickBack():void
	{
		router.navigate('confirm-quit', 'modal');
	}
}



let moduleClass:any = PauseGameModule;
try{ moduleClass = getDependency<PauseGameModule>(require('theme-iso/base/modules/overlay/pause-game/pause-game.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./pause-game.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/overlay/pause-game/pause-game.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./pause-game.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/overlay/pause-game/pause-game.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/overlay/pause-game/pause-game.config.user').default;} catch(e){}
config = resolveConfig('pause-game', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./pause-game.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/overlay/pause-game/pause-game.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'pause-game', template, config, anims, css)