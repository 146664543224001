import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement, container:string):GSAPTimeline =>{
		
		// le param "container" peut permettre de faire des anim différente selon le template/container affiché
		// avec un switch container et des dom.querySelector('#'+container) dans les anims

		const t:GSAPTimeline = new gsap.core.Timeline({defaults:{immediateRender: false}});
		t.fromTo(dom, 
			{opacity:0}, 
			{opacity:1, duration:0.5}
		);
		return t;
	},
	
	
	transition_out:(dom:HTMLElement, container:string):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		t.to(dom, {opacity:0, duration:0.5});
		return t;
	}
}