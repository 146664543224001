/**
 * search for a pattern like (event)="function" in the template
 * remove it from the template
 * search if an id attribute exists, otherwise add a uniqid
 * return resulting MacroDef to be stored in ModuleDef
 */

import { pipe } from "gsap";
import { MacroDef, getModuleDefByID, ModuleDef, TplExpr, Module, registerModuleDef } from "./core";
import { getPipe, getTplFunc, TplFunction } from "./services";
import { testErrorFile } from "./testerrorfile";

export function parseMacro(tpl:string, type:string, index:number = 0):MacroDef
{
	let match;
	if(type === 'event'){
		match = tpl.match(/\(([\w\.\-]+)\)="([0-9A-zÀ-ú-|:, \.\$/\(\)'=!?;+*%]+)"/);
	}
	if(type === 'attr'){
		//func:arg1,arg2|pipe1|pipe2 func2
		// match = tpl.match(/\[([\w\.-]+)\]="([\w-|:, \.\$/]+)"/);
		match = tpl.match(/\[([\w\.\-]+)\]="([0-9A-zÀ-ú-|:, \.\$#°&><\!=/\(\)'=!?;+*%]+)"/);
		// 
	}
	else if(type === 'import') match = tpl.match(/\[(import)\]="([\w-]+)"/);
	else if(type === 'loop') match = tpl.match(/\[(loop)\]="([\w-]+)"/);
	
	if(match){
		let key = match[1];
		let value = match[2];
		
		//find the surrounding < ... > string 
		//and search for an id attribute inside
		let startElmt = tpl.lastIndexOf('<', match.index);
		let lastElmt = tpl.indexOf('>', match.index);
		let strTag = tpl.substr(startElmt, lastElmt - startElmt + 1);
		let subtpl;
		
		
		//for loop, we need to :
		//- find the closing tags 
		//- extract what is inside (subtpl)
		
		if(type === 'loop'){
			//we need to extract id if it exist, or assign one if it doesn't
			let closingPattern = `[loop]="${value}"`;
			let indClosingPattern = tpl.indexOf(closingPattern, lastElmt);
			
			if(indClosingPattern !== -1){
				let endStartElmt = tpl.lastIndexOf('<', indClosingPattern);
				let endLastElmt = tpl.indexOf('>', indClosingPattern);
				let strClosingTag = tpl.substr(endStartElmt, endLastElmt - endStartElmt + 1);
				
				subtpl = tpl.substr(lastElmt + 1, endStartElmt - lastElmt - 1);
				
				//remove closing tag
				tpl = tpl.substr(0, indClosingPattern - 1) + tpl.substr(indClosingPattern + closingPattern.length);
				
				//remove subtpl
				tpl = tpl.substr(0, lastElmt + 1) + tpl.substr(endStartElmt);
			}
			else subtpl = null;
		}
		
		
		//we need to extract id if it exist, or assign one if it doesn't
		//we're also removing the macro syntax to make tpl valid html
		let matchid = strTag.match(/id="([\w-]+)"/)
		let id;
		
		//id attribute exists
		if(matchid){
			id = matchid[1];
			tpl = tpl.replace(match[0], ``);	//remove macro syntax
		}
		//id doesn't exist, create a random one
		//store it and rewrite template
		else{
			if(type === 'import') id = `${value}_${index}`;
			else id = uniqid('bind-');
			//remove macro syntax and write id attribute instead
			tpl = tpl.replace(match[0], `id="${id}"`);
		}
		
		return {tpl, id, key, value, type, subtpl};
	}
	else return null;
}




export function parseTplExpr(value:string):TplExpr
{
	//protect || expression by turning it into a special token
	//and reconvert it after the | pipe spliting
	value = value.replace('||', '_OR_');
	
	let pipes = value.split('|');
	let expression = pipes[0];
	pipes.shift();
	pipes = pipes.map(str => str.replace(' ', ''));
	
	expression = expression.replace('_OR_', '||');
	
	//remove all spaces
	expression = expression.replace(/ +/g, '');
	
	//extract variables
	let variables:string[] = expression.match(/\$([\w_]+)/g);
	if(variables === null) variables = [];
	
	//extract functions
	let functions:string[] = expression.match(/([\w_]+)\(/g);
	if(functions === null) functions = [];
	
	for(let k in functions){
		let func = functions[k];
		func = func.substr(0, func.length-1);
		expression = expression.replace(new RegExp(`(\\b|[^\\w])${func}`, 'g'), (match, p1) => {
			if (p1 !== 'this.') {
				return `${p1}this.${func}`;
			}
			return match;
		});
		
		functions[k] = func;
	}
	
	expression = expression.replace(/\$/g, 'this.');
	
	let output:TplExpr = {
		expression,
		variables: variables.map(str => str.substr(1)),
		functions,
		pipes
	};
	
	return output;
}




/**
 * parse macros ([import] / (event) / [attr])
 * 
 * takes an html template that contains macros
 * modifies the moduleDef object
 * it sets 3 arrays which are reused later by modules.instanciateModule : macroImports, macroEvents, macroBindAttr
 * and modifies the template to remove the macro syntaxes and keep only valid html
 * it also injects a unique id (if necessary) to associated dom element so they can be retrieved later
 * 
 * done at init and save the results for later use (at instanciation time)
 * doing it at this time allows to have all template parse error at init time which speeds up debugging
 * (rather than later at instanciation time)
 */

export function parseTemplates(moduleDefs:ModuleDef[])
{
	let errors:string[] = [];
	
	for(let i = 0; i<moduleDefs.length; i++){
		let def = moduleDefs[i];
		let output = parseTemplate(def);
		let errorsTpl = output.errors;
		
		//if parsing generated new submodule to be added
		for(let j in output.loopModules){
			let def2 = output.loopModules[j];
			//this call will mutate moduleDefs array used in the main loop here
			registerModuleDef(null, def2.id, def2.template, null, null, null, def2.baseDef);
		}
		
		errors = [...errors, ...errorsTpl];
	}
	
	if(errors.length){
		throw new Error(getGlobalError(errors));
	}
}

export function parseTemplate(def:ModuleDef):{errors:string[], loopModules:ModuleDef[]}
{
	let errors:string[] = [];
	let tpl = def.template;
	
	//remove comments in template
	const regex = /<!--.*?-->/gs;
	tpl = tpl.replace(regex, '');
	
	let macroDef = null;
	
	
	
	//loop macros [loop]="array_name:id_module"
	
	let macroLoop:MacroDef[] = [];
	let index = 0;
	let loopModules:ModuleDef[] = [];
	
	//this loop is for extracting each loop content into a new module (dynamically created)
	//and repeating the process recursively until no more loop are found
	
	while(macroDef = parseMacro(tpl, 'loop', index++)){
		tpl = macroDef.tpl;
		if(!macroDef.subtpl) errors.push(`${getErrorPrefix(def, macroDef)}closing tag not found`);
		else{
			//validate that subtpl has only 1 child
			let domtest = document.createElement('div');
			domtest.innerHTML = macroDef.subtpl;
			if(domtest.children.length > 1){
				errors.push(`${getErrorPrefix(def, macroDef)}can only have 1 direct child (without sibblings)`);
			}
			//no errors
			else{
			
				//parseMacro has already extracted subtpl : the content between [loop]...[loop]
				//both tags have been erased from template (to make it valid html)
				
				//we will generate dynamically a new module to host that subtpl (and to be reused in the loop)
				//we return a list of loopModules that the calling function we add to its array
				macroDef.tpl = null;	//optimization
				macroLoop.push(macroDef);
				
				let baseDef:ModuleDef = def.class ? def : def.baseDef;
				
				let subid:string = `${macroDef.value}-sub-${uniqid()}`;
				macroDef.value += ':'+subid;
				let subdef:ModuleDef = { id:subid, template: macroDef.subtpl, baseDef, class:null, config:null, anims:null };
				loopModules.push(subdef);
			}
		}
		
	}
	
	
	
	
	
	
	
	//import macro [import]="id_module"
	//must be processed before [attr] bindings
		//1 - to avoid [import] to be treated as an attribute
		//2 - to setup specific id schema for imported modules ([ idmodule][index])
	
	let macroImports:MacroDef[] = [];
	index = 0;
	while(macroDef = parseMacro(tpl, 'import', index++)){
		tpl = macroDef.tpl;
		macroDef.tpl = null;	//optimization
		macroImports.push(macroDef);
		
		let targetID = macroDef.value;
		let moduleTarget = getModuleDefByID(targetID);
		if(!moduleTarget) errors.push(`${getErrorPrefix(def, macroDef)}module "${targetID}" is not defined`);
	}
	
	
	//events macro (click)="callback"
	
	let macroEvents:MacroDef[] = [];
	let instance:any = null;
	while(macroDef = parseMacro(tpl, 'event')){
		tpl = macroDef.tpl;
		macroDef.tpl = null;	//optimization
		
		
		let expr = parseTplExpr(macroDef.value);
		
		//error validations
		if(expr.pipes.length > 0) errors.push(`${getErrorPrefix(def, macroDef)}pipes are not allowed for event binding"`);
		
		if(!instance){
			if(def.class) instance = new (<any>def.class)();
			else if(def.baseDef.class) instance = new (<any>def.baseDef.class)();
			else throw new Error('one of those should be defined');
		}
		
		let outputExpr = execTplExpression(def, macroDef, expr, instance, null, null, null, false, true);
		if(outputExpr.errors.length) errors = [...errors, ...outputExpr.errors];
		
		macroDef.expressions = [expr];
		macroEvents.push(macroDef);
	}
	
	
	
	//attribute bindings macro [attr]="prop"
	
	let macroBindAttr:MacroDef[] = [];		
	while(macroDef = parseMacro(tpl, 'attr')){
		tpl = macroDef.tpl;
		macroDef.tpl = null;	//optimization
		
		if(!instance){
			if(def.class) instance = new (<any>def.class)();
			else if(def.baseDef) instance = new (<any>def.baseDef.class)();
			else throw new Error('one of those should be defined');
		}
		
		//new parsing
		let expr = parseTplExpr(macroDef.value);
		/* 
		if(expr.expression.indexOf('()') > -1){
			errors.push(`${getErrorPrefix(def, macroDef)}function calls with no arguments are not allowed in bindings expressions, you should use pure functions"`);
		}
		 */
		let outputExpr = execTplExpression(def, macroDef, expr, instance, null, null, null, true, true);
		
		//if no error, returned value is not an array
		if(outputExpr.errors.length) errors = [...errors, ...outputExpr.errors];
		
		if(expr.expression.indexOf(';') > -1) errors.push(`${getErrorPrefix(def, macroDef)}you can't use ";" in a binding expression`);
		
		
		for(let i in expr.pipes){
			if(!getPipe(expr.pipes[i])) errors.push(`${getErrorPrefix(def, macroDef)}pipe "${expr.pipes[i]}" is not defined. It must be defined globally with registerPipe"`);
		}
		
		macroDef.expressions = [expr];
		macroBindAttr.push(macroDef);
	}
	
	
	
	
	
	//modifies moduleDef object (side effect)
	def.template = tpl;
	def.macroImports = macroImports;
	def.macroEvents = macroEvents;
	def.macroBindAttr = macroBindAttr;
	def.macroLoop = macroLoop;
	
	//for test, we need to throw an error here, 
	//because this can be called outside of parent function
	if(errors.length && process.env.RUNTIME_ENV === 'test'){
		throw new Error(getGlobalError(errors));
	}
	return {errors: errors, loopModules};
}



/**
 * make an eval of expression
 * needs to create a scope that contains :
 * - variables used in the expression
 * - functions
 * 
 * called in 2 scenarios :
 * - at init : validation => returns array of errors string and use {} as a placeholder for variables value
 * - at runtime : return real value (type any), and use real variable value
 */
function execTplExpression(def:ModuleDef, macroDef:MacroDef, expr:TplExpr, instance:any, dataItem:any, dataParent:any, indexLoop:number, returnValue:boolean, parseInit:boolean, event:Event = null):{errors:string[], outputValue:any}
{
	let errors = [];
	let outputValue:any;
	//creation of a scope with required functions/variable to evaluate expression in an eval
	var scope:any = {};
	for(let i in expr.variables){
		if(!parseInit) scope[expr.variables[i]] = resolveArgSingle(expr.variables[i], dataItem, dataParent, indexLoop, false);
		else scope[expr.variables[i]] = {};
	}
	if(event) scope.event = event;
	
	let errorFlag = false;
	for(let k in expr.functions){
		let func:string = expr.functions[k];
		if(instance[func] && instance[func].bind) scope[func] = instance[func].bind(instance);
		else if(getTplFunc(func)) scope[func] = getTplFunc(func).func.bind(getTplFunc(func).serviceInst);
		else{
			errorFlag = true;
			errors.push(`${getErrorPrefix(def, macroDef)}function "${func}" must be defined either in module class, or as a global with registerTplFunc in index.ts`);
		}
		//create a mock of the function so we can test a call to it without actually calling the real one
		//this is for syntax validation
		if(parseInit){
			scope[func] = function(){};
		}
	}
	
	//validation with an eval
	if(!errorFlag){
		
		//for parsing, we use a try catch so we can collect all the errors and display them at once and also add additional context informations
		try{
			//this is equivalent to an eval
			if(returnValue) outputValue = Function(`"use strict"; return ${expr.expression}`).bind(scope)();
			else Function(`"use strict"; ${expr.expression}`).bind(scope)();
		}
		catch(error){
			//if parsing, only keep syntax error, the others are possible since variables aren't ready at this stage.
			let displayError = !parseInit || error.stack.substr(0, 6) === 'Syntax';
			if(displayError){
				let errorstr = `${getErrorPrefix(def, macroDef)}\n => ${error.message}\n\n${error.stack}///`;
				errors.push(errorstr);
			}
		}
	}
	return { errors, outputValue}
}



function getGlobalError(errors:string[]):string
{
	let output:string = `${errors.length} template parsing errors ::\n\n`;
	output += '- ' + errors.join('\n- ') + '\n.';
	return output;
}

function getErrorPrefix(def:ModuleDef, macroDef:MacroDef):string
{
	let open:string = macroDef.type === 'event' ? '(' : '[';
	let close:string = macroDef.type === 'event' ? ')' : ']';
	let defId = def.baseDef ? def.baseDef.id : def.id;
	return `Module "${defId}" > ${open}${macroDef.key}${close}="${macroDef.value}" : `;
}



function uniqid(prefix = "", random = true) {
	const sec = Date.now() * 1000 + Math.random() * 1000;
	const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
	return `${prefix}${id}${random ? `${Math.trunc(Math.random() * 100000000)}`:""}`;
}


/**
 * set attribute of dom element (and handle special cases)
 */
export function updateDomAttr(dom:HTMLElement, key:string, value:string)
{
	//innerHTML
	if(key === 'innerHTML'){
		dom.innerHTML = value;
	}
	//visible property, [visible]="true"
	else if(key === 'visible'){
		//if [visible]="false" : set display none
		if(!value) dom.style.display = 'none';
		//else, keep the style as it was
		else dom.style.removeProperty('display');
	}
	//[style.font-size]="12px"
	else if(key.substring(0, 6) === 'style.'){
		let prop:string = key.substring(6);
		//style.prop.value=[boolean]
		if(prop.indexOf('.') > -1){
			let tab = prop.split('.');
			prop = tab[0];
			if(value) dom.style.setProperty(prop, tab[1]);
		}
		//style.prop=value
		else{
			dom.style.setProperty(prop, value);
		}
	}
	//[class.is-selected]="true"
	else if(key.substring(0, 6) === 'class.'){
		let _class:string = key.substring(6);
		if(value) dom.classList.add(_class);
		else dom.classList.remove(_class);
	}
	//attr that appear or not (ex : selected)
	else if(key.substring(0, 5) === 'attr.'){
		let _attr:string = key.substring(5);
		if(value) dom.setAttribute(_attr, "1");
	}
	//form value (attribute value is not synced)
	else if(key === 'value' && (<HTMLInputElement>dom).value !== undefined){
		(<HTMLInputElement>dom).value = value;
	}
	//classic attribute
	else{
		if(value === undefined || value === null) value = '';
		dom.setAttribute(key, value);
	}
}



/**
 * resolve variable value for macro expressions in the following order :
 * 1 - from the current data object (in the case of a loop)
 * 2 - from the parent loop (in case of loop nesting)
 * 2 - from the parent module class (module.attrBinding object)
 * 
 * for inside loops, there are 3 reserved keywords :
 * - index (numeric index if looping over an array)
 * - key (string index if looping over an object/hashmap)
 * - item (the whole item object (rather than one if it's sub-property))
 */

function resolveArgSingle(key:string, dataItem:any, dataParent:any, indexLoop:number, allowKeyAsValue:boolean):any
{
	if(key === 'index') return indexLoop;			//$index
	else if(key === 'item'){												//$item
			return dataItem['item'] !== undefined ? dataItem['item'] : dataItem;
	}
	else if(dataItem && dataItem[key] !== undefined){
		return dataItem[key];
	}
	else if(dataParent && dataParent[key] !== undefined) return dataParent[key];
	else if(allowKeyAsValue) return key;
	else return null;
}





/**
 * listen to event defined and associate expressions
 * an expression is either a module handler (function) or a global template function (added with registerTplFunc)
 */

export function applyEventBinding(instance:Module<any>, dom:HTMLElement, id:string, key:string, macroDef:MacroDef, dataItem:any, dataParent:any, indexLoop:number):void
{
	let elmt = dom.id === id ? dom : dom.querySelector(`#${id}`);
	
	let expr = macroDef.expressions[0];
	let handler = (e:Event) => {
		let outputExpr = execTplExpression(instance.def, macroDef, expr, instance, dataItem, dataParent, indexLoop, false, false, e);
		if(outputExpr.errors.length){
			console.error(new Error(getGlobalError(outputExpr.errors)));
			throw new Error('Macro event error (see above)');
		}
	};
	
	let tabkey = key.split('.');
	if(tabkey.length > 2) throw new Error(`macro event can only have 1 separating dot maximum (ex : keyup.enter)`)
	
	//simple event (click)
	if(tabkey.length === 1) elmt.addEventListener(key, handler);
	//event + argument (keyup.enter)
	else{
		elmt.addEventListener(tabkey[0], (e) => {
			let arg:any;
			if (e instanceof KeyboardEvent) arg = e.code.toLowerCase();
			else throw new Error(`arg type not handled for event of type ${e} / ${key}`)
			
			if(arg === 'numpadenter') arg = 'enter';
			if(arg === tabkey[1]) handler(e);
		});
	}
}


export function resolveMacroBinding(def:ModuleDef, macroDef:MacroDef, instance:Module<null>, dataItem:any, dataParent:any, indexLoop:number):any
{
	//attr binding works only allowed for 1 expr (already validated at parsing time)
	let expr:TplExpr = macroDef.expressions[0];
	
	
	let outputExpr = execTplExpression(def, macroDef, expr, instance, dataItem, dataParent, indexLoop, true, false);
	if(outputExpr.errors.length){
		console.error(new Error(getGlobalError(outputExpr.errors)));
		throw new Error('Macro binding error (see above)');
	}
	
	
	//accumulate pipes transformation
	for(let k in expr.pipes){
		let tplFuncPipe = getPipe(expr.pipes[k]);
		outputExpr.outputValue = tplFuncPipe.func(outputExpr.outputValue);
	}
	return outputExpr.outputValue;
}


