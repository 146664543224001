import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';



export class StorageService extends Service<typeof config>{
	
	
	public isset(key:string):boolean
	{
		return (localStorage.getItem(key) !== null);
	}
	
	
	public getBoolean(key:string, _default:boolean = false):boolean
	{
		if(localStorage.getItem(key) === null) return _default;
		return (localStorage.getItem(key) == '1');
	}
	
	public setBoolean(key:string, value:boolean):void
	{
		localStorage.setItem(key, value ? '1' : '0');
	}
	
	
	public getObject(key:string):any
	{
		if(localStorage.getItem(key) === null) return null;
		return JSON.parse(localStorage.getItem(key));
	}
	
	public setObject(key:string, value:any):void
	{
		localStorage.setItem(key, JSON.stringify(value));
	}
	
	public getString(key:string):string
	{
		return localStorage.getItem(key);
		
	}
	public setString(key:string, value:string):void
	{
		localStorage.setItem(key, value);
	}
	
	public delete(key:string):void
	{
		localStorage.removeItem(key);
	}
	
	public getKeys():string[]
	{
		let output = [];
		for (let i = 0, len=localStorage.length; i<len; ++i ){
			output.push(localStorage.key(i));
		}
		return output;
	}
	
	
}




let serviceClass:any = StorageService;
try{ serviceClass = getDependency<StorageService>(require('theme-iso/base/services/storage/storage.service.user.ts')); }catch(e){}


import * as configSystem from './storage.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/storage/storage.config.user').default;} catch(e){}
config = resolveConfig('storage', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'storage', config)
