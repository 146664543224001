import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';
import { TranslationService } from 'theme/base/services/translation/translation.service';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';

let mainConfig = getMainConfig();

export class FlowChartService extends Service<typeof config>{
	
	protected graph:Graph;
	protected flowchartVariables:any;
	protected flowchartVariablesPrev:any;
	protected translationService:TranslationService;
	protected modules:any;
	protected node:GameNode;
	protected nodeSave:GameNode;
	protected level:number;
	protected userdataService:UserDataService;
	
	protected defaultWaitInputUser:boolean = true;		//temp


	public onInit(): void {
		this.userdataService = this.getServiceInstance<UserDataService>('userdata');
	}
	
	
	
	onViewReady()
	{
		console.log('FlowChartService.onViewReady');
		this.translationService = this.getServiceInstance<TranslationService>('translation');
		broadcaster.addListener(Evt.FRONT_COMMAND, this.onFrontCommand.bind(this));
		broadcaster.addListener(Evt.CHARTFLOW_CONTINUE, this.onContinue.bind(this));
		broadcaster.addListener(Evt.CHARTFLOW_JUMP, this.onJump.bind(this));
		
	}
	

	/* 
	UserDataManager
		checkpoint
		setvariable
		
	BackgroundManager
		setBackground
		
	AppManager
		start(ModuleDialog)
		end (ModuleDialog)

	ModuleDialog
		npcAction
		npcTalk
		playerTalk(choice, 

	AudioManager
		playSound
	*/
	
	reset(graph:Graph, nodeId:string, flowchartVariables:any):void
	{
		//map modules id to be called from the flowchart
		this.modules = this.getModulesId();
		this.userdataService = (<UserDataService>this.modules.userdata);
		
		this.graph = graph;
		/* 
		for(let k in this.graph){
			let node = this.graph[k];
			node.id = k;
		}
		 */
		
		this.flowchartVariablesPrev = null;
		this.flowchartVariables = flowchartVariables || {};
		
		
		//-------------------------
		//resync state by executing timelines
		
		//order state commands chronologically
		
		animator.reset();
		animator.setSyncMode(true);
		animator.setChainingDelay(0);
		let state = this.userdataService.getCurrentData().flowchartState;
		let orderedstate = [];
		for(let k in state) orderedstate.push(state[k]);
		orderedstate.sort((a, b) => a.index > b.index ? 1 : -1);
		
		//execute them (with instant timeline animation)
		for(let k in orderedstate){
			this.execCommand(orderedstate[k].command, orderedstate[k].command.args, null, false);
		}
		
		//dispatch set variable to trigger animations
		this.dispatchSetVariable();
		
		animator.completeAnimations();
		animator.setSyncMode(false);
		
		//start recursion in the graph
		let firstNode = nodeId ? this.graph[nodeId] : this.graph['start'];
		if(!firstNode){
			console.warn(`node id "${nodeId}" doesnt exist, using "start" instead`);
			firstNode = this.graph['start'];
		}
		this.rec(firstNode, null, 0);
		
	}
	
	

	
	
	
	protected rec(node:GameNode, parentNode:GameNode, level:number)
	{
		/**
		for player choices, or other module like Point & click :
		
		the command of a node interactiveList can only contain 1 argument which is an array of object of type InteractiveListItem
		
		- we loop over those InteractiveListItem
		- we define indexPrefilter based on their index in the array
		- filter command.args by condition
		*/
		
		let args = node.command ? node.command.args : null;
		
		if(node.interactiveList){
			
			let index = 0;
			args = [...args];
			args[0] = args[0].map((choice:InteractiveListItem) => {
				choice.indexPrefilter = index;
				index++;
				return choice;
			});
			args[0] = args[0].filter((choice:InteractiveListItem) => {
				return this.evalCondition(choice.condition);
			});
		}
		
		//execute command from the node
		//if very first node, we need to skip it because it's been already executed by userdata.flowchartState
		let allowSave:boolean = true;
		if((node.command && level > 0) || (node.command && node.command.function == "startSequence")){
			
			if(node.animDelay !== undefined) animator.setChainingDelay(node.animDelay);
			
			//global variable waitUserInput for npcTalk (dirty compromise)
			//could be handled on the flowchart compilation side as an additional arg
			
			let stateHash = this.execCommand(node.command, args, node);
			
			//if node has a command that doesn't return a state hash : we forbid to save to this node
			if(!stateHash) allowSave = false;
		}
		
		if (!node.waitUserInput) this.followNodes(node, level);
		else{
			this.node = node;
			if(allowSave) this.nodeSave = node;	//save the last node that was allowed for saving
			this.level = level;
			
			//savable means : associated to no command or a command with hashstate (skip the command without hashstate which will cause bugs)
			
			
			//save cookies if needed
			//this is done outside of setvariable command to save ressources
			this.userdataService.updateData('flowchartNodeId', this.nodeSave.id);
			this.userdataService.saveProgress();
			//test
			
			
			//dispatch event for new variables
			//à discuter avec guillaume :
			//positionner ça ici est optimal en terme de perf mais implique une perte de control
			//sur l'ordre des animations, un setVariable ne sera pas executé immédiatement mais à la fin d'une sequence (apres un waitUserInput)
			
			this.dispatchSetVariable();
		}
		
	}
	
	
	/**
	 * loop over each charflow variables
	 * compare with a previous clone of the object
	 * if value changed, dispatch an event
	 */
	protected dispatchSetVariable():void
	{
		if(this.flowchartVariables){
			for(let k in this.flowchartVariables){
				if(this.flowchartVariablesPrev === null || this.flowchartVariables[k] !== this.flowchartVariablesPrev[k]){
					broadcaster.dispatchEvent(Evt.SET_VARIABLE, [k, this.flowchartVariables[k]]);
				}
			}
			this.flowchartVariablesPrev = { ...this.flowchartVariables };
		}
	}
	
	
	
	/**
	 * normally, commands are written in the flowchart
	 * but in some cases, like hiding the footer after a user input
	 * the hide command can only be triggered by the front (and not written in the flowchart)
	 * in that case, the service will dispatch an event FRONT_COMMAND and pass the command to be called
	 * (this allow for the sync system of flowchartState to work propertly and in a generic way)
	 */
	public onFrontCommand(command:Command):void
	{
		animator.setChainingDelay(0);
		this.execCommand(command, command.args, null);
	}
	
	
	
	
	/**
	 * entry point for restarting the recursion
	 */
	public onContinue(selectedIndex:number = -1):void
	{
		this.followNodes(this.node, this.level, selectedIndex);
	}
	
	/**
	 * jump to a specific node
	 */
	public onJump(nodeId:string):void
	{
		this.node = this.graph[nodeId];
		this.nodeSave = this.node;		
		//assume that a node where we jump is necessary savable
		
		this.userdataService.updateData('flowchartNodeId', this.node.id);
		this.userdataService.saveProgress();
	}
	
	
	
	
	/* 
	loop over node.targets
			find first node where condition true (or use selectedIndex if passed as arg)
			execCommand (list)
			find node with target id
			rec node
	*/
	protected followNodes(node:GameNode, level:number, selectedIndex:number = -1)
	{
		let len:number = node.targets ? node.targets.length : 0;
		let start=0; 
		let end = len;
		
		//answer selected
		if(selectedIndex !== -1){
			start = selectedIndex;
			end = start+1;
		}
		//conditions
		else{
			for(let i=start;i<end;i++){
				let target:GameNodeTarget = node.targets[i];
				let resultCondition;
				resultCondition = this.evalCondition(target.condition);
				
				if(resultCondition){
					start = i;
					end = start+1;
					break;
				}
			}
		}
		
		//follow branch path (can not be multiple)
		for(let i=start;i<end;i++){
			
			let target:GameNodeTarget = node.targets[i];
			if(target.commands){
				for(let k in target.commands){
					let command = target.commands[k];
					let chainingParams = this.resolveChainingConfig(command.function);
					if(chainingParams) animator.setChainingDelay(chainingParams.delay);
					
					this.execCommand(command, command.args, node);
				}
			}
			let newNode:GameNode = this.graph[target.id];
			this.rec(newNode, node, level + 1);
		}
	}
	
	protected resolveChainingConfig(f:string):{delay:number}
	{
		if((<any>this.config.chainingParams)[f]) return (<any>this.config.chainingParams)[f];
		else this.config.chainingParams.global;
	}
	
	
	protected execCommand(command:Command, args:any[], node:GameNode, storeState:boolean = true):any
	{
		let nodeId = node ? node.id : "";
		
		// console.log(command)
		let module:any = this.resolveModule(command.module);
		if(!module) throw new Error(`flowchart node ${nodeId}, module "${command.module}" doesn't exist`);
		
		//temp : must be changed by guillaume in compilation
		if(module === this){
			if(command.function === 'start') command.function = 'startModule';
			else if(command.function === 'end') command.function = 'endModule';
		}
		
		
		//temp
		//for commmand dialog.npcTalk, arg waitUserInput must be added
		if(command.function === 'npcTalk' && args.length < 7){
			args.push(this.defaultWaitInputUser);
		}
		
		
		let f:Function = module[command.function];
		if(!f) throw new Error(`flowchart node ${nodeId}, function ${command.module}.${command.function}" doesn't exist`);
		
		// todo : 
		// to re-enable this error validation, 2 solutions :
		// 1 - count the complete list of args (function.length doesn't count optional args) / but solution for counting is not ideal (stringify def and analyze string)
		// 2 - remove all optional args for commands definitions (but audio.playSound ideally needs optional args)
		/* 
		if(f.length !== args.length){
			throw new Error(`flowchart node ${nodeId}, function ${command.module}.${command.function}" expects ${f.length} arguments, ${args.length} given`);
		}
		*/
		
		//execution
		f = f.bind(module);
		
		
		//state hash is a key to store commands
		//if function returns one, we need to store that command in userdata so we can re-sync the state at init later
		//otherwise, it means that this command doesn't need to be stored / synced
		//state hash is created from inside the command, 
		//it must be carefully chosen depending on what we want to exclude or keep
		
		let stateHash = f(...args);
		let type = 'add';
		if(stateHash && typeof stateHash !== 'string'){
			type = stateHash.type;
			stateHash = stateHash.hash;
		}
		if(stateHash && storeState){
			if(type === 'add'){
				let command2 = {...command};
				command2.args = args;
				this.userdataService.saveFlowchartState(stateHash, command2);
			}
			else if(type === 'delete') this.userdataService.deleteFlowchartState(stateHash);
			else throw new Error(`wrong type of operation "${type}"`);
		}
		return stateHash;
	}
	
	
	
	protected resolveModule(id:string):any
	{
		return this.modules[id];
	}
	
	
	//a déplacer ds module UserData
	/*
	eval the action by pluging the variable to a local object
	
	*/
	
	
	protected checkpoint(id:string = null):void
	{
		let key = 'n-checkpoint';
		if(id) key += '-'+id;
		this.userdataService.save(key, this.userdataService.getCurrentData());
	}
	
	
	protected setvariable(action:string):void
	{
		action = action.replace(/\$/g, 'this.flowchartVariables.');
		try{
			eval(action);
		}
		catch(e){
			throw new Error(`error with setvariable "${action}"`);
		}
		
		//update user data
		//this has to be done here and not at save time
		//because in case of bug, this data must be available to store it as a save
		this.userdataService.updateData('flowchartVariables', this.flowchartVariables);
		
	}
	
	
	public startModule(moduleId:string):void
	{
		
	}
	public endModule(moduleId:string):void
	{
		
	}

	public startSequence():void
	{
		
	}
	public endSequence():void
	{
		
	}

	protected goToSequence(index: number): void
	{
		let promptService = this.getServiceInstance<PromptService>('prompt');
		let mediaService = this.getServiceInstance<MediaService>('media');
		let bgService = this.getServiceInstance<BackgroundService>('background');
		let dataService = this.getServiceInstance<DataService>('data');

		let viewportPrompt = <HTMLElement>document.querySelector('.prompt-viewport');
		let viewportMedia = <HTMLElement>document.querySelector('.media-viewport');
		let bgViewport = <HTMLElement>document.querySelector('.background-viewport');

		promptService.destroy();
		mediaService.destroy();

		this.userdataService.updateData('indexSequence', index);
		this.reset(dataService.sequences[index].nodes, null, this.flowchartVariables);

		bgService.reset(bgViewport);
		promptService.reset(viewportPrompt);
		mediaService.reset(viewportMedia);
	}
	
	
	
	
	protected evalCondition(cond:string):boolean
	{
		if(cond === '' || cond === null) return true;
		cond = cond.replace(/\$/g, 'this.flowchartVariables.');
		let output:boolean;
		try{
			output = eval(cond);
		}
		catch(e){
			throw new Error(`error with evalCondition "${cond}"`);
		}
		return output;
	}
	
	
	public tracerec(msg:any, indent:number):void
	{
		let prefix:string = '';
		for(let i=0;i<indent;i++) prefix += '  ';
		console.log(prefix + msg);
	}

	protected getModulesId(){
		// cette fonction doit toujours être override dans une implémentation projet
		return {
			'userdata': this.getServiceInstance<UserDataService>('userdata'),
			'audio': this.getServiceInstance<AudioService>('audio'),
			'prompt': this.getServiceInstance<PromptService>('prompt'),
			'media': this.getServiceInstance<MediaService>('media'),
			'background': this.getServiceInstance<BackgroundService>('background'),
			'flowchart': this,
			'application': this,
		}
	}
}


//former flowchart interface
export interface Graph {
	[name: string]: GameNode;
}

export function createMockGameNode(data:any = null):GameNode{
	let output:GameNode = {
		nodeType:null,
		waitUserInput:false,
		targets:[],
		id:null,
	};
	output = {...output, ...data};
	return output;
};

export interface GameNodeTarget{
	
	id:string,
	condition:string,
	commands:Command[],
};


export function createMockGameNodeTarget(data:any = null):GameNodeTarget{
	let output:GameNodeTarget = {
		id:null,
		condition:null,
		commands:[],
	};
	output = {...output, ...data};
	return output;
};


/* 
dialog_npcTalk
    - * talker : [string] (pour spécifier le nom du perso = nom du fichier du perso à charger en front)
    - * emote : [string] optionnel (pour specifier l'émotion du talker).
    - wordingKey : [string] (clé de wording qui correspondra aux vrais contenus multilangue à récupérer dans les data de wordings)
    - * text : [string multilangue] (contenu de la bulle à afficher)
    - textContainer : [string] optionnel (pour pouvoir spécifier quelle bulle d'affichage utiliser si différente de celle par défaut)
    - speed : [string] optionnel (pour pouvoir spécifier une vitesse d'affichage du texte à l'écran autre que la vitesse par défaut)
dialog_npcAction
    - * npc : [string] (pour spécifier le nom du perso = nom du fichier du perso à charger en front)
    - * emote : [string] optionnel (pour specifier l'émotion du talker).
    - * action : [string] (pour spécifier l'action à faire au perso :
        - "in" > entrer ou repositioner sur la scène
        - "out" > sortir de la scène
    - * position : [string] (nom du container de position du personnage, pour le "in")
dialog_playerTalk
    - * type : [liste de choix prédéfinis] > choice / thought / simple
    - * text : [string multilangue]
ellipsis
    - * text : [string multilangue]
node
background
    - * image : [string] (nom du fichier image pour le BG, par ex. : bg_01.png)
    - audio : [string] optionnel (nom du fichier audio d'ambiance correspondant au BG)
eventAudio
    - * file : [string] (nom du fichier mp3)
    - delay : [string] optionnel (delai de lecture en seconde)
    - repeat : [string] optionnel (nombre de repetition 0 = infini)
 */
export interface GameNode{
	
	nodeType:NodeType,
	waitUserInput?:boolean;
	targets:GameNodeTarget[],
  animDelay?:number,
	command?:Command,
	interactiveList?:boolean,
	
	//calculated
	id:string,
};

export interface Command{
	module:string,
	function:string,
	args:any[],
}

export enum NodeType{
	start = 'start',
	start_dialog = 'start_dialog',
	end = 'end',
	end_dialog = 'end_dialog',
	dialog_npcTalk = 'dialog_npcTalk',
	dialog_npcAction = 'dialog_npcAction',
	dialog_playerTalk = 'dialog_playerTalk',
	dialog_playerTalkChoices = 'dialog_playerTalkChoices',
	ellipsis = 'ellipsis',
	node = 'node',
	background = 'background',
	eventAudio = 'eventAudio',
}
export enum PlayerTalkType{
	choice = 'choice',
	thought = 'thought',
	simple = 'simple',
}
export enum NpcAction{
	in = 'in',
	out = 'out',
	transition = 'transition',
}
export enum ChainingMode{			//todo
	sequenced = 'sequenced',
	simultaneous = 'simultaneous',
}

export interface InteractiveListItem{
	condition:string, 
	wordingkey:string, 
	text:string,
  animDelay?:number,
	
	indexPrefilter?:number,
	payload?:any,
}


export interface Sequence{
	nodes:Graph,
};


export function createMockSequence(data:any = null):Sequence{
	let output:Sequence = {
		nodes:null,
	};
	output = {...output, ...data};
	return output;
};



let serviceClass:any = FlowChartService;
try{ serviceClass = getDependency<FlowChartService>(require('theme-iso/base/services/flowchart/flowchart.service.user.ts')); }catch(e){}


import * as configSystem from './flowchart.config';
import { AudioService } from '../audio/audio.service';
import { BackgroundService } from '../background/background.service';
import broadcaster from 'core/lib/broadcaster';
import { Evt } from 'theme/base/config/events';
import animator from 'core/lib/animator';
import { PromptService } from 'theme/base/services/prompt/prompt.service';
import { MediaService } from '../media/media.service';
import { DataService } from '../data/data.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/flowchart/flowchart.config.user').default;} catch(e){}
config = resolveConfig('flowchart', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'flowchart', config)
