import { ModuleDef } from "./core";

let servicesByClass = new Map();
let servicesByID = new Map();
let instancesByClass = new Map();
let tplFuncs = new Map();
let tplPipes = new Map();

export class Service<T>{
	// public anims:any;	//disabled
	public config:T;
	public onInit(){}
	public onViewReady(){}
	public getServiceInstance<Type>(serviceId:string):Type{
		return (<Type>getServiceByID(serviceId));
	}
}

export interface TplFunction{
	serviceInst:Service<any>;
	id:String;
	func:Function;
}


/**
 * remove all from instancesByClass that is not defined in arg classes
 */

export function registerServiceClass(classes:any[])
{
	for (let [_class, instance] of instancesByClass) {
		
		let found:boolean = false;
		//check if classes contains same or parent class
		for(let i in classes){
			let c:any = classes[i];
			if(_class === c || _class.prototype instanceof c){
				found = true;
				break;
			}
		}
		
		if(!found){
			let def:ModuleDef = servicesByClass.get(_class);
			instancesByClass.delete(_class);
			
		}
	}
	console.log('test');
}


/**
 * call the lifecycle hook for services
 * 1 - onInit is for declaring listening
 * 2 - onViewReady is for firing events
 * this way, there won't be any order dependencies
 */
export function initServices()
{
	for (let [key, value] of instancesByClass) value.onInit();
}
export function viewReadyServices()
{
	for (let [key, value] of instancesByClass) value.onViewReady();
}

/**
 * used for command in template
 * example :
 * [innerHTML]="translate('key.translation')"
 * (click)="playsound:path/sound.mp3"
 * 
 */
export function registerTplFunc(_class:any, id:string):void
{
	let serviceInst = instancesByClass.get(_class) || _class;
	if(!serviceInst[id]) throw new Error(`template function "${id}" is not defined`);
	
	let func = serviceInst[id].bind(serviceInst);
	let tplFunc:TplFunction = {serviceInst, func, id};
	tplFuncs.set(id, tplFunc);
}
export function getTplFunc(id:string):TplFunction
{
	return (<TplFunction>tplFuncs.get(id));
}

export function registerPipe(_class:any, id:string):void
{
	let serviceInst = instancesByClass.get(_class) || _class;
	if(!serviceInst[id]) throw new Error(`template function "${id}" is not defined`);
	
	//pipe is not bound to serviceInst to disable the use of "this"
	//this is to discourage external state, which isn't handled for pipes
	//(which is by design, because pipes are supposed to be pure)
	let func = serviceInst[id];
	let tplPipe:TplFunction = {serviceInst, func, id};
	tplPipes.set(id, tplPipe);
}
export function getPipe(id:string):TplFunction
{
	return (<TplFunction>tplPipes.get(id));
}



export function registerServiceDef(_class:any, id:string, config:any, anims:any = null):void
{
	let def:ModuleDef = { id, class:_class, config, anims};
	servicesByClass.set(_class, def);
	servicesByID.set(id, def);
	
	let inst:Service<any> = new _class();
	inst.config = def.config;
	// inst.anims = def.anims;	//disabled
	instancesByClass.set(_class, inst);
}
export function getServiceByID(id:string):any
{
	let def:ModuleDef = servicesByID.get(id);
	if(!def) throw new Error(`service id "${id}" was not found`)
	return instancesByClass.get(def.class);
}

