import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './debug-gsap.config';


export class DebugGsapModule extends Module<typeof config>{
	
	override onInit(): void {
		
	}
}



let moduleClass:any = DebugGsapModule;
try{ moduleClass = getDependency<DebugGsapModule>(require('theme-iso/base/modules/debug/submodules/debug-gsap/debug-gsap.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./debug-gsap.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-gsap/debug-gsap.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./debug-gsap.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/debug/submodules/debug-gsap/debug-gsap.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/debug/submodules/debug-gsap/debug-gsap.config.user').default;} catch(e){}
config = resolveConfig('debug-gsap', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./debug-gsap.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-gsap/debug-gsap.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'debug-gsap', template, config, anims, css)