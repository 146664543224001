export default {
	
	default : {
		pointsStartHigh:8,
		pointsStartLow:4,
		pointPerRoundHigh:6,
		pointPerRoundLow:3,
		nbRound:10,
		timeAlert:10,
		
		ctFactorReward1:1,
		ctFactorReward2:1,
		ctFactorCost:1,
		
		scenarioFactors:{
				A: {
					innovationFactor: 1,
					economyTradeFactor: 1,
					naturalEnergyRessourcesFactor: 1,
					climateChangeFactor: 1,
					qualityOfLifeFactor: 1,
					governanceCorportateFactor: 1,
					socialEquityFactor: 1
			},
			B: {
					innovationFactor: 1,
					economyTradeFactor: 1,
					naturalEnergyRessourcesFactor: 1,
					climateChangeFactor: 1,
					qualityOfLifeFactor: 1,
					governanceCorportateFactor: 1,
					socialEquityFactor: 1
			},
		}
	}
}