import { getServiceByID } from "./services";
import { applyEventBinding, resolveMacroBinding, updateDomAttr } from "./templateMacro";

let modulesByClass = new Map();
let modulesbyID = new Map();
let modulesDef:ModuleDef[] = [];

export function registerModuleDef(_class:Function, id:string, tpl:string, config:any, anims:any, css:string, baseDef:ModuleDef = null):void
{
	let def:ModuleDef = { id, template: tpl, class:_class, config, anims, css, baseDef};
	if(_class) modulesByClass.set(_class, def);
	modulesbyID.set(id, def);
	modulesDef.push(def);
}

export function getModuleDefByClass(_class:Function):ModuleDef
{
	return modulesByClass.get(_class);
}
export function getModuleDefs():ModuleDef[]
{
	return modulesDef;
}

export function getModuleDefByID(id:string):ModuleDef
{
	return modulesbyID.get(id);
}

/**
 * does nothing just for forcing the user to use the class
 * and have it added by webpack to the bundle
 */
export function registerModuleClass(classes:any[])
{
	
}




export function getDependency<T>(obj:any):T
{
	return obj.default;
}






export interface ModuleInstance{
	classInstance:Module<any>,
	dom:HTMLElement,
	childrens:{[name:string]: ModuleInstance},
}
export interface ModuleDef{
	id:string,
	class:Function,
	template?:string,
	config:any,
	anims:any,
	css?:string,
	baseDef?:ModuleDef,
	
	//filled at init parse template (parseTemplates)
	macroImports?:MacroDef[],
	macroEvents?:MacroDef[],
	macroBindAttr?:MacroDef[],
	macroLoop?:MacroDef[],
}

export interface MacroDef{
	tpl:string;
	id:string;
	key:string;
	value:string;
	type:string;
	expressions?:TplExpr[];
	subtpl?:string;
}


export interface TplExpr{
	expression:string,
	functions:string[],
	variables:any[],
	pipes:string[],
}

export interface BindingService{
	cmd:string;
	arg:string;
	optionalArgs:any[];
}

export class Module<T>{
	public def:ModuleDef;
	public attrBinding:any = null;
	public config:T;
	public moduleInstance:ModuleInstance;
	public dom:HTMLElement;			//protected but public for tests
	private childrens:{[name:string]:ModuleInstance};
	/* 
	private listeners:{
		[name:string]: {fct:any}[]
	} = {};
	
	
	public dispatchEvent(name:string, args:any[] = []):void
	{
		var listeners = this.listeners[name];
		args = args || [];
		if(listeners !== undefined) {
			let len = listeners.length;
			for(let i = 0; i < len; i++){
				listeners[i].fct.apply(null, args);
			}
		}
	}
	public addListener(name:string, fct:any):void
	{
		(this.listeners[name] = this.listeners[name] || []).push({fct});
	}
	 */
	/**
	 * called manually by the user when a data array used in a loop is updated
	 * this will look into the loop definitions matching array_name (already parsed at init)
	 * and inject the associated module template into the associated dom element
	 * 
	 */
	public updateLoop(array_name:string, def:ModuleDef = null, dom:HTMLElement = null, dataitem:any = null)
	{
		//when used for nested loop (recursion), args def is passed
		if(!def) def = this.def;
		if(!dom) dom = this.dom;
		if(!dataitem) dataitem = this.attrBinding;
		
		let loops = def.macroLoop;
		let tab;
		for(let i in loops){
			let match = loops[i];
			tab = match.value.split(':');	//match.value has format "array_name:id_module"
			if(!array_name || tab[0] === array_name){	//filter by array_name
				let containerElmt = <HTMLElement>dom.querySelector('#'+match.id);
				let arr:any[] = dataitem[tab[0]];	//get data array
				
				//we can also loop over object, we convert it to an array first
				if(!Array.isArray(arr)){
					let obj:any = arr;
					arr = [];
					for(let k in obj) arr.push({'key' : k, 'item' : obj[k]});
				}
				
				//inject duplicate of templates
				let childModuleDef = getModuleDefByID(tab[1]);
				let tabhtml = arr.map(item => childModuleDef.template);
				let html = tabhtml.join('\n');
				containerElmt.innerHTML = html;
				
				//apply template macro
				for(let indexChild = 0; indexChild < containerElmt.children.length; indexChild++){
					let childDom = <HTMLElement>containerElmt.children[indexChild];
					let loopDataItem = arr[indexChild];
					
					//data binding
					for(let k in childModuleDef.macroBindAttr){
						
						let macroDef = childModuleDef.macroBindAttr[k];
						let instance = (<Module<null>>this);	//cast
						//todo migration
						
						let outputValue = resolveMacroBinding(childModuleDef, macroDef, instance, loopDataItem, dataitem, indexChild);
						
						let dom = childDom.id === macroDef.id ? childDom : <HTMLElement>childDom.querySelector('#'+macroDef.id);
						updateDomAttr(dom, macroDef.key, outputValue);
					}
					
					
					//event binding
					for(let k in childModuleDef.macroEvents){
						let macroDef = childModuleDef.macroEvents[k];
						applyEventBinding(this, childDom, macroDef.id, macroDef.key, macroDef, loopDataItem, dataitem, indexChild);
					}
					
					
					//nested loops (recursion)
					if(childModuleDef.macroLoop.length > 0){
						//passing null on array_name means no filter
						
						//accumulate data from the root element so children have access to the scope
						//priority is given to nested scope (arr)
						let dataitemChild = {...dataitem, ...arr[indexChild]};
						this.updateLoop(null, childModuleDef, childDom, dataitemChild);
					}
					
				}
				
			}
		}
	}
	
	
	
	
	public setDomElements(dom:HTMLElement, childrens:{[name:string]:ModuleInstance})
	{
		this.dom = dom;
		this.childrens = childrens;
	}
	
	public onInit(){}
	public onViewReady(){}
	public onDestroy(){}
	
	//protected but public for tests
	public getModuleInstance(moduleid:string, index:number = 0):Module<any>
	{
		let id = `${moduleid}_${index}`;
		if(!this.childrens[id]) throw new Error(`Module instance id "${id}" doesn't exist`);
		return this.childrens[id].classInstance;
	}
	
	public getServiceInstance<Type>(serviceId:string):Type
	{
		return (<Type>getServiceByID(serviceId));
	}
	
}

