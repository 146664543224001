import gsap from 'gsap';
import { NpcPosition } from '../dialog.service';
export default {
	
	
	
	in:(dom:HTMLElement, position:NpcPosition, npc:string, emote:string, offset:any):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline({defaults:{immediateRender: false}});
		var offsetX = offset[0];
		var offsetY = offset[1];
		switch (position.id) {
			case '1': // bottom right
			case '2':
				t.fromTo(dom, 
					{opacity:0, right:position.right + offsetX, bottom:position.bottom + offsetY - 600}, 
					{opacity:1, right:position.right + offsetX, bottom:position.bottom + offsetY, duration:1.00, transformOrigin: "center bottom"}
				);
				break;
			case '3': // bottom left
				t.fromTo(dom, 
					{opacity:0, left:position.left + offsetX, bottom:position.bottom + offsetY - 600}, 
					{opacity:1, left:position.left + offsetX, bottom:position.bottom + offsetY, duration:1.00, transformOrigin: "center bottom"}
				);
				break;
			case '4': // top left
				t.fromTo(dom, 
					{opacity:0, left:position.left + offsetX, top:position.top + offsetY - 600}, 
					{opacity:1, left:position.left + offsetX, top:position.top + offsetY, duration:1.00, transformOrigin: "center top"}
				);
				break;
			case '5': //top right
				t.fromTo(dom, 
					{opacity:0, right:position.right + offsetX, top:position.top + offsetY - 600}, 
					{opacity:1, right:position.right + offsetX, top:position.top + offsetY, duration:1.00, transformOrigin: "center top"}
				);
				break;
			default: // bottom right (position '1')
				t.fromTo(dom, 
					{opacity:0, right:position.right + offsetX, bottom:position.bottom + offsetY - 600}, 
					{opacity:1, right:position.right + offsetX, bottom:position.bottom + offsetY, duration:1.00, transformOrigin: "center bottom"}
				);
				break;
		}
		return t;
	},
	
	
	out:(dom:HTMLElement, position:NpcPosition, npc:string, emote:string, offset: any):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		var offsetX = offset[0];
		var offsetY = offset[1];
		switch (position.id) {
			case '1': // bottom right
			case '2':
				t.to(dom, 
					{opacity:0, right:position.right + offsetX, bottom:position.bottom + offsetY - 600, duration:1.00, transformOrigin: "center bottom"}
				);
				break;
			case '3': // bottom left
				t.to(dom, 
					{opacity:0, left:position.left + offsetX, bottom:position.bottom + offsetY - 600, duration:1.00, transformOrigin: "center bottom"}
				);
				break;
			case '4': // top left
				t.to(dom, 
					{opacity:0, left:position.left + offsetX, top:position.top + offsetY + 600, duration:1.00, transformOrigin: "center top"}
				);
				break;
			case '5': //top right
				t.to(dom, 
					{opacity:0, right:position.right + offsetX, top:position.top + offsetY + 600, duration:1.00, transformOrigin: "center top"}
				);
				break;
			default: // bottom right (position '1')
				t.to(dom, 
					{opacity:0, right:position.right + offsetX, bottom:position.bottom + offsetY - 600, duration:1.00, transformOrigin: "center bottom"}
				);
				break;
		}

		return t;
	},
	
	
	transition:(dom:HTMLElement, positionPrev:NpcPosition, positionTo:NpcPosition, npc:string, emote:string, offset:any):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		var offsetX = offset[0];
		var offsetY = offset[1];
		switch (positionTo.id) {
			case '1': // bottom right
			case '2':
				t.to(dom, 
					{opacity:1, right:positionTo.right + offsetX, bottom:positionTo.bottom + offsetY, duration:1.00}
				);
				break;
			case '3': // bottom left
				t.to(dom, 
					{opacity:1, left:positionTo.left + offsetX, bottom:positionTo.bottom + offsetY, duration:1.00}
				);
				break;
			case '4': // top left
				t.to(dom, 
					{opacity:1, left:positionTo.left + offsetX, top:positionTo.top + offsetY, duration:1.00}
				);
				break;
			case '5': //top right
				t.to(dom, 
					{opacity:1, right:positionTo.right + offsetX, top:positionTo.top + offsetY, duration:1.00}
				);
				break;
			default: // bottom right (position '1')
				t.to(dom, 
					{opacity:1, right:positionTo.right + offsetX, bottom:positionTo.bottom + offsetY, duration:1.00}
				);
				break;
		}
		t.to(dom, 
			{opacity:1, right:positionTo.right + offsetX, bottom:positionTo.bottom + offsetY, duration:1.00}
		);
		return t;
	},
	
	
	
	
}