import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';


export class LandingModule extends Module<typeof config>{
	
	override onInit(): void {

	}

	public goToHome(){
		router.navigate('home');
	}
	
	
}


let moduleClass:any = LandingModule;
try{ moduleClass = getDependency<LandingModule>(require('theme-iso/base/modules/pages/landing/landing.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./landing.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/landing/landing.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./landing.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/landing/landing.module.user.scss'));} catch(e){}
injectStyle(css);

import * as configSystem from './landing.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/landing/landing.config.user').default;} catch(e){}
config = resolveConfig('landing', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./landing.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/landing/landing.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};




registerModuleDef(moduleClass, 'landing', template, config, anims, css)