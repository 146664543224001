import gsap from 'gsap';
export default {
	
	
	/**
	 * page transitions (root outlet)
	 */
	transition_x_x: (domOut:HTMLElement, domIn:HTMLElement):GSAPTimeline =>{
		
		const timeline:GSAPTimeline = new gsap.core.Timeline();
		if(domOut){
			timeline.fromTo(domOut, {opacity:1}, {opacity: 0, duration: 0.25});
		}
		if(domIn){
			timeline.fromTo(domIn, {opacity:0}, {opacity: 1, duration: 0.4, delay:0});
		}
		return timeline;
	},
	
	/**
	 * modal transitions (modal outlet)
	 */
	transition_x_x_modal: (domOut:HTMLElement, domIn:HTMLElement):GSAPTimeline =>{
		
		const timeline:GSAPTimeline = new gsap.core.Timeline();
		if(domOut){
			timeline.fromTo(domOut, {opacity:1}, {opacity: 0, duration: 0.1});
		}
		//1st modal : must show background
		else{
			timeline.fromTo('.bg-modal', {opacity:0}, {opacity: 1, duration: 0.25});
			timeline.fromTo('.btn_close_menu', {opacity:0, display:"block"}, {opacity: 1, duration: 0.25 }, 0);
		}
		
		if(domIn){
			timeline.fromTo(domIn, {opacity:0}, {opacity: 1, duration: 0.15, delay:0});
		}
		//last modal : must hide background
		else{
			timeline.fromTo('.bg-modal', {opacity:1}, {opacity: 0, duration: 0.2});
			timeline.fromTo('.btn_close_menu', {opacity:1}, {opacity: 0, duration: 0.2}, 0);
			timeline.set('.btn_close_menu', {'display':'none'});
		}
		return timeline;
	},
	
	
	transition_x_x_debug: (domOut:HTMLElement, domIn:HTMLElement):GSAPTimeline =>{
		const timeline:GSAPTimeline = new gsap.core.Timeline();
		if(domOut){
			timeline.fromTo(domOut, {opacity:1}, {opacity: 0, duration: 0.1});
		}
		if(domIn){
			timeline.fromTo(domIn, {opacity:0}, {opacity: 1, duration: 0.1, delay:0});
		}
		return timeline;
	},
	
	
	/**
	 * immediate transitions for debug module
	 */
	transition_x_x_debugContent: (domOut:HTMLElement, domIn:HTMLElement):GSAPTimeline =>{
		
		const timeline:GSAPTimeline = new gsap.core.Timeline();
		return timeline;
	},
	
}

