import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './game.config';
import { DataService } from 'theme/base/services/data/data.service';
import { FlowChartService } from 'theme/base/services/flowchart/flowchart.service';
import { Sequence } from 'theme/base/services/flowchart/flowchart.service';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { BackgroundService } from 'theme/base/services/background/background.service';
import { TranslationService } from 'theme/base/services/translation/translation.service';
import { PromptService } from '../../../services/prompt/prompt.service';
import { MediaService } from 'theme/base/services/media/media.service';

export class GameModule extends Module<typeof config>{
	
	protected flowchart:FlowChartService;
	protected bgService:BackgroundService;
	protected promptService: PromptService;
	protected mediaService: MediaService;
	protected translateService: TranslationService;
	
	override onInit(): void {
		
		this.promptService = this.getServiceInstance<PromptService>('prompt');
		this.mediaService = this.getServiceInstance<MediaService>('media');
		let viewportPrompt = <HTMLElement>this.dom.querySelector('.prompt-viewport');
		let viewportMedia = <HTMLElement>this.dom.querySelector('.media-viewport');

		this.promptService.reset(viewportPrompt);
		this.mediaService.reset(viewportMedia);
		
		this.bgService = this.getServiceInstance<BackgroundService>('background');
		let bgViewport = <HTMLElement>this.dom.querySelector('.background-viewport');
		this.bgService.reset(bgViewport);
		
		this.flowchart = this.getServiceInstance<FlowChartService>('flowchart');
		
		//keyboard debug
		let mainConfig = getMainConfig();
		
		
		this.translateService = this.getServiceInstance<TranslationService>('translation');
		
	}
	
	
	
	
	public override onViewReady(): void 
	{
		let dataService = this.getServiceInstance<DataService>('data');
		let userData = this.getServiceInstance<UserDataService>('userdata');
		
		let nodeId:string = userData.getCurrentData().flowchartNodeId;
		let flowchartVariables:any = userData.getCurrentData().flowchartVariables || null;
		
		//0 = test_vincent
		//1 = test_playerTalkChoice
		//2 = test_cookie_state
		let indexSequence:number = userData.getCurrentData().indexSequence;
		let sequence:Sequence = dataService.sequences[indexSequence];
		
		this.flowchart.reset(sequence.nodes, nodeId, flowchartVariables);
	}
	
	
	
	override onDestroy():void
	{
		let mainConfig = getMainConfig();
		this.promptService.destroy();
		this.mediaService.destroy();
	}
}



let moduleClass:any = GameModule;
try{ moduleClass = getDependency<GameModule>(require('theme-iso/base/modules/pages/game/game.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./game.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/game/game.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./game.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/game/game.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/game/game.config.user').default;} catch(e){}
config = resolveConfig('game', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./game.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/game/game.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'game', template, config, anims, css)