import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';


export class FullscreenService extends Service<typeof config>{
	
	elem:any;
	
	/* View in fullscreen */
	openFullscreen() {
		
		var elem:any = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE/Edge */
			elem.msRequestFullscreen();
		}
	}

	/* Close fullscreen */
	closeFullscreen() {
		
		var doc:any = document;
		
		if (doc.exitFullscreen) {
			doc.exitFullscreen();
		} else if (doc.mozCancelFullScreen) { /* Firefox */
			doc.mozCancelFullScreen();
		} else if (doc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
			doc.webkitExitFullscreen();
		} else if (doc.msExitFullscreen) { /* IE/Edge */
			doc.msExitFullscreen();
		}
	}
	
	
	isFullscreen():boolean
	{
		let deltaX:number = Math.abs(window.innerWidth - screen.width);
		let deltaY:number = Math.abs(window.innerHeight - screen.height);
		
		if(deltaX < 5 && deltaY < 5) {
			return true;
		} else {
			return false;
		}
	}
	
}



let serviceClass:any = FullscreenService;
try{ serviceClass = getDependency<FullscreenService>(require('theme-iso/base/services/fullscreen/fullscreen.service.user.ts')); }catch(e){}


import * as configSystem from './fullscreen.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/fullscreen/fullscreen.config.user').default;} catch(e){}
config = resolveConfig('fullscreen', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'fullscreen', config)
