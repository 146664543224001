import gsap from 'gsap';
import { dataGlobalHelp } from './help.global';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		//slow transition
		if(!dataGlobalHelp.fastTransition){
			return new gsap.core.Timeline()
			.fromTo('.background > .character', {y: 910}, {y: 0, duration:0.42, ease: "back.out(1.1)"})
			.fromTo('.background > .bubble,.background > .frame', {scale:0}, {scale:1, duration:0.4, ease: "back.out(2.0)"}, '>+0.0')
			.fromTo('.background > .btn-link-cards a,.background > .btn.bottom-right', {y: 200}, {y: 0, duration:0.4, ease: "back.out(2.5)"}, '>+0.0')
			.fromTo('.background > .btn-link-cards .btn.orange', {y: 200}, {y: 0, duration:0.43, ease: "back.out(2.5)"}, '>-0.2')
			;
		}
		//fast transition (for feedback)
		else{
			return new gsap.core.Timeline()
			.fromTo('.background > .character', {y: 910}, {y: 0, duration:0.38, ease: "back.out(1.1)"})
			.fromTo('.background > .bubble,.background > .frame', {scale:0}, {scale:1, duration:0.38, ease: "back.out(2.0)"}, '>-0.35')
			.fromTo('.background > .btn-link-cards,.background > .btn.bottom-right', {y: 200}, {y: 0, duration:0.38, ease: "back.out(2.5)"}, '>-0.35')
			;
		}
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}