/**
 * Dowimoteur
 * 
 * Override module "select-language"
 * Created on Mar 25, 2024
 * 
 */

import broadcaster from 'core/lib/broadcaster';
import { getMainConfig } from 'core/lib/config';
import { Evt } from 'theme/base/config/events';
import { SelectLanguageModule } from 'theme/base/modules/ui/select-language/select-language.module';
import { DataService } from 'theme/base/services/data/data.service';
let mainConfig = getMainConfig();

export default class SelectLanguageSub extends SelectLanguageModule{
	
	
	override onInit(): void
	{
		super.onInit();
		let dataService = this.getServiceInstance<DataService>('data');
		this.attrBinding.langs = dataService.available_languages;
		this.attrBinding.locale = SelectLanguageModule.locale;
		
		this.updateLoop('langs');
	}
	
	
	selectLang(evt:Event)
	{
		let locale = (<any>evt.currentTarget).value;
		console.log('select '+locale);
		
		if(SelectLanguageModule.locale !== locale){
			SelectLanguageModule.locale = locale;
			broadcaster.dispatchEvent(Evt.SELECT_LOCALE, [SelectLanguageModule.locale]);
			this.userdata.updateData('locale', locale);
			this.userdata.saveProgress()
			this.attrBinding.locale = locale;

			var htmlContainer = <HTMLElement>document.getElementsByTagName('html')[0];
			htmlContainer.lang = locale.slice(0, 2);
		}
	}
}