export default {
	//default config
	default : {
		navigateIntro:true,		//if false : we restart from current page (for dev)
		preloadImg:true,
		mockTimePreloadImg:0,			//ms
		timeLandingPage:2000,			//ms

		//base size (before scale)
		uiWidth: 1920,
		uiHeight: 1080,

		//companyUID
		defaultUid: 'upwjtUqBLS3FDo0wL79Ns8mTQVGxU3ql',
		
	},
	dev:{
		navigateIntro:false,
		preloadImg:true,
		mockTimePreloadImg:0,
		timeLandingPage:0,
	}
}