export default {
	
	//default config
	default : {
		_routes : [
			//root
			
			{	path : 'landing', module : 'landing'},
			{	path : 'loading', module : 'loading'},
			{ path : 'home', module : 'home'},
			{ path : 'terms', module : 'terms'},
			{ path : 'results', module : 'results'},
			{ path : 'card-choice', module : 'card-choice'},
			{ path : 'select-game', module : 'select-game'},
			{ path : 'new-game', module : 'new-game'},
			{ path : 'create-players', module : 'create-players'},
			{ path : 'setup-round', module : 'setup-round'},
			{	path : 'final-screen', module : 'final-screen'},
			
			{	path : 'pause-game', module : 'pause-game', outlet: 'modal'},
			{	path : 'confirm-quit', module : 'confirm-quit', outlet: 'modal'},
			{	path : 'delete-game', module : 'delete-game', outlet: 'modal'},
			{	path : 'help', module : 'help', outlet: 'modal'},
		],
		defaultPath : 'landing',
		enablePushState: false,
	},
	
	
	dev:{
		_routes:[
			{path : '1', module : 'debug', outlet: 'debug'},
			{path : 'router', module : 'debug-router', outlet: 'debug-content'},
			{path : 'audio', module : 'debug-audio', outlet: 'debug-content'},
			{path : 'userdata-selector', module : 'debug-userdata', outlet: 'debug-content'},
			{path : 'userdata-editor', module : 'debug-userdata-editor', outlet: 'debug-content'},
			{path : 'gsap', module : 'debug-gsap', outlet: 'debug-content'}
		],
		enablePushState: true,
	},
	
}