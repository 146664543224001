import { registerModuleClass } from './core/lib/core';
import { registerServiceClass, registerTplFunc, registerPipe } from './core/lib/services';

import { ApplicationModule } from './theme/base/modules/application/application.module';

//services
import { AudioService } from './theme/base/services/audio/audio.service';
import { TranslationService } from './theme/base/services/translation/translation.service';
import { UserDataService } from './theme/base/services/userdata/userdata.service';
import { ApiService } from './theme/base/services/api/api.service';
import { DataService } from './theme/base/services/data/data.service';
import { EncryptService } from './theme/base/services/encrypt/encrypt.service';
import { StorageService } from './theme/base/services/storage/storage.service';
import { PipeService } from './theme/base/services/pipes/pipes.service';
import { DebugService } from 'theme/base/services/debug/debug.service';
import { FullscreenService } from 'theme/base/services/utils/fullscreen/fullscreen.service';
import { TplFunctionsService } from 'theme/base/services/tpl-functions/tpl-functions.service';
import { PreloadService } from 'theme/base/services/preload/preload.service';
import { FlowChartService } from 'theme/base/services/flowchart/flowchart.service';
import { DialogService } from 'theme/packages/dialog/dialog.service';
import { BackgroundService } from 'theme/base/services/background/background.service';
import { PromptService } from 'theme/base/services/prompt/prompt.service';
import { MediaService } from 'theme/base/services/media/media.service';


//modules
import { HomeModule } from './theme/base/modules/pages/home/home.module';
import { LoadingModule } from './theme/base/modules/pages/loading/loading.module';
import { LandingModule } from './theme/base/modules/pages/landing/landing.module';
import { SelectLanguageModule } from 'theme/base/modules/ui/select-language/select-language.module';
import { DebugModule } from 'theme/base/modules/debug/debug.module';
import { DebugRouterModule } from 'theme/base/modules/debug/submodules/debug-router/debug-router.module';
import { DebugAudioModule } from 'theme/base/modules/debug/submodules/debug-audio/debug-audio.module';
import { SettingsModule } from 'theme/base/modules/modals/settings/settings.module';
import { DebugUserdataModule } from 'theme/base/modules/debug/submodules/debug-userdata/debug-userdata.module';
import { DebugGsapModule } from 'theme/base/modules/debug/submodules/debug-gsap/debug-gsap.module';
import { GameModule } from 'theme/base/modules/pages/game/game.module';
import { DebugUserdataEditorModule } from 'theme/base/modules/debug/submodules/debug-userdata-editor/debug-userdata-editor.module';
import { bootstrap } from 'core/lib/bootstrap';
import { OutletDef } from 'core/lib/router';
import { TermsModule } from 'theme-iso/base/modules/pages/terms/terms.module';
import { ResultsModule } from 'theme-iso/base/modules/pages/results/results.module';
import { CardChoiceModule } from 'theme-iso/base/modules/pages/card-choice/card-choice.module';
import { SelectGameModule } from 'theme-iso/base/modules/pages/menu/select-game/select-game.module';
import { NewGameModule } from 'theme-iso/base/modules/pages/menu/new-game/new-game.module';
import { CreatePlayersModule } from 'theme-iso/base/modules/pages/menu/create-players/create-players.module';
import { SetupRoundModule } from 'theme-iso/base/modules/pages/menu/setup-round/setup-round.module';
import { PauseGameModule } from 'theme-iso/base/modules/overlay/pause-game/pause-game.module';
import { ConfirmQuitModule } from 'theme-iso/base/modules/overlay/confirm-quit/confirm-quit.module';
import { DeleteGameModule } from 'theme-iso/base/modules/overlay/delete-game/delete-game.module';
import { WarningTimeModule } from 'theme-iso/base/modules/overlay/warning-time/warning-time.module';
import { HelpModule } from 'theme-iso/base/modules/pages/help/help.module';
import { FinalScreenModule } from 'theme-iso/base/modules/pages/final-screen/final-screen.module';
import { HeaderModule } from 'theme-iso/base/modules/ui/header/header.module';
import { GameService } from 'theme-iso/base/services/game/game.service';





//register sub modules which are not part of routes
let modules = [
	ApplicationModule, HomeModule, LandingModule, LoadingModule, GameModule,
	SettingsModule,
	SelectLanguageModule,
	TermsModule, ResultsModule, CardChoiceModule, SelectGameModule, NewGameModule, CreatePlayersModule, SetupRoundModule, 
	HeaderModule,
	PauseGameModule, ConfirmQuitModule, DeleteGameModule, WarningTimeModule, HelpModule, FinalScreenModule, 
	DebugModule, DebugRouterModule, DebugAudioModule, DebugUserdataModule, DebugGsapModule, DebugUserdataEditorModule,
];
registerModuleClass(modules);



//register services
let services = [
	TranslationService, 
	AudioService,
	TplFunctionsService,
	UserDataService,
	DataService,
	EncryptService,
	StorageService,
	ApiService,
	PipeService,
	DebugService,
	FullscreenService,
	PreloadService,
	FlowChartService,
	DialogService,
	BackgroundService,
	PromptService,
	MediaService,
	GameService,
];
registerServiceClass(services);


//register template commands
registerTplFunc(TranslationService, 'translate');
registerTplFunc(TranslationService, 'replaceVar');
registerTplFunc(AudioService, 'playSound');
registerTplFunc(TplFunctionsService, 'multiply');			//for testing



//register pipes
registerPipe(PipeService, 'bbcode');
registerPipe(PipeService, 'invertbool');
registerPipe(PipeService, 'translateimg');
registerPipe(PipeService, 'nl2br');



let outlets:OutletDef[] = [
	{'selector': '.outlet-root'},
	{'selector': '.outlet-modal', 'outlet' : 'modal'},
];
if(process.env.RUNTIME_ENV === 'dev'){
	outlets.push({'selector': '.outlet-debug', 'outlet' : 'debug'});
}


bootstrap(outlets);




