/**
 * Dowimoteur
 * 
 * Base module "create-players"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './create-players.config';
import { createPlayerMock, Player, UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';
import gsap from 'gsap';
import mainConfig from 'theme/base/config/main.config';

export class CreatePlayersModule extends Module<typeof config>{
	
	private inputAdd:HTMLInputElement;
	
	override onInit(): void
	{
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		let game = userdata.getCurrentGame();
		
		this.attrBinding.players = game.players;
		this.attrBinding.nameAdd = "";
		this.updateLoop('players');
		this.inputAdd = (<HTMLInputElement>this.dom.querySelector('.add-new input[type=text]'));
	}
	
	
	onUpdateName(evt:Event):void
	{
		this.attrBinding.nameAdd = (<HTMLInputElement>evt.currentTarget).value;
	}
	
	
	clickDelete(index:number):void
	{
		this.attrBinding.players.splice(index, 1);
		this.attrBinding.players = this.attrBinding.players;
		this.updateLoop('players');
		
		this.inputAdd.focus();
	}
	
	clickAdd(nameAdd:string):void
	{
		if(nameAdd.length === 0) return;
		
		
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		let game = userdata.getCurrentGame();
		let highBudget:boolean = game.typeGame === 'A';
		
		let gameService = this.getServiceInstance<GameService>('game');
		let player = createPlayerMock();
		player.name = nameAdd;
		player.pointPerRound = highBudget ? gameService.config.pointPerRoundHigh : gameService.config.pointPerRoundLow;
		
		this.attrBinding.players.push(player);
		this.attrBinding.players = this.attrBinding.players;
		
		this.attrBinding.nameAdd = "";
		this.updateLoop('players');
		
		this.inputAdd.focus();
		
	}
	
	
	clickValidate():void
	{
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		let game = userdata.getCurrentGame();
		game.players = [...this.attrBinding.players];
		
		let gameService = this.getServiceInstance<GameService>('game');
		gameService.initPlayerPoints(game);
		
		
		userdata.saveIfN();
		router.navigate('setup-round');
	}
	
	
	private btnEnabled:boolean;
	isBtnEnabled(players:Player[]):boolean
	{
		let minplayer = getMainConfig().debugMode ? 1 : 5;
		let btnEnabled = players.length >= minplayer;
		
		//if becomes enabled, play a small animation
		if(!this.btnEnabled && btnEnabled){
			new gsap.core.Timeline()
			.fromTo('button.validate', {scale:0.87}, {scale:1, duration:0.23, ease: "back.out(2.0)"})
		}
		
		this.btnEnabled = btnEnabled;
		return btnEnabled;
	}
	
}





let moduleClass:any = CreatePlayersModule;
try{ moduleClass = getDependency<CreatePlayersModule>(require('theme-iso/base/modules/pages/menu/create-players/create-players.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./create-players.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/menu/create-players/create-players.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./create-players.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/menu/create-players/create-players.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/menu/create-players/create-players.config.user').default;} catch(e){}
config = resolveConfig('create-players', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./create-players.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/menu/create-players/create-players.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'create-players', template, config, anims, css)