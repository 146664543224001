import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('.content > h1', {opacity:0, scale: 0.5}, {opacity:1, scale:1, duration:0.35, ease: "back.out(1.5)"})
		.fromTo('.content > .frame', {opacity:0, y:100}, {opacity:1, y:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('.content > .footer > .frame-legend', {opacity:0, marginBottom:-100}, {opacity:1, marginBottom:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('.content > .footer > .btn.orange', {opacity:0, marginBottom:-100}, {opacity:1, marginBottom:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}