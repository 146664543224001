import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency, getModuleDefByID, ModuleDef, ModuleInstance, registerModuleClass } from 'core/lib/core';
import gsap from 'gsap';

registerModuleClass([
	BgmoduleModule
]);



export class BackgroundService extends Service<typeof config>{
	
	protected viewport:HTMLElement;
	protected bgA:ModuleInstance;
	protected bgB:ModuleInstance;
	protected moduleHandler:ModuleHandler;
	protected moduleBGDef:ModuleDef;
	protected displayA:boolean = true;
	protected theme:string;
	public dialogService: DialogService = this.getServiceInstance<DialogService>('dialog');
	
	public onInit():void
	{
		this.moduleHandler = new ModuleHandler();
		this.moduleBGDef = getModuleDefByID('bgmodule');
		this.theme = process.env.THEME;
		
		//validate animations files
		let mainConfig = getMainConfig();
		if(mainConfig.checkRuntimeErrors){
			let errors = animator.getErrors(this.moduleBGDef.anims, ['transition'], ['dom:HTMLElement', 'dom:HTMLElement'], 'bgmodule.animations.ts');
			if(errors.length) throw new Error(animator.formatError(errors));
		}
	}
	
	public reset(viewport:HTMLElement):void
	{
		this.viewport = viewport;
		
		this.bgA = this.moduleHandler.instanciateModule(this.moduleBGDef.class);
		this.viewport.appendChild(this.bgA.dom);
		
		this.bgB = this.moduleHandler.instanciateModule(this.moduleBGDef.class);
		this.viewport.appendChild(this.bgB.dom);
		
	}
	
	public destroy():void
	{
		this.viewport.removeChild(this.bgA.dom);
		this.moduleHandler.destroyModule(this.bgA);
		
		this.viewport.removeChild(this.bgB.dom);
		this.moduleHandler.destroyModule(this.bgB);
		
	}
	
	public setBackground(filename:string, audio:string = null):string
	{
		let bgIn = (this.displayA) ? this.bgA : this.bgB;
		let bgOut = (this.displayA) ? this.bgB : this.bgA;
		
		let instIn = (<BgmoduleModule>bgIn.classInstance);
		instIn.update(this.theme, filename);
		
		//todo : animator
		// (<HTMLElement>bgOut.dom).style.display = 'none';
		// (<HTMLElement>bgIn.dom).style.display = 'block';
		
		
		let func:Function = animator.resolveFunction(this.moduleBGDef.anims, ['transition', filename]);
		
		let timeline = func(bgIn.dom, bgOut.dom);
		animator.addTimeline(timeline);
		
		this.displayA = !this.displayA;
		
		//play a sound with infinite repeat
		if(audio){
			let audioService = this.getServiceInstance<AudioService>('audio');
			let channel = 'background';
			//do not play if channel is currently playing and if sound path is the same
			if(!audioService.isPlaying(channel) || audioService.getCurrentFilename(channel) !== audio){
				audioService.playSound(true, audio, 0, 0, channel);
			}
		}
		
		
		//return state hash
		return 'setBackground';
	}
	
	
	
	
}



let serviceClass:any = BackgroundService;
try{ serviceClass = getDependency<BackgroundService>(require('theme-iso/base/services/background/background.service.user.ts')); }catch(e){}


import * as configSystem from './background.config';
import { BgmoduleModule } from './bgmodule/bgmodule.module';
import { ModuleHandler } from 'core/lib/modules';
import animator from 'core/lib/animator';
import { HomeModule } from 'theme/base/modules/pages/home/home.module';
import { AudioService } from '../audio/audio.service';
import { DialogService } from 'theme/packages/dialog/dialog.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/background/background.config.user').default;} catch(e){}
config = resolveConfig('background', configSystem.default, configUser, process.env.RUNTIME_ENV)


registerServiceDef(serviceClass, 'background', config)
