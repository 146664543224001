import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';
import axios from 'axios';
import JSON5 from 'json5';


export class ApiService extends Service<typeof config>{
	
	
	protected counterSecu:number;
	
	onViewReady(){
		console.log('ApiService.onViewReady');
		
	}
	
	public query(method:string, route:string, data:any = null, encoding:boolean = false):Promise<any>
	{
		this.counterSecu = 0;
		return new Promise((resolve, reject) => {
			this.querySub(method, route, data, encoding, resolve, reject);
		});
	}
	
	
	protected querySub(method:string, route:string, data:any = null, encoding:boolean = false, resolve:(value:unknown)=>void, reject:(reason?:any)=>void):Promise<any>
	{
		//if mock are defined in config, return a mock instead
		let mockData:any = (<any>this.config.mock);
		method = method.toLowerCase();	//normalize method to lowercase
		
		if(
			mockData && 
			(mockData[route] || mockData[`${route}:${method}`])
		){
			
			let mockpath = mockData[`${route}:${method}`] || mockData[route];
			
			let tab = mockpath.split('.');
			let ext = tab[tab.length-1];
			
			axios.get(
				mockpath, {
					transformResponse: (res) => {
						if(ext === 'json') res = JSON.parse(res);
						else if(ext === 'json5') res = JSON5.parse(res);
						resolve(res);
					}
				}
			).then(resp => {
				return resolve(resp);
			});
			return;
		}
		
		
		//encryption
		
		let encrypt = this.getServiceInstance<EncryptService>('encrypt');
		let body:any = {};
		if(data){
			body.data = JSON.stringify(data);
			if(encoding) body.data = encrypt.encode(body.data);
			body.hash = MD5(body.data).toString();
		}
		
		// body = data;	//temp
		
		
		//get token if available
		
		let access_token = null;
		let storageService = this.getServiceInstance<StorageService>('storage');
		if(storageService.isset('jwt')){
			let obj = storageService.getObject('jwt');
			access_token = obj.access_token;	//test
		}
		
		//execute query
		//note : baseUrl assumes a trailing slash here
		//idea : auto-magically add it ? (i'm not a fan of auto-magic stuff)
		
		if(this.counterSecu > 1) resolve(new Error('too many recursion'));
		this.counterSecu++;
		
		const config = {
			headers: { 
				Authorization: `Bearer ${access_token}`
			},
		};
		
		let path = `${this.config.baseUrl}${route}`;
		let output:Promise<any>;
		if(method === 'post') output = axios.post(path, body, config);
		else if(method === 'put') output = axios.put(path, body, config);
		else if(method === 'get') output = axios.get(path, config);
		else if(method === 'delete'){
			axios.delete(path, {
				headers: { Authorization: `Bearer ${access_token}`},
				data: body
			});
		}
		else throw new Error(`wrong method for api.query "${method}" (use get / post / put / delete)`);
		
		output.then(resp => {
			resolve(resp.data);
		})
		.catch(err => {
			if(!err.response || err.response.status === 401){
				this.oauth()
				.then(resp => this.querySub(method, route, data, encoding, resolve, reject))
				.then(resp => {
					resolve(resp.data)
				})
				.catch(err => reject(err));
			}
			else reject(err);
		});
		return output;
	}
	
	
	protected onAuthError():void
	{
		
	}
	
	
	
	protected oauth():Promise<any>
	{
		let config = {
			headers:{
				Accept: '*/*',
				"Content-Type": "multipart/form-data",
				"Accept-Encoding": "gzip, deflate, br",
				Connection: "keep-alive",
			}
		}
		let path = `${this.config.baseUrl}oauth/token`;
		
		var formdata = new FormData();
		formdata.append("grant_type", "client_credentials");
		formdata.append("client_id", process.env.CLIENT_ID);
		formdata.append("client_secret", process.env.CLIENT_SECRET);
		
		return axios.post(path, formdata, config)
		.then(resp => {
			let storageService = this.getServiceInstance<StorageService>('storage');
			storageService.setObject('jwt', resp.data);
			return true;
		})
		.catch(err => {
			return false;
		});
	}
	
	
	
	protected getRand():string
	{
		return ''+Math.round(Math.random() * 9999999);
	}
	
	
	 	
}


interface DataInput{
	data:any,
	hash:string,
}



let serviceClass:any = ApiService;
try{ serviceClass = getDependency<ApiService>(require('theme-iso/base/services/api/api.service.user.ts')); }catch(e){}


import apiConfig, * as configSystem from './api.config';
import { EncryptService } from '../encrypt/encrypt.service';
import { MD5 } from 'crypto-js';
import { StorageService } from '../storage/storage.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/api/api.config.user').default;} catch(e){}
config = resolveConfig('api', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'api', config)
