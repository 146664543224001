export default{
	
	//default config
	default : {
		encrypt : true
	},
	//dev
	dev : {
		encrypt : false
	},
	staging : {
		encrypt : false
	},
}