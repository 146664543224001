import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('h1', {scale:0}, {scale:1, duration:0.35, ease: "back.out(2.0)"})
		.fromTo('.list .btn:nth-child(1)', {scale:0}, {scale:1, duration:0.23, ease: "back.out(1.5)"}, '>-0.12')
		.fromTo('.list .btn:nth-child(2)', {scale:0}, {scale:1, duration:0.23, ease: "back.out(1.5)"}, '>-0.08')
		.fromTo('.list .btn:nth-child(3)', {scale:0}, {scale:1, duration:0.23, ease: "back.out(1.5)"}, '>-0.08')
		.fromTo('.list div[import="select-language"]', {scale:0}, {scale:1, duration:0.23, ease: "back.out(1.5)"}, '>-0.08')
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}