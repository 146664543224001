/**
 * Dowimoteur
 * 
 * Override service "preload"
 * Created on Mar 4, 2024
 * 
 */

import { PreloadService } from "theme/base/services/preload/preload.service";

export default class PreloadServiceSub extends PreloadService{
	
	override onViewReady(): void {
		console.log('PreloadServiceSub.onViewReady');
	}
}
