/**
 * Dowimoteur
 * 
 * Base service "game"
 * Created on Apr 5, 2024
 * 
 */

import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';


export class GameService extends Service<typeof config>{
	
	private game:Game;
	private userdata:UserDataService;
	private cards:{[id:string]:CardModel};
	private pedagogicalContents:PedagogicalContent[];
	private collectiveTurns:number[];
	public firstCards:{[idfirst:string]:boolean};
	
	
	public onInit(): void 
	{
		this.userdata = this.getServiceInstance<UserDataService>('userdata');
	}
	
	
	public initData(cards:{[id:string]:CardModel}, pedagogicalContents:PedagogicalContent[], params:any):void
	{
		this.pedagogicalContents = pedagogicalContents;
		for(let id in this.pedagogicalContents){
			this.pedagogicalContents[id].id = id;
		}
		
		this.collectiveTurns = [];
		
		this.config.pointsStartHigh = params.pointsStartHigh;
		this.config.pointsStartLow = params.pointsStartLow;
		this.config.pointPerRoundHigh = params.pointPerRoundHigh;
		this.config.pointPerRoundLow = params.pointPerRoundLow;
		this.config.nbRound = params.nbRound;
		this.config.timeAlert = params.timeAlert;
		this.config.scenarioFactors = params.scenarioFactors;
		this.config.ctFactorReward1 = params.ctFactorReward1;
		this.config.ctFactorReward2 = params.ctFactorReward2;
		this.config.ctFactorCost = params.ctFactorCost;
		
		
		//pre process js expressions (replace $ by this.)
		for(let i in cards){
			let card:CardModel = cards[i];
			card.availabilityConditions = this.transformExpression(card.availabilityConditions);
			for(let j in card.roundConsequences) card.roundConsequences[j] = this.transformExpression(card.roundConsequences[j]);
			for(let j in card.eachRoundConsequences) card.eachRoundConsequences[j] = this.transformExpression(card.eachRoundConsequences[j]);
			
			let match = card.availabilityConditions.match(/this\.(CT\d)/);
			if(match) card.ctDependency = match[1];
			
			if(i.substr(0, 2) === 'CT') card.collective = true;
			
			card.id = this.toDashCase(card.id);
			if(card.cost === null) card.cost = '0';
			if(card.collective && this.collectiveTurns.indexOf(card.faceoffRound) === -1){
				this.collectiveTurns.push(card.faceoffRound);
			}
		}
		this.cards = cards;
		
		for(let i in pedagogicalContents){
			let p = pedagogicalContents[i];
			p.displayConditions = this.transformExpression(p.displayConditions);
		}
	}
	
	
	private toDashCase(str:string):string
	{
		//only keezp lowercase, digit and dash
		str = str. replace(/[^a-zA-Z0-9]/g, '-');
		
		//remove double dash, prefix dash, suffix dash
		str = str.replace('--', '-');
		if(str.charAt(str.length - 1) === '-') str = str.substr(0, str.length - 1);
		if(str.charAt(0) === '-') str = str.substr(1);
		
		return str;
	}
	
	
	/**
	 * return all the new cards for a given round
	 */
	public getCardsRound(roundIndex:number):CardModel[]
	{
		let output:CardModel[] = [];
		
		
		//use random player cardPlayed because its only used for CT (which are duplicated for each players)
		let cardsPlayed = this.game.players[0].cardsPlayed;
		
		let isCollective = this.isCollectiveRound(roundIndex);
		let vars = this.generateVarsRead(cardsPlayed, {}, roundIndex, this.game);
		
		
		for(let id in this.cards){
			let card = this.cards[id];
			if(card.faceoffRound === roundIndex){
				if(!isCollective){
					
					//if a card depends on a CT choice, only display it if the CT was picked
					if(card.ctDependency){
						if(cardsPlayed[card.ctDependency]) output.push(card);
					}
					else output.push(card);
				}
				else if(card.collective) output.push(card);
				
			}
		}
		return output;
	}
	
	
	
	/**
	 * return all cards and process their state based on
	 * - index round
	 * - player variables
	 */
	public getCardsState(roundIndex:number, cardsPlayed:{[id:string]:number}, cardsPlayedRound:{[idcard:string]:boolean}, clone:boolean = false):CardModel[]
	{
		//to avoid wasting memory,
		//do not clone the cards model, we can just keep writing them without any conflicts
		
		let vars = this.generateVarsRead(cardsPlayed, cardsPlayedRound, roundIndex, this.game);
		
		let output = [];
		let count:number = 0;
		for(let i in this.cards){
			let card = this.cards[i];
			
			if(!card.collective){
			
				if(clone) card = {...card};
				if(roundIndex < card.faceoffRound) card.state = 'unavailable';
				else if(!this.processExpression(card.availabilityConditions, vars)){
					if(card.ctDependency && !cardsPlayed[card.ctDependency]) card.state = 'unavailable';
					else card.state = 'locked';
				}
				else if(cardsPlayed[card.id] > 0 && !card.multipleSelection) card.state = 'played_prevround';
				else card.state = 'available';
				
				card.nbplayed = cardsPlayed[card.id] ?? 0;
				if(cardsPlayedRound[card.id]) card.nbplayed++;
				card.index = count;
				output.push(card);
				count++;
			}
		}
		return output;
	}
	
	public getCardById(id:string):CardModel
	{
		return this.cards[id];
	}
	
	public getCards():{[id:string]:CardModel}
	{
		return this.cards;
	}
	
	
	
	public getCardsCollective(roundIndex:number):CardModel[]
	{
		let output = [];
		for(let i in this.cards){
			let card = this.cards[i];
			if(card.collective && roundIndex === card.faceoffRound) output.push(card);
			card.state = 'available';
		}
		return output;
	}
	
	
	
	
	public getDiffCards(prevCards:CardModel[], newCards:CardModel[]):{[id:string]:CardModel}
	{
		let output:{[id:string]:CardModel} = {};
		for(let i = 0; i<prevCards.length; i++){
			if(prevCards[i].state !== newCards[i].state){
				newCards[i].prevState = prevCards[i].state;
				output[newCards[i].id] = newCards[i];
			}
		}
		return output;
	}
	
	public isCollectiveRound(roundIndex:number):boolean
	{
		return this.collectiveTurns.indexOf(roundIndex) > -1;
	}
	
	
	public initGame():Game
	{
		this.game = this.userdata.getCurrentGame();
		return this.game;
	}
	
	
	
	
	
	
	public finishTurn(cardsPlayedRound:{[idcard:string]:boolean}[], collective:boolean):void
	{
		if(!this.game) this.initGame();
		
		
		for(let i = 0; i<this.game.players.length; i++){
			let player = this.game.players[i];
			let vars = this.generateVarsWrite(player, this.game);
			
			let indexPlayer = collective ? 0 : i;
			
			for(let idcard in cardsPlayedRound[indexPlayer]){
				let played = cardsPlayedRound[indexPlayer][idcard];
				if(played){
					
					//execute round consequence (current round selection)
					let card = this.cards[idcard];
					for(let j in card.roundConsequences){
						this.processExpression(card.roundConsequences[j], vars);
					}
					
					//save played cards
					if(player.cardsPlayed[idcard] === undefined) player.cardsPlayed[idcard] = 1;
					else player.cardsPlayed[idcard]++;
				}
			}
			
			
			//execute each round consequences (current + past rounds)
			if(!collective){
				for(let idcard in player.cardsPlayed){
					
					let card = this.cards[idcard];
					let nbplayed = player.cardsPlayed[idcard];		//nbplayed shouldn't be undefined or 0 here (to check)
					for(let r = 0; r < nbplayed; r++){	//repeat as many time as card was played
						for(let j in card.eachRoundConsequences){
							this.processExpression(card.eachRoundConsequences[j], vars);
						}
					}
				}
			}
			
			//re-synchronize generated vars (where expression was writing) and userdata
			this.syncVars2player(vars, player, this.game);
			
			
			//delete vars (it's a calculated data, it shouldn't be saved in local storage with userdata)
			player.played = false;
			
			//save into history (for results page)
			if(player.history === undefined){
				player.history = [];	//fix for dev
			}
			player.history[this.game.roundIndex] = Object.values(player.metrics);
			
		}
		
		this.game.roundIndex++;
		
		//save
		this.userdata.saveIfN();
		
	}
	
	
	//add points to each players
	public addPointsPlayers():void
	{
		for(let player of this.game.players){
			player.points += player.pointPerRound;
		}
	}
	
	
	public initPlayerPoints(game:Game):void
	{
		let highBudget:boolean = game.typeGame === 'A';
		for(let player of game.players){
			player.points = highBudget ? this.config.pointsStartHigh : this.config.pointsStartLow;
		}		
	}
	
	//
	
	public processExpression(conditions:string, vars:any):boolean
	{
		let result = false;
		try{
			//this is equivalent to an eval
			result = Function(`"use strict"; return ${conditions}`).bind(vars)();
		}
		catch(error){
			let error2 = new Error(`error processing expression "${conditions}"`);
			error2.stack = error.stack;
			throw error;
		}
		return result;
	}
	
	
	
	/**
	 * generate variables object for js expressions that read variable (conditions))
	 * based on user state (userdata)
	 */
	private generateVarsRead(cardsPlayed:{[id:string]:number}, cardsPlayedRound:{[idcard:string]:boolean}, roundIndex:number, game:Game):any
	{
		//turn cards into boolean
		let output:any = {};
		if(cardsPlayed){
			for(let id in cardsPlayed){
				if(cardsPlayed[id] > 0) output[id] = true;
			}
		}
		if(cardsPlayedRound){
			for(let id in cardsPlayedRound){
				if(cardsPlayedRound[id] === true) output[id] = true;
			}
		}
		output.roundIndex = roundIndex;
		
		output.ctFactorReward1 = game.ctFactorReward1;
		output.ctFactorReward2 = game.ctFactorReward2;
		output.ctFactorCost = game.ctFactorCost;
		
		output = {...output, ...game.scenarioFactors};
		
		//add max points
		output.maxPoints = 0;
		for(let k in game.players[0].metrics) output['maxPoints_' + k] = 0;
		for(const player of game.players){
			let total = 0;
			for(let k in player.metrics){
				let v = (<any>player.metrics)[k];
				total += v;
				if(v > output['maxPoints_' + k]) output['maxPoints_' + k] = v;
			}
			if(total > output.maxPoints) output.maxPoints = total;
		}
		
		output.scenario = game.typeGame === 'A' ? 0 : 1;
		
		return output;
	}
	
	
	/**
	 * generate variable object for js expressions that write in object (like roundConsequence)
	 * we separate 2 functions to save spaces and avoid writing useless data
	 */
	private generateVarsWrite(player:Player, game:Game):any
	{
		let output:any = {
			...player.metrics, 
			points: player.points,
			pointPerRound: player.pointPerRound,
			
			ctFactorReward1: game.ctFactorReward1,
			ctFactorReward2: game.ctFactorReward2,
			ctFactorCost: game.ctFactorCost,
			...game.scenarioFactors,
		};
		return output;
	}
	
	
	/**
	 * after writing in vars, we need to synchronize it with player 
	 */
	private syncVars2player(vars:any, player:Player, game:Game):void
	{
		for(let k in player.metrics) (<any>player.metrics)[k] = vars[k];
		player.points = vars.points;
		player.pointPerRound = vars.pointPerRound;
			
		game.ctFactorCost = vars.ctFactorCost;
		game.ctFactorReward1 = vars.ctFactorReward1;
		game.ctFactorReward2 = vars.ctFactorReward2;
		for(let k in game.scenarioFactors){
			(<any>game.scenarioFactors)[k] = vars[k];
		}
	}
	
	
	
	private transformExpression(expression:string):string
	{
		if(!expression) return expression;
		expression = expression.replace(/\$/g, 'this.');
		expression = expression.replace(/TRUE/g, 'true');
		expression = expression.replace(/FALSE/g, 'false');
		return expression;
	}
	
	
	public getPedagogicContent(pedagogicalFlags:{[id:string]:boolean}, roundIndex:number, position:'beforeScore'|'afterScore', firstCards:{[idfirst:string]:boolean}):PedagogicalContent[]
	{
		let output:PedagogicalContent[] = [];
		
		let vars = this.generateVarsRead(null, null, roundIndex, this.game);
		for(let i in firstCards) vars[i] = firstCards[i];
		
		for(let k in this.pedagogicalContents){
			let p = this.pedagogicalContents[k];
			if(!pedagogicalFlags[k] && position === p.position && this.processExpression(p.displayConditions, vars)){
				output.push(p);
			}
		}
		
		return output;	
	}
	
	
	
	/**
	 * this must be executed before finishTurn, when card current turn are not registered yet
	 */
	public getFirstCards(playedCardsRound:{[idcard:string]:boolean}[]):{[idfirst:string]:boolean}
	{
		//get cards played at a global state
		
		let cardsPlayedPreviousRound:{[id:string]:number} = {};
		
		for(let i = 0; i<this.game.players.length; i++){
			for(let k in this.game.players[i].cardsPlayed){
				if(this.game.players[i].cardsPlayed[k]) cardsPlayedPreviousRound[k] = 1;
			}
		}
		
		//add var firstCXX
		//for each players, if one of them played a card and this card wasn't already played by someone previously : add as first
		let firstCards:{[idfirst:string]:boolean} = {};
		for(let indPlayer in playedCardsRound){
			for(let idcard in playedCardsRound[indPlayer]){
				if(playedCardsRound[indPlayer][idcard] && !cardsPlayedPreviousRound[idcard]){
					firstCards['first' + idcard] = true;
				}
			}
		}
		return firstCards;
	}
	
	
	
}



let serviceClass:any = GameService;
try{ serviceClass = getDependency<GameService>(require('theme-iso/base/services/game/game.service.user.ts')); }catch(e){}


import * as configSystem from './game.config';
import { Game, Player, UserDataService } from 'theme/base/services/userdata/userdata.service';
import router from 'core/lib/router';
import { CardModel, PedagogicalContent } from 'theme-iso/base/interface/interfaces';
import { CardChoiceModule } from 'theme-iso/base/modules/pages/card-choice/card-choice.module';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/game/game.config.user').default;} catch(e){}
config = resolveConfig('game', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'game', config)
