/**
 * Dowimoteur
 * 
 * Override module "landing"
 * Created on Mar 15, 2024
 * 
 */
import gsap from 'gsap';
import broadcaster from 'core/lib/broadcaster';
import { getMainConfig } from 'core/lib/config';
import { Evt } from 'theme/base/config/events';
import { LandingModule } from 'theme/base/modules/pages/landing/landing.module';
import router from 'core/lib/router';
let mainConfig = getMainConfig();

export default class LandingSub extends LandingModule{
	
	override onInit(): void
	{
	}
	
	override onViewReady(): void {
		
		let timeline:GSAPTimeline = new gsap.core.Timeline();
		let factor = getMainConfig().debugMode ? 0.1 : 1.0;
		
		setTimeout(() => {
			timeline
			.fromTo('.container-iso img', {opacity:0, y:100}, {opacity:1, y:0, time:1.0 * factor, delay:0.5 * factor})
			.fromTo('.container-iso p', {opacity:0, y:100}, {opacity:1, y:0, time:1.0 * factor, delay:-0.15})
			.to('.container-iso p', {opacity:0, y:100, time:0.2 * factor, delay:1.7 * factor})
			.to('.container-iso img', {opacity:0, y:100, time:0.2 * factor, delay:-0.21})
			
			.fromTo('.container-dowino p', {opacity:0, y:100}, {opacity:1, y:0, time:1.0 * factor, delay:0.5 * factor})
			.fromTo('.container-dowino img', {opacity:0, y:100}, {opacity:1, y:0, time:1.0 * factor, delay:-0.15})
			.to('.container-dowino img', {opacity:0, y:100, time:0.2 * factor, delay:1.7 * factor})
			.to('.container-dowino p', {opacity:0, y:100, time:0.2 * factor, delay:-0.21})
			
			.fromTo('.container-select-lang img', {opacity:0, y:100}, {opacity:1, y:0, time:1.0 * factor, delay:0.7 * factor})
			.fromTo('.container-select-lang .lang', {opacity:0, y:100}, {opacity:1, y:0, time:1.0 * factor, delay:-0.15})
			;
		}, 500 * factor);
	}
	
	
	clickOK():void
	{
		router.navigate('home');
	}
}