import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('.modal', {scale:0}, {scale:1, duration:0.4, ease: "back.out(1.5)"})
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}