import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './debug.config';
import { DebugService } from 'theme/base/services/debug/debug.service';

export class DebugModule extends Module<typeof config>{
	
	private debugService:DebugService;
	
	/**
	 * initialize router sub navigation of debug module
	 * go to default section
	 */
	
	override onInit(): void {
		
		this.debugService = this.getServiceInstance<DebugService>('debug');
		let sectionDefault = this.debugService.section || 'userdata-selector';
		this.attrBinding.content = sectionDefault;
		router.addOutlet({selector: '.debug .content', outlet: 'debug-content', dom: this.dom});
		setTimeout(() => {
			router.navigate(sectionDefault, 'debug-content');		//to re-enable
		}, 50);
		
		let elmt:HTMLElement = this.dom.querySelector('.container');
		let handleDrag:HTMLElement = this.dom.querySelector('.drag-top');
		this.dragElement(elmt, handleDrag);
	}
	
	navigate(route:string):void
	{
		this.attrBinding.content = route;
		router.navigate(route, 'debug-content');
		this.debugService.section = route;
	}
	
	isCurrentPage(page:string, key:string):boolean
	{
		return page === key;
	}
	
	
	
	
	//drag n drop
	private dragElement(elmnt:HTMLElement, handleDrag:HTMLElement) {
		var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
		var offsetX = 0, offsetY = 0;
		handleDrag.onmousedown = dragMouseDown;
	
		function dragMouseDown(e:MouseEvent) {
			e = e || (<MouseEvent>window.event);
			e.preventDefault();
					// get the mouse cursor position at startup:
			pos3 = e.pageX;
			pos4 = e.pageY;
			offsetX = elmnt.offsetLeft;
			offsetY = elmnt.offsetTop;
			console.log('____'+pos3+'/'+pos4);
			console.log('____'+offsetX+'/'+offsetY);
			document.onmouseup = closeDragElement;
			// call a function whenever the cursor moves:
			document.onmousemove = elementDrag;
		}
	
		function elementDrag(e:MouseEvent) {
			e = e || (<MouseEvent>window.event);
			e.preventDefault();
			// calculate the new cursor position:
			pos1 = e.pageX - pos3;
			pos2 = e.pageY - pos4;
			// console.log('pos '+pos1+'/'+pos2);
			
			// set the element's new position:
			let y = (pos2 + offsetY);
			if(y < -68) y = -68;
			elmnt.style.left = (pos1 + offsetX) + "px";
			elmnt.style.top = y + "px";
		}
	
		function closeDragElement() {
			// stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}
	
	
	
	
}



let moduleClass:any = DebugModule;
try{ moduleClass = getDependency<DebugModule>(require('theme-iso/base/modules/debug/debug.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./debug.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/debug/debug.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./debug.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/debug/debug.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/debug/debug.config.user').default;} catch(e){}
config = resolveConfig('debug', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./debug.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/debug/debug.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'debug', template, config, anims, css)