export default {
	//default config
	default : {
		//passphrase for encryption, the key is obfuscated 
		//in a way to look like everything else in the obfuscated code
		x0dx0ax20x20x20x20: process.env.DOWINO_LOG_KEY
	},
	//dev
	dev : {
		
	},
}