/**
 * Dowimoteur
 * 
 * Override module "home"
 * Created on Mar 14, 2024
 * 
 */

import broadcaster from 'core/lib/broadcaster';
import { getMainConfig } from 'core/lib/config';
import router from 'core/lib/router';
import { Evt } from 'theme/base/config/events';
import { HomeModule } from 'theme/base/modules/pages/home/home.module';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { dataGlobalHelp } from '../help/help.global';
import { TranslationService } from 'theme/base/services/translation/translation.service';
let mainConfig = getMainConfig();

export default class HomeSub extends HomeModule{
	
	override onInit(): void
	{
		let userData = this.getServiceInstance<UserDataService>('userdata');
		let data = userData.getCurrentData();
		data.curGameIndex = -1;
		this.attrBinding.hasGames = data.games && data.games.length > 0;
	}
	
	clickNewGame():void
	{
		dataGlobalHelp.type = 'WELCOME';
		router.navigate('help', 'modal');
	}
	clickContinue():void
	{
		router.navigate('select-game');
	}
	clickCGU():void
	{
		// router.navigate('terms');
		let translateService = this.getServiceInstance<TranslationService>('translation');
		let url = translateService.translate('ui.download.legal');
		//	/data/pdf/iso_legal_FR.pdf
		window.open(url);
	}
}