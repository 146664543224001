import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';
import { StorageService } from '../storage/storage.service';




export class UserDataService extends Service<typeof config>{
	
	//store multiple UserData in parallele
	protected currentData:UserData;
	protected currentKey:string;
	public data:{[key:string]:UserData};
	
	protected dataPrev0:UserData;
	protected dataPrev1:UserData;
	protected dataPrev2:UserData;
	
	protected encryptPassphrase:string = "kdlsKZklsWKL5O$2tD*Qk2f$";
	protected storageService:StorageService;
	protected encryptService:EncryptService;
	protected enablePreviousSave:boolean;
	
	
	/**
	 * load cookie data, or initialize them into this.data
	 */
	public init():void
	{
		this.storageService = this.getServiceInstance<StorageService>('storage');
		this.encryptService = this.getServiceInstance<EncryptService>('encrypt');
		let keys = this.storageService.getKeys();
		this.data = {};
		this.enablePreviousSave = getMainConfig().enableDebugModule;
		
		//parse and decrypt all the json userdata
		for(let i in keys){
			let key = keys[i];
			//filter non json keys
			if(key !== 'currentKey'){
				this.data[key] = this.getLocalData(key);
			}
		}
		
		//if main save doesnt exist, create it
		if(!this.data.n){
			this.data.n = createMock();
			this.data.n.saveTitle = 'main save (n)';
			this.save('n', this.data.n);
		}
		
		//currentKey
		let currentKey = this.storageService.getString('currentKey');
		if(!currentKey) currentKey = 'n';
		this.updateCurrent(currentKey);
		
	}
	
	
	protected getLocalData(key:string):any
	{
		//init data
		let strdata = this.storageService.getString(key);
				
		//try to parse data, if it doesn't work
		//it means data is encrypted and need to be decrpted first
		let obj;
		try{ obj = JSON.parse(strdata);}
		catch(e){
			strdata = this.encryptService.decode(strdata);
			obj = JSON.parse(strdata);
		}
		if(!obj) throw new Error("Error with cookie encoding");
		return obj;
	}
	
	
	/**
	 * getter, must be used for reading only
	 * although it's possible, do not use this for writing in currentData
	 * use updateData instead
	 */
	public getCurrentData(n:number = 0):UserData
	{
		if(n === 0) return this.currentData;
		else if(n === -1) return this.dataPrev1;
		else if(n === -2) return this.dataPrev2;
		else throw new Error(`getCurrentData : value n ${n} not allowed`);
	}
	public getCurrentKey():string
	{
		return this.currentKey;
	}
	
	
	/**
	 * only write data if current is n 
	 * otherwise => read only
	 */
	public updateData(key:string, value:any):void
	{
		(<any>this.currentData)[key] = value;
	}
	
	
	
	public get(id:string):UserData
	{
		return this.data[id];
	}
	
	public getListUserData():{[name:string]:UserData}
	{
		let keys = this.storageService.getKeys();
		let output:{[name:string]:UserData} = {};
		for(let i in keys){
			let key = keys[i];
			if(key === 'n' || key.substr(0, 5) === 'save-'){
				output[key] = this.data[key];
			}
		}
		return output;
	}
	
	
	/**
	 * used when switching to another save from the debug module
	 */
	public updateCurrent(key:string):void
	{
		if(!this.data[key]) throw new Error(`userdata with key "${key}" doesnt exist`);
		this.currentData = this.data[key];
		this.currentKey = key;
		this.storageService.setString('currentKey', key);
		
		if(this.enablePreviousSave){
			//reset save chain
			this.dataPrev2 = null;
			this.dataPrev1 = null;
			this.dataPrev0 = _.cloneDeep(this.currentData);
		}
	}
	
	
	
	public reset():void
	{
		if(this.currentKey !== 'n') return;
		
		//delete all the personal save
		//todo : loop ?
		delete this.data.n;
		delete this.data.checkpoint;
		
		//save the uuid and recreate a n save
    let uuid = this.data.n.uuid;
		this.data = { n: createMock() };
    this.data.n.uuid = uuid;
		this.updateCurrent('n');
  }
	
	
	public resetGame():void
	{
		this.resetSequence();
		this.currentData.indexSequence = null;
	}
	public resetSequence():void
	{
		this.currentData.flowchartNodeId = null;
		this.currentData.flowchartStateMaxIndex = 0;
		this.currentData.flowchartState =  {};
		this.currentData.flowchartVariables = {};
	}
	
	public createMock():void
	{
		let data = this.getCurrentData();
		data.curGameIndex = 0;
		if(data.games.length === 0){
			let game = this.addGame();
			game.players.push(createPlayerMock());
			game.players.push(createPlayerMock());
			game.players.push(createPlayerMock());
			game.players.push(createPlayerMock());
			game.players.push(createPlayerMock());
			game.players.push(createPlayerMock());
		}
		else{
			let game = data.games[0];
			if(game.players.length === 0){
				game.players.push(createPlayerMock());
				game.players.push(createPlayerMock());
				game.players.push(createPlayerMock());
				game.players.push(createPlayerMock());
				game.players.push(createPlayerMock());
				game.players.push(createPlayerMock());
			}
		}
	}
	
	
	/**
	 * only accepted if main save (currentKey === 'n')
	 */
	public saveProgress():void
	{
		if(this.enablePreviousSave){
			//chain of precedent clones (for debug module)
			if(this.dataPrev1) this.dataPrev2 = _.cloneDeep(this.dataPrev1);
			if(this.dataPrev0) this.dataPrev1 = _.cloneDeep(this.dataPrev0);
			this.dataPrev0 = _.cloneDeep(this.currentData);
		}
		
		//if not read-only
		if(this.currentKey === 'n'){
			this.save('n', this.currentData);
		}
	}
	
	
	
	/**
	 * called when switching to a local data,
	 * to make sure the session progress is reset
	 */
	public reloadCurrentData(key:string):void
	{
		this.data[key] = this.getLocalData(key);
	}
	
	
	
	
	/**
	 * for saving from userdata debug module
	 */
	public saveInstant(key:string, title:string, desc:string, saveType:string, data:UserData):UserData
	{
		data = _.cloneDeep(data);		
		data.saveTitle = title; data.saveDesc = desc;
		data.saveTimestamp = new Date().getTime();
		data.saveType = saveType;
		this.save(key, data);
		return data;
	}
	
	
	public saveIfN():void
	{
		if(this.currentKey === 'n'){
			this.save('n', this.currentData);
		}
	}
	
	public save(key:string, data:UserData):void
	{
		this.data[key] = data;
		var str:string = JSON.stringify(data);
		if(this.config.encrypt){
			str = this.encryptService.encode(str);
		}
    this.storageService.setString(key, str);
    // console.log(str);
	}
	
	public delete(key:string):void
	{
		this.storageService.delete(key);
	}
	
	
	public saveFlowchartState(stateHash:string, command:Command):void
	{
		this.currentData.flowchartState[stateHash] = {
			index: this.currentData.flowchartStateMaxIndex,
			command
		};
		this.currentData.flowchartStateMaxIndex++;
	}
	public deleteFlowchartState(stateHash:string):void
	{
		delete this.currentData.flowchartState[stateHash];
	}
	
	
	
	
	public addGame():Game
	{
		let data = this.getCurrentData();
		let game = createGameMock();
		data.games.push(game);
		data.curGameIndex = data.games.length - 1;
		return game;
	}
	
	public getCurrentGame():Game
	{
		let data = this.getCurrentData();
		if(!data.games || data.games.length === 0) return null;
		else if(data.curGameIndex === -1) return null;
		else return data.games[data.curGameIndex];
		
	}
	
	
}


//former userdata interface
export interface UserData{
	
	//debug module
	saveType:string;	//instant/previous
	saveTitle?:string;
	saveDesc?:string;
	saveTimestamp?:number;
	
	locale:string,
	flowchartNodeId:string,
	flowchartVariables:any,
	flowchartState:{
		[name:string]:{command:Command, index:number},
	},
	flowchartStateMaxIndex:number;

	indexSequence:number;
	completedSequences: number[],
	routePath:string;

	uuid:string,
	games:Game[],
	curGameIndex:number,
	
};


export interface Game{
	name:string,
	datetime:number,
	currentTime:number,
	typeGame:string,
	players:Player[],
	//maybe we don't need to store anything about card usage
	//everything should be available in var players
	
	scenarioFactors?:ScenarioFactors,
	ctFactorReward1?:number,
	ctFactorReward2?:number,
	ctFactorCost?:number,
	
	roundIndex:number,
	pedagogicalFlags:{[id:string]:boolean}
}
export interface Player{
	name:string,
	history:number[][],
	scores?:number[],
	scenarioFactors?:ScenarioFactors,
	metrics:{
		climateChange:number,
		economyTrade:number,
		governanceCorportate:number,
		innovation:number,
		naturalEnergyRessources:number,
		qualityOfLife:number,
		socialEquity:number,
	},
	cardsPlayed:{[id:string]:number},
	points:number,
	pointPerRound:number,
	played?:boolean,
	//for histogram (star)
	indexMax?:number,	valueMax?:number,
	x?:number, y?:number, sum?:number, rank?:number,
	playerIndex?:number,
	positioned?:boolean,
}

export interface ScenarioFactors{
	innovationFactor: number,
	economyTradeFactor: number,
	naturalEnergyRessourcesFactor: number,
	climateChangeFactor: number,
	qualityOfLifeFactor: number,
	governanceCorportateFactor: number,
	socialEquityFactor: number
}
//ceginqs

export interface LogItem{
	talker:string,
	msg:string,
	isDidascalie?:boolean,
}


export function createMock(data:any = null):UserData{
	let output:UserData = {
		saveTitle:null, 
		saveDesc:null,
		saveType:null,
		
		locale: getMainConfig().defaultLang,
		uuid:null,
		
		flowchartNodeId:null,
		flowchartVariables:{},
		flowchartState:{},
		flowchartStateMaxIndex:0,
		
    indexSequence: 0,
    completedSequences: [],
		routePath:null,
		
		games:[],
		curGameIndex:-1,

	};
	output = {...output, ...data};
	return output;
};


export function createGameMock():Game
{
	return {
		name: '',
		typeGame: null,
		datetime: new Date().getTime(),
		currentTime: 0,
		players: [],
		roundIndex: 0,
		scenarioFactors: null,
		pedagogicalFlags: {}
	};
}
export function createPlayerMock():Player
{
	return {
		name: '',
		scores:[],
		history:[],
		metrics: { 
			climateChange: 0,
			economyTrade: 0,
			governanceCorportate: 0,
			innovation: 0,
			naturalEnergyRessources: 0,
			qualityOfLife: 0,
			socialEquity: 0,
		},
		cardsPlayed: {},
		points:0,
		pointPerRound: 0
	};
}

let serviceClass:any = UserDataService;
try{ serviceClass = getDependency<UserDataService>(require('theme-iso/base/services/userdata/userdata.service.user.ts')); }catch(e){}


import * as configSystem from './userdata.config';
import _ from 'lodash';
import { Command } from 'theme/base/services/flowchart/flowchart.service';
import { EncryptService } from '../encrypt/encrypt.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/userdata/userdata.config.user').default;} catch(e){}
config = resolveConfig('userdata', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'userdata', config)
