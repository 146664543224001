/**
 * Dowimoteur
 * 
 * Base service "media"
 * Created on Feb 1, 2024
 * 
 */

import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency, getModuleDefByID, ModuleDef, ModuleInstance, registerModuleClass } from 'core/lib/core';

registerModuleClass([
	MediaModule
]);

export class MediaService extends Service<typeof config>{
	
	protected translationService: TranslationService;
	protected mainConfig = getMainConfig();
	protected moduleHandler:ModuleHandler;
	protected viewportMedia:HTMLElement;
	protected moduleMedia:ModuleInstance;
	protected mediaInst:MediaModule;
	protected moduleMediaDef:ModuleDef;

	override onInit(): void
	{
		this.translationService = this.getServiceInstance<TranslationService>('translation');
		this.viewportMedia = <HTMLElement>document.querySelector('.media-viewport');
		this.moduleHandler = new ModuleHandler();
	}

	public reset(viewportMedia:HTMLElement):void
	{
		this.viewportMedia = viewportMedia;

		this.moduleMediaDef = getModuleDefByID('media');
		this.moduleMedia = this.moduleHandler.instanciateModule(this.moduleMediaDef.class);
		this.viewportMedia.appendChild(this.moduleMedia.dom);
		this.mediaInst = (<MediaModule>this.moduleMedia.classInstance);
		
	}

	public setMedia(container: string, media_wording: string, media_title:string, transcript_wording:string, transcript_text:string, text_wording:string, media_text:string){

		let translatedMedia = this.translationService.translate(media_wording);
		let path = (process.env.THEME !== "0" && process.env.THEME !== "x" ? `theme-${process.env.THEME}` : 'theme') + '/base/assets/flowchart/medias/';

		if(!translatedMedia || (this.mainConfig.checkRuntimeErrors && translatedMedia === media_wording)) {
			translatedMedia = media_wording;
		} else {
			translatedMedia = path+media_title;
		}
		if(!container) container = this.config.defaultContainer;

		let translatedTranscript = this.translationService.translate(transcript_wording);
		if(!translatedTranscript || (this.mainConfig.checkRuntimeErrors && translatedTranscript === transcript_wording)) translatedTranscript = '_' + transcript_text;

		let translatedText = this.translationService.translate(text_wording);
		if(!translatedText || (this.mainConfig.checkRuntimeErrors && translatedText === text_wording)) translatedText = '_' + media_text;

		//animation
		let func = this.moduleMediaDef.anims['transition_in'];
		let t:GSAPTimeline = func(this.mediaInst.dom);
		t.eventCallback('onStart', () => {
			this.mediaInst.update(translatedText, container, translatedTranscript, translatedMedia);
		})
		animator.addTimeline(t);
		
		
		this.viewportMedia.style.pointerEvents = 'all';
		
		return 'media';
		
	}

	public hide():string
	{
		//animation
		let func = this.moduleMediaDef.anims['transition_out'];
		let t = func(this.mediaInst.dom);
		animator.addTimeline(t);

		this.viewportMedia.style.pointerEvents = 'none';
		
		return 'media';
	}

	public destroy(){
		this.viewportMedia.removeChild(this.moduleMedia.dom);
		this.moduleHandler.destroyModule(this.moduleMedia);
	}
}



let serviceClass:any = MediaService;
try{ serviceClass = getDependency<MediaService>(require('theme-iso/base/services/media/media.service.user.ts')); }catch(e){}


import * as configSystem from './media.config';
import animator from 'core/lib/animator';
import { ModuleHandler } from 'core/lib/modules';
import { TranslationService } from '../translation/translation.service';
import { MediaModule } from './media.module';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/media/media.config.user').default;} catch(e){}
config = resolveConfig('media', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'media', config)
