import { getDependency, Module, registerModuleClass, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './settings.config';
import { StorageService } from 'theme/base/services/storage/storage.service';
import { FullscreenService } from 'theme/base/services/utils/fullscreen/fullscreen.service';
import { AudioService } from 'theme/base/services/audio/audio.service';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';



export class SettingsModule extends Module<typeof config>{
	
	protected fs:boolean;
	protected sound:boolean;
	protected fullscreen:FullscreenService;
	protected audioService:AudioService;
	
	override onInit(): void {
		
		let storage = this.getServiceInstance<StorageService>('storage');
		this.audioService = this.getServiceInstance<AudioService>('storage');
		this.sound = storage.getBoolean('sound', true);
		this.attrBinding.keyBtnSound = !this.sound ? 'ui.menu.sounds.inactive' : 'ui.menu.sounds.active';
		
		this.fullscreen = this.getServiceInstance<FullscreenService>('fullscreen');
		this.fs = this.fullscreen.isFullscreen();
		this.attrBinding.keyBtnFS = !this.fs ? 'ui.menu.fullscreen.inactive' : 'ui.menu.fullscreen.active';
		
	}
	
	public clickClose():void
	{
		router.navigate(null, 'modal');
	}
	
	
	
	public clickLink(id:string):void
	{
		if(id === 'sound'){
			
			this.sound = !this.sound;
			let storage = this.getServiceInstance<StorageService>('storage');
			storage.setBoolean('sound', this.sound);
			this.attrBinding.keyBtnSound = !this.sound ? 'ui.menu.sounds.inactive' : 'ui.menu.sounds.active';
			
			if(this.sound) this.audioService.enable(true);
			else this.audioService.disable();
		}
		else if(id === 'fs'){
			this.fs = !this.fs;
			this.attrBinding.keyBtnFS = !this.fs ? 'ui.menu.fullscreen.inactive' : 'ui.menu.fullscreen.active';
			
			if(this.fs) this.fullscreen.openFullscreen();
			else this.fullscreen.closeFullscreen();
			
		}
		else if(id=='restart_game'){
			
			let userdata:UserDataService = this.getServiceInstance<UserDataService>('userdata');
			userdata.resetSequence();
			router.navigate(null, 'modal');
			
		}
		
	}
	
	
	
	
}


let moduleClass:any = SettingsModule;
try{ moduleClass = getDependency<SettingsModule>(require('theme-iso/base/modules/modals/settings/settings.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./settings.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/modals/settings/settings.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./settings.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/modals/settings/settings.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/modals/settings/settings.config.user').default;} catch(e){}
config = resolveConfig('settings', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./settings.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/modals/settings/settings.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'settings', template, config, anims, css)