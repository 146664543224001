import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		let t = new gsap.core.Timeline()
		.fromTo('.col-left h1', {y:-200}, {y:0, duration:0.3, ease: "back.out(1.4)"}, '>-0.15');
		
		let domplayers = dom.querySelector('.players');
		for(let d of domplayers.children){
			t = t.fromTo(d, {x: -320}, {x:0, duration: 0.25}, '>-0.2');
		}
		
		t = t
		.fromTo('.col-right p.title', {marginTop:-150}, {marginTop:0, duration:0.25 }, '>-0.1')
		.fromTo('.grid,grid-collective', {opacity:0, y:40}, {opacity:1, y:0, duration:0.2 }, '>-0.1')
		.fromTo('.btn-help', {y:220}, {y:0, duration:0.3, ease: "back.out(1.5)"}, '>+0.2')
		.fromTo('.btn.orange', {y: 220}, {y: 0, duration:0.3, ease: "back.out(2.5)"}, '>-0.1')
		;
		return t;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}