import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';




export interface AudioOptions{
	volume?:number,
	loop?:boolean,
	repeat?:number,
	channel?:string;
	looptab?:number[],
	forceStart?:boolean,
	filename?:string,

	duration?:number | string,		//sec or %
	timestart?:number | string,		//sec or %
}

interface AudioItem{
	audio:HTMLAudioElement;
	loop:boolean;
	path:string,
	filename:string,
	looptab?:number[];
	duration?:number;
}




export class AudioService extends Service<typeof config>{
	
	override onViewReady()
	{
		console.log('AudioService.onViewReady');
		let storageService = this.getServiceInstance<StorageService>('storage');
		let soundEnabled = this.config.enabledInit;
		if(storageService.isset('soundEnabled')) soundEnabled = storageService.getBoolean('soundEnabled');
		
		if (soundEnabled) this.enable();
		else this.disable();
	}
	
	/**
	 * 
	 * @param file path to sound
	 * @param delay en second 
	 * @param repeat 0 for infiny
	 */
	playSound(themeUser:boolean, filename:string, delay:number, repeat:number, channel:string, volume?:number)
	{
		if(!channel) channel = 'main';
		let loop = repeat !== undefined && repeat !== 1;
		let options:AudioOptions = { loop, channel, repeat, filename, volume};
		if(!volume){
			switch (channel) {
				case 'background':
					options.volume = this.config.defaultVolume.background;
					break;
			
				default:
					options.volume = this.config.defaultVolume.main;
					break;
			}
		}
		let prefix = (themeUser ? `theme-${process.env.THEME}` : 'theme') + '/base/assets/';
		let path = prefix + filename;

		if(delay === 0) this.play(path, options);
		else setTimeout(this.play.bind(this, path, options), delay * 1000);
	}
	
	
	
	protected data:any = {};
	protected enabled:boolean = true;
	protected listDisabled:string[] = [];


	optionsDefault: AudioOptions = {
		volume:1,
		loop: false,
		repeat:1,
		looptab: null,
		forceStart :true,
	};
	

	isPlaying(channel:string):boolean
	{
		if(this.data[channel]){
			let item = this.data[channel];
			return !item.audio.paused;
		}
		else return false;
	}
	
	getCurrentFilename(channel:string):string
	{
		if(this.data[channel]) return this.data[channel].filename;
		else return null;
	}


	stop(channel:string):void
	{
		if(this.data[channel]){
			var item:AudioItem = this.data[channel];
			if(!item.audio.paused) item.audio.pause();
		}
		//stop all channelds
		else{
			for(let ch in this.data){
				var item:AudioItem = this.data[ch];
				if(!item.audio.paused) item.audio.pause();
			}
		}
	}

	public fadeAudioOut(channel: string, interval: number, audioVolumeTarget:number = 0){

		if(this.data[channel]){
			var item:AudioItem = this.data[channel];
			let fadeAudio = setInterval(function () {

				item.audio.volume -= interval;
				// When volume at zero stop all the intervalling
				if (item.audio.volume < interval) {
					clearInterval(fadeAudio);
					item.audio.volume = audioVolumeTarget;
					this.stop(channel);
				}
			}, 200);
		}
	}

	public fadeAudioIn(channel: string, interval: number, audioVolumeTarget: number){

		if(this.data[channel]){
			var item:AudioItem = this.data[channel];
			item.audio.volume = 0;
			let fadeAudio = setInterval(function () {
				item.audio.volume += interval;
				// When volume at zero stop all the intervalling
				if (item.audio.volume > audioVolumeTarget) {
					clearInterval(fadeAudio);
					item.audio.volume = audioVolumeTarget;
				}
			}, 200);
		}
	}
	
	
	
	protected play(filename:string, options:AudioOptions = null):void
	{
		if(!this.enabled) return;
		options = {...this.optionsDefault, ...options, };
		
		var audio:any;
		var item:AudioItem;
		let hash = options.channel;
		
		//if audio object has already been loaded, reuse it
		if(this.data[hash]){
			item = this.data[hash];
			item.loop = options.loop;
			item.looptab = options.looptab;
			item.path = filename;
			item.duration = NaN;
			item.filename = options.filename;
		}
		//if first load, create it
		else{
			item = {
				audio : new Audio(),
				loop : options.loop,
				looptab : options.looptab,
				path: filename,
				duration : NaN,
				filename: options.filename
			}
		}
		this.data[hash] = item;
		
		
		item.audio.src = filename;
		item.audio.load();

		if(options.loop){
			if(options.looptab){
				item.audio.ontimeupdate = function() {
					var time:number = item.audio.currentTime;
					if(isNaN(item.duration)) item.duration = item.audio.duration;
					if(!isNaN(item.duration)){
						var timeend:number = item.duration - options.looptab[1];
						if(time >= timeend) item.audio.currentTime = options.looptab[0];
					}
					//console.log("ontimeupdate "+time+" // "+looptab);
				};
			}
			else{
				item.audio.loop = true;
			}
		}
		
		
		if(options.duration){

			var timeend:number = -1;
			item.audio.ontimeupdate = function() {
				var time:number = item.audio.currentTime;
				if(isNaN(item.duration)) item.duration = item.audio.duration;

				if(timeend == -1 && !isNaN(item.duration)){

					let str:string = options.duration.toString();
					if(str.charAt(str.length - 1) == '%'){
						let percent:number = parseInt(str.substr(0, str.length - 1));
						timeend = item.duration * percent / 100;
					}
					else timeend = <number>options.duration;
				}

				if(timeend != -1 && time >= timeend) item.audio.pause();
			};
		}
		
		if(options.repeat > 1){
			let timeprev = 0;
			let countRepeat = 0;
			item.audio.ontimeupdate = function() {
				var time:number = item.audio.currentTime;
				//loop
				if(time < timeprev){
					countRepeat++;
					if(countRepeat === options.repeat){
						item.audio.pause();
					}
				}
				timeprev = time;
			};
		}

		if(!options.volume){
			switch (options.channel) {
				case 'background':
					options.volume = this.config.defaultVolume.background;
					break;
			
				default:
					options.volume = this.config.defaultVolume.main;
					break;
			}
		}
		
		item.audio.volume = options.volume;

		item.audio.currentTime = 0;
		let playPromise = item.audio.play();
	}





	enable(replayPendingSounds:boolean = true):void
	{
		this.enabled = true;
		if(replayPendingSounds){
			this.listDisabled.forEach((k:string) => {
				this.data[k].audio.play();
			});
		}

		this.listDisabled = [];
	}


	disable():void
	{
		this.enabled = false;
		for(let k in this.data){
			let item = this.data[k];
			if(!item.audio.paused){
				item.audio.pause();
				this.listDisabled.push(k);
			}
		}
	}
	

	public addSelectorEvent(classname:string, evt:string, filename:string, options:AudioOptions = null):void
	{
		/* 
		this.renderer.listen('document', evt, (event) => {
			let classes:string[] = event.target.className.split(' ');
			if(classes.indexOf(classname) > -1){
				this.play(filename, options);
			}
		});
		 */
	}
	
	
}


let serviceClass:any = AudioService;
try{ serviceClass = getDependency<AudioService>(require('theme-iso/base/services/audio/audio.service.user.ts')); }catch(e){}


import * as configSystem from './audio.config';
import { StorageService } from '../storage/storage.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/audio/audio.config.user').default;} catch(e){}
config = resolveConfig('audio', configSystem.default, configUser, process.env.RUNTIME_ENV)



registerServiceDef(serviceClass, 'audio', config)
