import gsap from 'gsap';
export default {

	transition: (domIn: HTMLElement, domOut: HTMLElement): GSAPTimeline =>
	{
		// transition immédiate par défaut
		const tin: GSAPTimeline = new gsap.core.Timeline();
		tin.set(domIn, { left: 0, alpha: 1 });
		const tout: GSAPTimeline = new gsap.core.Timeline();
		tin.set(domOut, { left: 0, alpha: 0 });
		const t = new gsap.core.Timeline();
		t.add(tin);
		t.add(tout, '<0');
		return t;
	}

}