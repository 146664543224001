import { mergeConfigEnv } from "./config";
import { getModuleDefByID, ModuleInstance, ModuleDef } from "./core";
import { ModuleHandler } from "./modules";
import routeAnimator from "./route.animator";


const USE_HASH:boolean = true;

class Router{
	
	
	private routes:Routes;
	private cacheRoutes:{[hash:string]:Route};
	private outletDefs:{[name:string]:OutletDef} = {};
	private state:{[name:string]:string} = {};
	private modules:{[name: string]: ModuleInstance|HTMLElement} = {};
	private handlers:((outlet:string, route:string)=>void)[] = [];
	private history:{[name:string]:string[]};
	
	private ModuleHandler:ModuleHandler;
	public navigateCallback:Function;
	private enablePushState:boolean = false;
	
	
	
	/**
	 * initialization/utility functions
	 */
	init(config:any, forcePath:string)
	{
		//module class vs id
		this.enablePushState = config.enablePushState;
		this.routes = config._routes;
		if(this.enablePushState) window.addEventListener('popstate', this.onNavigate.bind(this));
		
		this.ModuleHandler = new ModuleHandler();
		this.history = {};
		
		
		//static definition of routes (directly in outlet container)
		//(this feature is not used for now)
		for(let outlet in this.outletDefs){
			let children:any = this.outletDefs[outlet].dom.children;
			if(children.length > 0){
				for (var i = 0; i < children.length; i++) {
					let dom = children[i];
					let path = dom.dataset ? dom.dataset.path : null;
					if(!path) throw new Error(`router outlet static child must have a "data-path" defined`)
					
					let route:Route = { path, dom, outlet};
					this.routes.push(route);
				}
				//remove children from dom
				let container = this.outletDefs[outlet].dom;
				while(container.firstChild) container.removeChild(container.lastChild);
			}
		}
		
		
		
		//set cache + validate module existence
		this.cacheRoutes = {};
		for(let i in this.routes){
			let route = this.routes[i];
			if(route.module && !this.ModuleHandler.moduleExists(route.module)){
				throw new Error(`module id "${route.module}" is not defined`);
			}
			this.cacheRoutes[(route.outlet || 'root')+':'+route.path] = route;
		}
		
		
		//validate linked routes
		for(let i in this.routes){
			let route = this.routes[i];
			if(route.links){
				for(let outlet in route.links){
					let elmt = outlet + ':' + route.links[outlet];
					this.cacheRoutes[elmt];
					if(!this.cacheRoutes[elmt]) throw new Error(`Route links "${elmt}" is not defined`);
					this.cacheRoutes[elmt].isLinked = true;
				}
			}
		}
		
		
		//init navigation
		let path = null;
		if(forcePath) path = forcePath;
		else if(router.getPath()) path = router.getPath();
		else path = config.defaultPath;
		this.navigate(path);
	}
	
	//todo : add an interface outlet : dom, transition:string
	addOutlet(option:OutletDef)
	{
		if(!option.outlet) option.outlet = 'root';
		if(!option.isLinked) option.isLinked = false;
		if(!option.dom) option.dom = (<any>document);
		this.outletDefs[option.outlet] = {
			dom: option.dom.querySelector(option.selector),
			isLinked: option.isLinked
		};
		if(!this.outletDefs[option.outlet].dom) throw new Error(`outlet selector '${option.selector}' wasnt found`);
	}
	addListener(func:(outlet:string, route:string)=>void):void
	{
		this.handlers.push(func);
	}
	
	getOutletDom(outlet:string):OutletDef
	{
		if(!outlet) outlet = 'root';
		return this.outletDefs[outlet];
	}
	getOutletDefs():any
	{
		return this.outletDefs;
	}
	getRoutes():Routes
	{
		return this.routes;
	}
	
	
	public getState(outlet:string):string
	{
		return this.state[outlet];
	}
	
	
	/**
	 * event triggered when url location change
	 * call displayRoute for each updated outlet
	 */
	onNavigate(evt:Event = null, forceReload:boolean = false, urlarg:string= null)
	{
		//get hash part of the url
		let urlhash = this.getPath(urlarg);
		if(urlhash === null){
			throw new Error('urlhash shouldnt be null');
		}
		
		
		//loop over each outlet definitions (separated by /)
		
		let urlstates:any = {};
		let tab = urlhash.split('/');
		let injections:{route:string, outlet:string, prevRoute:string, reload:boolean}[] = [];
		
		for(let i = 0; i < tab.length; i++){
			let outlet;
			let route:string;
			//extract outlet/route data for each
			if(tab[i].indexOf(':') === -1){
				outlet = 'root';
				route = tab[i];
			}
			else{
				let keyvalue = tab[i].split(':');
				outlet = keyvalue[0];
				route = keyvalue[1];
			}
			
			//add linked routes (see doc router > Navigation implicite)
			//this push extends the loop further
			let routeDef:Route = this.cacheRoutes[outlet+':'+route];
			if(!routeDef){
				throw new Error(`route "${outlet+':'+route}" is not defined`);
			}
			if(routeDef.links){
				for(let outlet in routeDef.links){
					let elmt = outlet + ':' + routeDef.links[outlet];
					tab.push(elmt);
				}
			}
			
			
			//if outlet is not already in that state : navigate to it
			
			if(this.state[outlet] !== route || (forceReload && outlet.substr(0, 5) !== 'debug')){
				injections.push({route, outlet, prevRoute:this.state[outlet], reload: forceReload});
			}
			
			for(let handler of this.handlers) handler(outlet, route);
			
			//save state
			this.state[outlet] = route;
			urlstates[outlet] = true;
		}
		
		
		for(let i in injections){
			this.displayRoute(injections[i].route, injections[i].outlet, injections[i].prevRoute, injections[i].reload);
		}
		
		
		//loop over state and check if it's no longer in the url (which means it has been closed)
		for(let k in this.state){
			if(k !== 'root'){
				if(!urlstates[k]){
					this.clearOutlet(k);
					delete this.state[k];
				}
			}
		}
		
		if(this.navigateCallback) this.navigateCallback(urlhash);
		
	}
	
	
	/**
	 * inject required module in the associated outlet container
	 * destroy previous module
	 */
	displayRoute(routeStr:string, outlet:string, prevRouteStr:string, reload:boolean):void
	{
		//search the corresponding route
		if(outlet === 'root') outlet = null;
		let route:Route = this.routes.find((routeObj:Route) => routeObj.outlet == outlet && routeObj.path === routeStr);
		if(!route) throw new Error(`route '${routeStr}' for outlet '${outlet}' not found (or module doesn't exist)`);
		
		let newModule:ModuleInstance|HTMLElement;
		let prevModule = this.modules[outlet];
		
		//if reload : destroy module must be done before instanciation of module in
		if(prevModule && 'dom' in prevModule && reload){
			let dom = ('dom' in prevModule) ? prevModule.dom : prevModule;
			if(dom.parentNode) dom.parentNode.removeChild(dom.parentNode.firstChild);
			this.ModuleHandler.destroyModule(prevModule);
		}
		
		//module route
		if(route.module){
			let module = getModuleDefByID(route.module);
			if(!module) throw new Error(`module '${route.module}' doesn't exist`);
			newModule = this.ModuleHandler.instanciateModule(module.class);
		}
		//static route
		else if(route.dom){
			newModule = route.dom;
		}
		else throw new Error(`wrong definition for route "${routeStr}" or outlet "${outlet}"`);
		
		
		//save current instance so we can destroy it next time we navigate in that outlet
		let newDom = (<any>newModule).dom || newModule;
		
		this.modules[outlet] = newModule;
		
		let routeOut:Route = null;
		let routeIn:Route = {path:route.path, dom:newDom};
		let defOut:ModuleDef;
		let defIn:ModuleDef = (<ModuleInstance>newModule).classInstance ? (<ModuleInstance>newModule).classInstance.def : null;
		
		if(prevModule){
			let prevDom = (<any>prevModule).dom || prevModule;
			routeOut = {dom: prevDom, path: prevRouteStr};
			defOut = (<ModuleInstance>prevModule).classInstance ? (<ModuleInstance>prevModule).classInstance.def : null;
		}
		
		//inject module in
		let outletDef = this.getOutletDom(outlet);
		this.ModuleHandler.inject(newModule, outletDef.dom);
		
		routeAnimator.transition(routeOut, defOut, routeIn, defIn, outlet, () => {
			//on complete : destroy previous instance
			
			if(prevModule  && !reload){
				//prevModule must remove itself from its parent
				let dom = ('dom' in prevModule) ? prevModule.dom : prevModule;
				if(dom.parentNode) dom.parentNode.removeChild(dom.parentNode.firstChild);
				
				//if reload : destroy module must be done before instanciation of module in
				if('dom' in prevModule && !reload) this.ModuleHandler.destroyModule(prevModule);
			}
			
		});
		
	}
	
	
	private clearOutlet(outlet:string):void
	{
		let prevModule = this.modules[outlet];
		let prevDom = (<any>prevModule).dom || prevModule;
		
		let routeOut:Route = {path:'', dom: prevDom};
		let defOut:ModuleDef = (<ModuleInstance>prevModule).classInstance ? (<ModuleInstance>prevModule).classInstance.def : null;
		if(!prevModule)throw new Error(`how is this possible ?`);
		
		routeAnimator.transition(routeOut, defOut, null, null, outlet, ()=>{
			//on complete, destroy module
			let dom = this.getOutletDom(outlet).dom;
			this.ModuleHandler.empty(dom);
			if('dom' in prevModule) this.ModuleHandler.destroyModule(prevModule);
		});
		
		this.modules[outlet] = null;
	}
	
	
	
	
	
	
	/**
	 * triggers a physical url change in the url location
	 */
	
	navigate(path:string, outlet:string = 'root'):void
	{
		//construct a url based on the current state of each outlet
		//url will look like /home/popup:help
		
		if(!this.history[outlet]) this.history[outlet] = [];
		this.history[outlet].push(path);
		
		let state = {...this.state};
		if(path) state[outlet] = path;
		else delete state[outlet];	//delete outlet from url
		
		let array = [];
		for(let k in state){
			if(k === 'root') array.push(state[k]);
		}
		for(let k in state){
			if(k !== 'root'){
				if(!this.outletDefs[k].isLinked){
					array.push(k + ':' + state[k]);
				}
			}
		}
		
		// let url = '/' + (USE_HASH ? '#/' : '') + array.join('/');
		let url = location.pathname + location.search + (USE_HASH ? '#/' : '') + array.join('/');
		
		if(this.enablePushState){
			window.history.pushState('', '', url);
			var popStateEvent = new PopStateEvent('popstate');
			window.dispatchEvent(popStateEvent);
		}
		else{
			this.onNavigate(null, false, url);
		}
	}
	
	
	/**
	 * navigate to the previous route of this outlet
	 * will bug if used along with the back button of the browser
	 */
	public prev(outlet:string = 'root'):void
	{
		let path = null;
		if(this.history[outlet]){
			this.history[outlet].pop();
			path = this.history[outlet][this.history[outlet].length - 1];
		}
		this.navigate(path, outlet);
		this.history[outlet].pop();
	}
	
	
	
	/**
	 * urlarg is for !enablePushState where we can't rely on the location object
	 */
	public getPath(urlarg:string = null):string
	{
		let url = urlarg ? urlarg : location.hash;
		if (routerConfig.enablePushState === true) {
			if(url.charAt(0) === '/') url = url.substring(1);
			if(url.charAt(0) === '#') url = url.substring(1);
		} else {
			//remove everything before the hash
			url = url.replace(/^.+#/, '');
		}
		//trim prefix characters
		if(url.charAt(0) === '/') url = url.substring(1);
		return url;
	}
}

const router = new Router();
export default router;

export interface Route {
	path?: string;
	module?: string;
	dom?: HTMLElement;		//dom is for static route definition
	redirectTo?: string;
	outlet?: string;
	links?:{[outlet:string]:string};	//for linking to other routes
	isLinked?:boolean;
	
}

export interface OutletDef {
	dom?:HTMLElement;
	isLinked?:boolean;
	outlet?:string,
	selector?:string,
}



export type Routes = Route[];

import * as routerConfigSystem from 'theme/base/config/router.config';
import { resolveConfig } from 'core/lib/config';


let routerConfig = routerConfigSystem.default.default;
let routerConfigUser; try{ routerConfigUser = require('theme-iso/base/config/router.config.user').default;} catch(e){}
routerConfig = resolveConfig('router', routerConfigSystem.default, routerConfigUser, process.env.RUNTIME_ENV);

export function getRouterConfig():any
{
	return routerConfig;
}
