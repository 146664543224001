/**
 * Dowimoteur
 * 
 * Base module "results"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './results.config';
import { Game, Player, UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';
import gsap from 'gsap';
import { dataGlobalHelp } from '../help/help.global';


export class ResultsModule extends Module<typeof config>{
	
	private game:Game;
	private playersOrder:Player[];
	private countZindex:number = 0;
	private interval:any;
	private interval2:any;
	private roundIndex:number;
	
	override onInit(): void
	{
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		this.game = userdata.getCurrentGame();
		let gameService = this.getServiceInstance<GameService>('game');
		
		//mock history
		/*if(getMainConfig().debugMode){
			if(!this.game){
				userdata.createMock();
				this.game = gameService.initGame();
			}
			this.game.roundIndex = 2;
			for(let i in this.game.players){
				this.game.players[i].history = this.config.historyMock[i];
			}
		}*/
		
		for(let i in this.game.players) this.game.players[i].playerIndex = parseInt(i);
		
		
		this.roundIndex = this.game.roundIndex - 1;
		this.attrBinding.nbplayers = this.game.players.length;
		this.attrBinding.roundIndex = this.roundIndex;
		this.attrBinding.typeGame = this.game.typeGame;
		this.attrBinding.lastTurn = this.roundIndex === gameService.config.nbRound - 1;
		
		this.attrBinding.colorBars = [
			'#D04361', '#B4D657', '#ED9EAA', '#2D83BF', '#45AC8E', '#F2C269', '#B089D3'
		];
		this.attrBinding.heightBars = 100;
		
		this.setScores(this.roundIndex);
		this.attrBinding.players = this.game.players;
		this.updateLoop('players');
		
		
		
		
		//position players based on last round (before animation)
		this.playersOrder = this.orderPlayers(this.roundIndex, this.game.players);
		this.positionPlayers(this.playersOrder);
		this.attrBinding.players = this.playersOrder;	//refresh
		
		this.interval = setTimeout(() => {
			this.animate(this.playersOrder, this.roundIndex);
		}, 1600);
		
		this.interval2 = setTimeout(() => {
			//positions
			let prevOrder = this.playersOrder;
			this.playersOrder = this.orderPlayers(this.roundIndex, this.game.players);
			this.positionPlayers(this.playersOrder);
			this.animatePlayers(this.game.players, true);
		}, 4000);
	}
	
	
	
	
	private setScores(indexRound:number):void
	{
		//convert scores in an array for templates
		let maxScore = 0;
		for(let player of this.game.players){
			player.scores = [...player.history[indexRound]];
			
			//retrieve max score for normalization (see below)
			for(let score of player.scores){
				if(score > maxScore) maxScore = score;
			}
		}
		
		//normalize scores based on max score accross all players
		for(let player of this.game.players){
			for(let k in player.scores){
				player.scores[k] /= maxScore; //to [0,1] interval
			}
		}
		
		
		//calculated data for processExpressioning star above max histogram
		
		for(let i in this.game.players){
			let obj = this.game.players[i];
			let max = -1;
			for(let j in obj.scores){
				if(obj.scores[j] > max){
					max = obj.scores[j];
					obj.indexMax = parseInt(j);
					obj.valueMax = max;
				}
			}
		}
		
	}
	
	private animate(players:Player[], roundIndex:number):void
	{
		//animate histogram
		let domframe = this.dom.querySelector('.frame');
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		for(let i = 0; i<7; i++){
			for(let j in players){
				
				let player = players[j];
				let value = player.scores[i];
				
				let selector = `.result-item${player.playerIndex} .diagram-item:nth-child(${i+1})`;
				t.to(selector, {height: value * 100, duration: 0.04});
				
			}
		}
		
	}
	
	
	
	private orderPlayers(roundIndex:number, players:Player[]):Player[]
	{
		let output = [...players];
		
		for(let j in output){
			let player = players[j];
			
			//mock
			if(getMainConfig().debugMode && !player.history[roundIndex]){
				player.history[roundIndex] = [0,1,2,3,4,5,6];
			}
			
			player.sum = player.history[roundIndex].reduce((partialSum, a) => partialSum + a, 0);
		}
		output.sort((p1, p2) => p1.sum > p2.sum ? -1 : +1);
		return output;
	}
	
	
	private positionPlayers(players:Player[]):void
	{
		let nbColumn = 5;
		for(let j in players){
			let index = parseInt(j);
			let player = players[j];
			player.x = (index % nbColumn) * 349 + 33;
			player.y = Math.floor(index / nbColumn) * 280 + 32;
		}
	}
	
	private animatePlayers(players:Player[], animate:boolean):void
	{
		const t:GSAPTimeline = new gsap.core.Timeline();
		t.add('start_players');
		for(let j in players){
			let index = parseInt(j);
			let player = players[j];
			let selector = `.result-item${player.playerIndex}`;
			let dom:HTMLElement = this.dom.querySelector(selector);
			
			if(!animate){
				dom.style.left = player.x + 'px';
				dom.style.top = player.y + 'px';
			}
			else{
				const t2:GSAPTimeline = new gsap.core.Timeline();
				t2.set(dom, {zIndex: ++this.countZindex + 100});
				t2.to(dom, {
					left: player.x+'px', 
					top: player.y+'px',
					duration: 0.8
				});
				t.add(t2, '<+=0.1');
			}
		}
	}
	
	
	public getClassId(index:number):string
	{
		return `result-item result-item${index}`;
	}
	
	
	
	public isBtnHistoryDisabled(side:number, roundIndex:number):boolean
	{
		if(side === -1) return roundIndex === 0;
		else if(side === 1) return roundIndex === this.roundIndex;
	}
	
	public clickHistory(side:number):void
	{
		this.attrBinding.roundIndex += side;
		let roundIndex = this.attrBinding.roundIndex;
		this.setScores(this.attrBinding.roundIndex);
		
		this.attrBinding.players = this.attrBinding.players;	//refresh
		
		//positions
		
		if(this.interval) clearInterval(this.interval);
		if(this.interval2) clearInterval(this.interval2);
		
		
		this.interval = setTimeout(() => {
			this.animate(this.playersOrder, this.attrBinding.roundIndex);
		}, 500);
		
		this.interval2 = setTimeout(() => {
			//positions
			let prevOrder = this.playersOrder;
			this.playersOrder = this.orderPlayers(roundIndex, this.game.players);
			this.positionPlayers(this.playersOrder);
			this.animatePlayers(this.game.players, true);
		}, 2900);
	}
	
	
	public clickValidate():void
	{
		//next turn
		let gameService = this.getServiceInstance<GameService>('game');
		if(this.roundIndex < gameService.config.nbRound - 1){
			
			//display pedagogic content if any
			let contents = gameService.getPedagogicContent(this.game.pedagogicalFlags, this.roundIndex + 1, 'afterScore', gameService.firstCards);
			
			//flag the pedagogicContents as already seen in game data
			for(const content of contents){
				this.game.pedagogicalFlags[content.id] = true;
			}
			
			if(contents.length > 0){
				dataGlobalHelp.type = 'FEEDBACK_EDUCATION';
				dataGlobalHelp.pedagogicContents = contents;
				dataGlobalHelp.fastTransition = false;
				dataGlobalHelp.callbackConfirm = () => {
					router.navigate('setup-round');	//loop back
				}
				router.navigate('help', 'modal');
			}
			else{
				router.navigate('setup-round');	//loop back
			}
			
			
		}
		//end
		else router.navigate('final-screen');
		
	}
}



let moduleClass:any = ResultsModule;
try{ moduleClass = getDependency<ResultsModule>(require('theme-iso/base/modules/pages/results/results.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./results.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/results/results.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./results.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/results/results.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/results/results.config.user').default;} catch(e){}
config = resolveConfig('results', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./results.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/results/results.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'results', template, config, anims, css)