import gsap from 'gsap';
import { getDependency, ModuleDef } from './core';
import { Route } from './router';

let routeConfigSystem:any = getDependency<string>(require('theme/base/config/route.transitions'));
let routeConfigUser:any; try{ routeConfigUser = getDependency<string>(require('theme-iso/base/config/route.transitions.user'));} catch(e){}
let routeConfig = {...routeConfigSystem, ...routeConfigUser};



class RouteAnimator{
	
	transition(routeOut:Route, defOut:ModuleDef, routeIn:Route, defIn:ModuleDef, outlet:string, onComplete:()=>void):void
	{
		//this should never happen
		if(!routeOut && !routeIn) throw new Error(`at least 1 route (in or out) should be defined`);
		
		
		//resolve the 3 function (out, transition, in)
		
		let _out:string = null;
		let _in:string = null;
		
		let funcOut:Function;
		if(routeOut){
			_out = routeOut.path;
			funcOut = defOut ? this.resolveConstFunc(defOut.anims, 'out') : null;
		}
		
		let funcFrom:Function;
		
		let funcIn:Function;
		if(routeIn){
			_in = routeIn.path;
			funcIn = defIn ? this.resolveConstFunc(defIn.anims, 'in') : null;
			funcFrom = defIn ? this.resolveConstFunc(defIn.anims, 'from') : null;
		}
		let funcTrans = this.resolveTransitionFunc(_out, _in, outlet);
		
		
		//combine the 4 timeline into a global one
		
		const timelineGlobal:GSAPTimeline = new gsap.core.Timeline();
		
		let tOut:GSAPTimeline = funcOut ? funcOut(routeOut.dom) : new gsap.core.Timeline();
		let tTransition:GSAPTimeline = funcTrans(routeOut ? routeOut.dom : null, routeIn ? routeIn.dom : null);
		let tFrom:GSAPTimeline = funcFrom ? funcFrom(routeIn.dom) : new gsap.core.Timeline();
		let tIn:GSAPTimeline = funcIn ? funcIn(routeIn.dom) : new gsap.core.Timeline();
		
		timelineGlobal.add(tOut);
		timelineGlobal.add(tFrom);
		timelineGlobal.add(tTransition);
		timelineGlobal.add(tIn);
		
		//play it
		timelineGlobal.play();
		timelineGlobal.eventCallback('onComplete', onComplete);
	}
	
	
	
	
	
	private resolveConstFunc(obj:any, type:string):Function
	{
		if(obj && obj['transition_' + type]) return obj['transition_' + type];
		return null;
	}
	
	private resolveTransitionFunc(_out:string, _in:string, outlet:string):Function
	{
		if(!outlet) outlet = 'root';
		outlet = this.toCamelCase(outlet);
		
		let output:Function;
		
		//outlet specific
		if(_out && _in && routeConfig[`transition_${_out}_${_in}_${outlet}`]) output = routeConfig[`transition_${_out}_${_in}_${outlet}`];
		else if(_in && routeConfig[`transition_x_${_in}_${outlet}`]) output = routeConfig[`transition_x_${_in}_${outlet}`];
		else if(_out && routeConfig[`transition_${_out}_x_${outlet}`]) output = routeConfig[`transition_${_out}_x_${outlet}`];
		else if(routeConfig[`transition_x_x_${outlet}`]) output = routeConfig[`transition_x_x_${outlet}`];
		//global
		else if(_out && _in && routeConfig[`transition_${_out}_${_in}`]) output = routeConfig[`transition_${_out}_${_in}`];
		else if(_in && routeConfig[`transition_x_${_in}`]) output = routeConfig[`transition_x_${_in}`];
		else if(_out && routeConfig[`transition_${_out}_x`]) output = routeConfig[`transition_${_out}_x`];
		else if(routeConfig[`transition_x_x`]) output = routeConfig[`transition_x_x`];
		else{
			throw new Error(`"route.transitions" should at least define transition_x_x() method`);
		}
		
		return output;
	}
	
	
	/**
	 * id-module => idModule
	 */
	private toCamelCase(str:string)
	{
		return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		}).replace(/[\s-]+/g, '');
	}
	
}

const routeaAnimator = new RouteAnimator()
export default routeaAnimator;