export default {
	
	//default config
	default : {
		
	},
	
	
	//dev
	dev : {
		
	},
}