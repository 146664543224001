import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';



export class PipeService extends Service<typeof config>{
	
	bbcodeConfig:BbcodeConfig[] = [
  	{ target: '\n', replace: '<br />' },
  	{ target: '[b]', replace: '<b>' },
  	{ target: '[/b]', replace: '</b>' },
	];
	
	public bbcode(value:string):string
	{
		if(!value) return value;
		for(let k in this.bbcodeConfig){
			let item = this.bbcodeConfig[k];
			// let regexp = new RegExp(item.target, "g");
			value = (<any>value).replaceAll(item.target, item.replace);
		}
		return value;
	}
	
	public invertbool(value:boolean):boolean
	{
		return !value;
	}
	
	
	public translateimg(id:string):string
	{
		const currentTheme = (process.env.THEME !== 'x' && process.env.THEME !== '0' && process.env.THEME !== '') ? `theme-${process.env.THEME}` : 'theme';
		return `url(/app/${currentTheme}/base/assets/images/translatedImages/${id}`;
	}
	
	public nl2br(str:string):string
	{
		if(str) str = str.replace('\n', '<br />');
		return str;
	}
	
	
}


let serviceClass:any = PipeService;
try{ serviceClass = getDependency<PipeService>(require('theme-iso/base/services/pipes/pipes.service.user.ts')); }catch(e){}


import * as configSystem from './pipes.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/pipes/pipes.config.user').default;} catch(e){}
config = resolveConfig('pipes', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'pipes', config)



interface BbcodeConfig{
	target:string,
	replace:string,
}