import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './select-language.config';
import { Evt } from 'theme/base/config/events';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';





export class SelectLanguageModule extends Module<typeof config>{
	
	static locale:string;	//defined from outside
	protected userdata:UserDataService;
	
	override onInit(): void {
		
		this.attrBinding.locale = SelectLanguageModule.locale;
		this.attrBinding.languages = config.languages;
		this.userdata = this.getServiceInstance<UserDataService>('userdata');
		broadcaster.addListener(Evt.SELECT_LOCALE, this.onSelectLocale.bind(this), this);
	}
	
	protected onSelectLocale(locale:string):void
	{
		this.attrBinding.locale = locale;
	}
	
	isSelected(locale:string):boolean
	{
		return locale === SelectLanguageModule.locale;
	}
	
	select(locale:string)
	{
		if(SelectLanguageModule.locale !== locale){
			SelectLanguageModule.locale = locale;
			broadcaster.dispatchEvent(Evt.SELECT_LOCALE, [SelectLanguageModule.locale]);
			this.attrBinding.languages = this.attrBinding.languages;
			this.userdata.updateData('locale', locale);
			this.userdata.saveProgress();

			var htmlContainer = <HTMLElement>document.getElementsByTagName('html')[0];
			htmlContainer.lang = locale.slice(0, 2);
		}
	}
	
}




let moduleClass:any = SelectLanguageModule;
try{ moduleClass = getDependency<SelectLanguageModule>(require('theme-iso/base/modules/ui/select-language/select-language.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./select-language.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/ui/select-language/select-language.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./select-language.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/ui/select-language/select-language.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/ui/select-language/select-language.config.user').default;} catch(e){}
config = resolveConfig('select-language', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./select-language.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/ui/select-language/select-language.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};

registerModuleDef(moduleClass, 'select-language', template, config, anims, css)