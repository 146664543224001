/**
 * Dowimoteur
 * 
 * Base module "select-game"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './select-game.config';
import { Game, UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';
import { dataGlobalHelp } from '../../help/help.global';
import gsap from 'gsap';


export class SelectGameModule extends Module<typeof config>{
	
	private userdata:UserDataService;
	private btnEnabled:boolean;
	
	override onInit(): void
	{
		this.userdata = this.getServiceInstance<UserDataService>('userdata');
		let data = this.userdata.getCurrentData();
		
		//24/02/2023 14:03
		this.attrBinding.games = data.games.filter(game => game.roundIndex < 10);
		/* 
		if(getMainConfig().debugMode){
			this.attrBinding.games = [
				{name: 'salut', index:0},
				{name: 'salut', index:1},
				{name: 'salut', index:2},
			]
		}
		 */
		this.updateLoop('games');
		this.attrBinding.indexSelected = -1;
	}
	
	
	formatName(game:Game, isSelected:boolean):string
	{
		if(!isSelected) return game.name;
		else return `${game.name} ${this.formatDate(game.datetime)}`;
	}
	formatDate(datetime:number):string
	{
		let date = new Date();
		date.setTime(datetime);
		return date.getUTCFullYear() +"/"+ (date.getUTCMonth()+1) +"/"+ date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();
	}
	
	
	clickBack():void
	{
		router.navigate('home');
	}
	
	
	
	selectGame(index:number):void
	{
		//todo : 
		// selected state
		// unlock btn
		// delete smth selected
		// case all deleted
		// btn back
		
		for(let i = 0; i<this.attrBinding.games.length; i++){
			let dom:HTMLElement = this.dom.querySelector('.item-save:nth-child('+(i+1)+')');
			this.selectDom(this.attrBinding.games[i], dom, false);
		}
		
		let dom:HTMLElement = this.dom.querySelector('.item-save:nth-child('+(index+1)+')');
		this.selectDom(this.attrBinding.games[index], dom, true);
		
		this.attrBinding.indexSelected = index;
	}	
	
	private selectDom(game:Game, dom:HTMLElement, isSelected:boolean)
	{
		let dom2 = dom.querySelector('input[type="radio"]');
		if(isSelected && !dom2.classList.contains('selected')) dom2.classList.add('selected');
		else if(!isSelected && dom2.classList.contains('selected')) dom2.classList.remove('selected');
		
		dom2 = dom.querySelector('p.name');
		dom2.innerHTML = this.formatName(game, isSelected);
	}
	
	
	
	clickDeleteGame(index:number):void
	{
		dataGlobalHelp.callbackConfirm = this.deleteGame.bind(this);
		router.navigate('delete-game', 'modal');
		
	}
	
	isBtnEnabled(games:Game[], indexSelected:number):boolean
	{
		let btnEnabled = games.length > 0 && indexSelected !== -1;
		
		//if becomes enabled, play a small animation
		if(!this.btnEnabled && btnEnabled){
			new gsap.core.Timeline()
			.fromTo('button.btn', {scale:0.88}, {scale:1, duration:0.2, ease: "back.out(2.0)"})
		}
		
		this.btnEnabled = btnEnabled;
		return btnEnabled;
	}
	
	
	deleteGame(index:number):void
	{
		let data = this.userdata.getCurrentData();
		data.games.splice(index, 1);
		this.attrBinding.games = data.games;
		this.updateLoop('games');

		this.userdata.saveProgress();
		
	}
	
	clickValidate():void
	{
		let data = this.userdata.getCurrentData();
		
		//retrieve index (because in this view, games have been filtered)
		//this must be done before reloading save (which will create a new object so we can't do comparisons anymore)
		let curGameIndex = data.games.findIndex(game => game === this.attrBinding.games[this.attrBinding.indexSelected])
		
		//reload from storage to erase any unsaved events
		this.userdata.reloadCurrentData('n');
		this.userdata.updateCurrent('n');
		data = this.userdata.getCurrentData();
		data.curGameIndex = curGameIndex;
		
		this.userdata.saveIfN();
		
		let gameService = this.getServiceInstance<GameService>('game');
		gameService.initGame();
		
		let game = this.userdata.getCurrentGame();
		broadcaster.dispatchEvent('SET_TIMER', [game.currentTime]);
		broadcaster.dispatchEvent('START_TIMER');
		
		router.navigate('setup-round');
	}
}








let moduleClass:any = SelectGameModule;
try{ moduleClass = getDependency<SelectGameModule>(require('theme-iso/base/modules/pages/menu/select-game/select-game.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./select-game.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/menu/select-game/select-game.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./select-game.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/menu/select-game/select-game.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/menu/select-game/select-game.config.user').default;} catch(e){}
config = resolveConfig('select-game', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./select-game.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/menu/select-game/select-game.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'select-game', template, config, anims, css)