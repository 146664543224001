import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';




export class DataService extends Service<typeof config>{
	
	sequences: Sequence[];
	hasLogin:boolean;
	available_languages: any;
	startTime:Date = new Date();
	
	
	
	onViewReady(){
		console.log('DataService.onViewReady');
		
	}
	 	
}




let serviceClass:any = DataService;
try{ serviceClass = getDependency<DataService>(require('theme-iso/base/services/data/data.service.user.ts')); }catch(e){}

import * as configSystem from './data.config';
import { Sequence } from 'theme/base/services/flowchart/flowchart.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/data/data.config.user').default;} catch(e){}
config = resolveConfig('data', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'data', config)