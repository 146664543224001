import { Evt } from "theme/base/config/events";

class Broadcaster {
	
    private listeners:{
		[name:string]: {fct:any, instance:any}[]
	} = {};
		
		
		
	/**
	 * 2 storages, 1 indexes name, 1 indexes instance
	 * when we dispatch, we always only wanna scan per name
	 * which constrains us to have all the listeners in that as well
	 * forget about doing it in O(1)
	 * we're just gonna avoid doing it in O(n²)
	 * let's go for O(n)
	 * 
	 * 
	 * 
	 * 
	 */
		
    addListener(name:Evt|string, fct:any, instance:any = null):void
	{
		(this.listeners[name] = this.listeners[name] || []).push({
			fct,
			instance,
		});
    }
		
		
	/**
	 * O(n) removal of multiple listeners targeted by module instance
	 * removal is done in place, so memory complexity is constant
	 * this is important because services like translation might use massive amount of listeners
	 */
	removeListenerByInstance(name:string, instance:any):void
	{
		var listeners = this.listeners[name];
		if(listeners !== undefined) {
			let offset = 0;
			for(var i = 0; i < listeners.length; i++) {
				if(listeners[i].instance !== instance){	//keep item
					listeners[offset] = listeners[i];
					offset++;
				}
			}
			listeners.length = offset;
		}
	}
		
    removeListener(name:Evt|string, fct:any = null):number
	{
		let output:number = 0;
		if(!fct){
			this.listeners[name] = [];
		}
		else{
			var listeners = this.listeners[name];
			if(listeners !== undefined) {
				var foundAt:number = -1;
				for(var i = 0, len = listeners.length; i < len && foundAt === -1; i++) {
					if(listeners[i].fct === fct) {
						foundAt = i;
						output++;
					}
				}
				if(foundAt >= 0) listeners.splice(foundAt, 1);
			}
		}
		return output;
    }
    
    dispatchEvent(name:Evt | string, args:any[] = []):void
	{
        var listeners = this.listeners[name];
        args = args || [];
        if(listeners !== undefined) {
			let len = listeners.length;
            for(let i = 0; i < len; i++){
				listeners[i].fct.apply(null, args);
			}
        }
    }
	
	hasListeners(name:Evt):boolean
	{
		return this.getNbListeners(name) > 0;
	}

    getNbListeners(name:Evt):number
	{
		return this.listeners[name] === undefined ? 0 : this.listeners[name].length;
	}
}
const broadcaster = new Broadcaster()
export default broadcaster;
