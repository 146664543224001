/**
 * Dowimoteur
 * 
 * Base module "prompt"
 * Created on Jan 31, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './prompt.config';
import { Evt } from 'theme/base/config/events';
import { Command } from 'theme/base/services/flowchart/flowchart.service';


export class PromptModule extends Module<typeof config>{

	public update(text:string, container: string):void
	{
		this.attrBinding.type = 'btn';
		this.attrBinding.content = text;
		this.attrBinding.container = container;
	}

	public continue():void
	{
		this.attrBinding.type = 'none';
		let command:Command = { module: 'prompt', function: 'hide', args:[]};
		
		broadcaster.dispatchEvent(Evt.FRONT_COMMAND, [command]);
		broadcaster.dispatchEvent(Evt.CHARTFLOW_CONTINUE, [-1]);
	}
}



let moduleClass:any = PromptModule;
try{ moduleClass = getDependency<PromptModule>(require('theme-iso/base/services/prompt/prompt.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./prompt.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/services/prompt/prompt.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./prompt.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/services/prompt/prompt.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/prompt/prompt.config.user').default;} catch(e){}
config = resolveConfig('prompt', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./prompt.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/services/prompt/prompt.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'prompt', template, config, anims, css)