import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('h1', {opacity:0, scale: 0.5}, {opacity:1, scale:1, duration:0.35, ease: "back.out(1.5)"})
		.fromTo('.header-list', {opacity:0, scale: 0.5}, {opacity:1, scale:1, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('.list', {opacity:0, scale: 0.5}, {opacity:1, scale:1, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('.btn.orange', {marginBottom: -200}, {marginBottom: 0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}