export default {
	//default config
	default : {
		enabledInit : true,
		defaultVolume: {
			main: 0.2,
			background: 0.1
		}
	},
	//dev
	dev : {
		enabledInit : true
	},
}