import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './debug-audio.config';

export class DebugAudioModule extends Module<typeof config>{
	
	override onInit(): void {
		
	}
}



let moduleClass:any = DebugAudioModule;
try{ moduleClass = getDependency<DebugAudioModule>(require('theme-iso/base/modules/debug-audio/debug-audio.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./debug-audio.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/debug-audio/debug-audio.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./debug-audio.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/debug-audio/debug-audio.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/debug-audio/debug-audio.config.user').default;} catch(e){}
config = resolveConfig('debug-audio', configSystem.default, configUser, process.env.RUNTIME_ENV)



registerModuleDef(moduleClass, 'debug-audio', template, config, null, css)