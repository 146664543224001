/**
 * Dowimoteur
 * 
 * Base module "final-screen"
 * Created on Mar 20, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './final-screen.config';

export class FinalScreenModule extends Module<typeof config>{
	
	override onInit(): void
	{
		
	}
	
	navigate(route:string):void
	{
		router.navigate(route);
	}
}



let moduleClass:any = FinalScreenModule;
try{ moduleClass = getDependency<FinalScreenModule>(require('theme-iso/base/modules/pages/final-screen/final-screen.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./final-screen.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/final-screen/final-screen.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./final-screen.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/final-screen/final-screen.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/final-screen/final-screen.config.user').default;} catch(e){}
config = resolveConfig('final-screen', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./final-screen.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/final-screen/final-screen.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'final-screen', template, config, anims, css)