import { getDependency, getModuleDefByID, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router, { getRouterConfig } from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { ApiService } from 'theme/base/services/api/api.service';
import { DataService } from 'theme/base/services/data/data.service';
import { TranslationService } from 'theme/base/services/translation/translation.service';


export class ApplicationModule extends Module<typeof config>{
	
	protected apiService:ApiService;
	protected preloadService: PreloadService;
	protected dataService: DataService;
	protected userdataService: UserDataService;
	
	constructor() { 
		super();
	}
	onInit(){
		
		this.apiService = this.getServiceInstance<ApiService>('api');
		this.preloadService = this.getServiceInstance<PreloadService>('preload');
		this.dataService = this.getServiceInstance<DataService>('data');
		this.userdataService = this.getServiceInstance<UserDataService>('userdata');

		const mainConfig = getMainConfig();

		this.onInitStart();
		
		//resize logic
		window.addEventListener('resize', ($event) => {
			this.handleResize((<Window>$event.target));
		});
		this.handleResize(window);
		
		
		if(mainConfig.enableDebugModule){
			//update route in cookie (for userdata debug module)
			router.navigateCallback = (url:string) => {
				// console.log(url);
				this.userdataService.updateData('routePath', url);
				this.userdataService.saveProgress();
			}
		}

		
		//------------------------------------
		//events communication
		this.onInit_setEvents();
		
		//init data
		this.onInit_initUserData();
		
		//load data from back-end (or mock)
		this.onInit_loadData();
		
	}
	
	
	onClickShowPopup()
	{
		router.navigate('help', 'popup');
	}
		
	clickCloseMenu():void
	{
		router.prev('modal');
	}
	clickOpenMenu():void
	{
		router.navigate('settings', 'modal');
	}
	
	
	/**
	 * handle the resize logic of the main container
	 * todo : move to another service ?
	 */
	
	
	handleResize(window:Window):void
	{
		//calculate the scale for a size "contain"
		//and apply it to main container.
		
		const mainConfig = getMainConfig();
		
		let el:any = this.dom.querySelector('.scaled_container');
		let scale:number = Math.min(
			window.innerWidth / mainConfig.uiWidth,
			window.innerHeight / mainConfig.uiHeight,
		);
		
		let anchorstr:string = 'translate(-50%, -50%)';
		let value:string = anchorstr + " " + "scale(" + scale + ")";
		el.style.transform = value;
		
		el.style.width = mainConfig.uiWidth + 'px';
		el.style.height = mainConfig.uiHeight + 'px';
		
		//calculate zoom level and pass variable for css (bound on interval [0.25, 2.0])
		let zoomLvl = Math.max(Math.min(window.outerWidth / window.innerWidth, 2.0), 0.25);
		let htmlElmt = (document.querySelector('html') as HTMLElement);
		htmlElmt.style.setProperty('--browser-zoom-lvl', zoomLvl + "");
	}

	public onInitStart(){}

	public onInit_loadData(){
		let translationService = this.getServiceInstance<TranslationService>('translation');
		this.apiService.query('get', 'api/get_data')
		.then(resp => {
			let data = resp.data;
			
			this.dataService.sequences = data.sequences;
			translationService.wordings = data.wordings;
			this.dataService.available_languages = ["fr_FR", "en_UK"];

			// in case locale language is not fr_FR
			translationService.locale = (Object.values(this.dataService.available_languages).length === 1) ? Object.values(this.dataService.available_languages)[0].toString() : this.userdataService.getCurrentData().locale;
			this.userdataService.updateData('locale', translationService.locale);
			
			
			//sync shared_userdatas
			if(data.shared_userdatas){
				//delete previous local shared states
				let listLocal = this.userdataService.getListUserData();
				for(let k in listLocal){
					if(k !== 'n') this.userdataService.delete(k);
				}
				//save remote shared state (locally)
				for(let i in data.shared_userdatas){
					let shudata = data.shared_userdatas[i];
					if (typeof shudata.data === 'string' || shudata.data instanceof String){
						shudata.data = JSON.parse(shudata.data);
					}
					this.userdataService.save(shudata.uid, shudata.data);
				}
			}
			
			//quizzes
			
			return this.apiService.query('get', 'api/get_preload_list');
			//return {data: {assets:[]}};
		}) 
		//preload images
		.then(resp => {
			let imgsToLoad = new Array<string>();
			let audiosToLoad = new Array<string>();
			//const baseURL = (document.baseURI.endsWith('index.html')) ? document.baseURI.replace('index.html', '') : api.config.baseUrl;
			const imgExtenstionsArray = [".png", ".jpg", ".jpeg", ".gif", ".webp", ".avif"];
			const audioExtenstionsArray = [".mp3", ".wav", ".ogg", ".wma", ".mid"];
			const baseURL = this.apiService.config.baseUrl;
			for (let asset of resp.data.assets) {
				//toLoad.push(asset.replace('./app/', baseURL));
				let extension = asset.substr(asset.lastIndexOf("."));
				if (imgExtenstionsArray.includes(extension)){
					imgsToLoad.push(asset);
				} else if (audioExtenstionsArray.includes(extension)){
					audiosToLoad.push(asset);
				}
			}
			let _promises = new Array<any>();
			const totalLength = imgsToLoad.length + audiosToLoad.length;
			if(this.config.preloadImg){
				if (imgsToLoad.length > 0){
					_promises.push(this.preloadService.preloadImgs(imgsToLoad, totalLength));
				}
				if(audiosToLoad.length > 0){
					_promises.push(this.preloadService.preloadAudios(audiosToLoad, totalLength));
				}
				return Promise.all(_promises);
			}
			else{
				return new Promise((resolve, reject) => {
					setTimeout(() => { resolve(false);}, this.config.mockTimePreloadImg);
				});
			}
		})
		//goto landing page
		.then(resp => {
			if(this.config.timeLandingPage > 0){
				if(this.config.navigateIntro) router.navigate('landing');
				return new Promise((resolve, reject) => {
					setTimeout(() => { resolve(false); }, this.config.timeLandingPage);
				});
			}
			else return true;
		})
		
		//goto home page (or path in cookies)
		.then(resp => {
			
			let cookiePath:string = null;
			const mainConfig = getMainConfig();
			
			//if readonly mode (userdata selector): retrieve path saved in userdata (local storage)
			if(mainConfig.enableDebugModule && this.userdataService.getCurrentKey() !== 'n'){
				cookiePath = this.userdataService.getCurrentData().routePath;
				//remove debug part in route (not needed)
				if(cookiePath) cookiePath = cookiePath.replace(/\/debug:1\/debug-content:[\w-]+/, '');
			}

			if(!this.config.navigateIntro){
				router.init(getRouterConfig(), cookiePath);
			}
		})
		.catch((err) => {
			console.log(err);
		});
	}

	public onInit_setEvents(){
		let translationService = this.getServiceInstance<TranslationService>('translation');
		broadcaster.addListener(Evt.SELECT_LOCALE, function(locale:string)
		{
			translationService.locale = locale;
			
			broadcaster.dispatchEvent(Evt.UPDATE_FUNCTION_STATE, ['translate']);
		});
	}

	public onInit_initUserData(){
		let translationService = this.getServiceInstance<TranslationService>('translation');
		this.userdataService.init();
		translationService.locale = this.userdataService.getCurrentData().locale;
		SelectLanguageModule.locale = this.userdataService.getCurrentData().locale;
		
		if(this.config.navigateIntro){
			router.init(getRouterConfig(), 'loading');
		}
	}
}


let moduleClass:any = ApplicationModule;
try{ moduleClass = getDependency<ApplicationModule>(require('theme-iso/base/modules/application/application.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./application.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/application/application.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./application.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/application/application.module.user.scss'));} catch(e){}
injectStyle(css);

import * as configSystem from './application.config';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { SelectLanguageModule } from '../ui/select-language/select-language.module';
import { Evt } from 'theme/base/config/events';
import { PreloadService } from 'theme/base/services/preload/preload.service';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/application/application.config.user').default;} catch(e){}
config = resolveConfig('application', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./application.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/application/application.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};




registerModuleDef(moduleClass, 'application', template, config, anims, css)