import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './debug-userdata.config';
import formBinding from 'core/lib/formBinding';
import _, { uniqueId } from 'lodash';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { UserData } from 'theme/base/services/userdata/userdata.service';
import { SelectLanguageModule } from 'theme/base/modules/ui/select-language/select-language.module';
import { Evt } from 'theme/base/config/events';
import { ApiService } from 'theme/base/services/api/api.service';

export class DebugUserdataModule extends Module<typeof config>{
	
	private userdata:UserDataService;
	override onInit(): void {
		this.userdata = this.getServiceInstance<UserDataService>('userdata');
		let idSelected = this.userdata.getCurrentKey();
		
		this.attrBinding.flagInsert = false;
		this.attrBinding.isMainSave = (idSelected === 'n');
		this.attrBinding.msgConfirm = null;
		
		let data = this.userdata.getCurrentData();
		let saves = this.refresh(idSelected === 'n' ? null : data);
		let activeSave = saves.find(saveItem => saveItem.id === idSelected)
		if(activeSave) activeSave.activeItem = true;
		this.attrBinding.saves = saves;
	}
	
	
	private getData(userDatas:{[name:string]:UserData}):SaveItem[]
	{
		let output = [];
		for(let key in userDatas){
			let userData = userDatas[key];
			output.push({
				id:key,
				title: userData.saveTitle,
				desc: userData.saveDesc,
				saveType: userData.saveType,
				activeItem:false,
				timestamp: key === 'n' ? Number.POSITIVE_INFINITY : userData.saveTimestamp
			});
		}
		output.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);
		return output;
	}
	
	
	clickUpdate():void
	{
		let key = this.userdata.getCurrentKey();
		let data = this.userdata.getCurrentData();
		if(window.confirm(`Do you want to update\n"${data.saveTitle}" (id "${key}") ?`)){
			
			let formData = this.getFormData(false);
			if(formData){
				data.saveTitle = formData.title;
				data.saveDesc = formData.desc;
				this.userdata.save(key, data);
				this.saveWS(key, data);
				this.attrBinding.msgConfirm = `Save updated ("${key}")`;
				
				//reload list
				this.attrBinding.saves = this.refresh(data);
				
			}
		}
	}
	
	
	clickAddSave(type:string):void
	{
		let formData = this.getFormData(type === 'previous');
		
		//if okay
		if(formData){
			let newkey = this.uniqid('save-'+type+'-');
			let n = type === 'previous' ? -2 : 0;
			let data = this.userdata.getCurrentData(n);
			let newdata = this.userdata.saveInstant(newkey, formData.title, formData.desc, type, data);
			this.attrBinding.flagInsert = true;
			this.attrBinding.msgConfirm = `Save added ("${type}")`;
			
			
			//reload list
			let currentKey = this.userdata.getCurrentKey();
			this.attrBinding.saves = this.refresh(currentKey !== 'n' ? data : null);
			
			this.saveWS(newkey, newdata);
		}
	}
	
	clickDelete():void
	{
		let key = this.userdata.getCurrentKey();
		let data = this.userdata.getCurrentData();
		if(window.confirm(`Do you want to delete\n"${data.saveTitle}" (id "${key}") ?`)){
			
			//delete locally
			this.userdata.delete(key);
				
			this.userdata.updateCurrent('n');	//fallback to save n
			this.attrBinding.saves = this.refresh(null);
			this.attrBinding.isMainSave = true;
			
			//trigger changes
			this.triggerChanges();
			
			//delete with webservice
			let apiService = this.getServiceInstance<ApiService>('api');
			apiService.query('delete', 'api/shared_userdata', {
				uid:key
			});
			
			this.attrBinding.msgConfirm = `Save deleted ("${key}")`;
		}
	}
	
	private refresh(data:UserData):SaveItem[]
	{
		//update form values
		this.attrBinding.formTitle = data ? data.saveTitle : null;
		this.attrBinding.formDesc = data ? data.saveDesc : null;
		
		//reload list
		let userDatas = this.userdata.getListUserData();
		this.attrBinding.selectedId = this.userdata.getCurrentKey();
		return this.getData(userDatas);
	}
	
	
	private saveWS(id:string, data:any):void
	{
		let apiService = this.getServiceInstance<ApiService>('api');
		apiService.query('put', 'api/shared_userdata', {
			uid:id, data
		});
	}
	
	
	
	
	private getFormData(requireDesc:boolean):any
	{
		const containerForm = <HTMLElement>this.dom.querySelector('.header');
		const formData = formBinding.getData(containerForm);
		
		//for bug declaration : we require desc to be filled
		let missingFields = [];
		if(!formData.title) missingFields.push('Title');
		if(requireDesc && !formData.desc){
			missingFields.push('Description (you must fill the reproduction steps when declaring a bug)');
		}
		//if error validation
		if(missingFields.length){
			alert('Please fill in the following fields : \n- '+missingFields.join('\n- '));
			return null;
		}
		return formData;
	}
	
	
	private toogleItem(index:number):void
	{
		let item = this.attrBinding.saves[index];
		if(!item.activeItem){
			for(let i in this.attrBinding.saves){
				this.attrBinding.saves[i].activeItem = false;
			}
		}
		item.activeItem = !item.activeItem;
		
		this.attrBinding.saves = this.attrBinding.saves;
	}
	
	private uniqid(prefix = "", random = true) {
		const sec = Date.now() * 1000 + Math.random() * 1000;
		const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
		return `${prefix}${id}${random ? `${Math.trunc(Math.random() * 100000000)}`:""}`;
	}
	
	
	
	private clickLoad(mode:string, id:string, index:number):void
	{
		//read only
		let data = null;
		if(mode === 'readonly'){
			this.userdata.reloadCurrentData(id);
			this.userdata.updateCurrent(id);
			data = this.userdata.getCurrentData();
			if(id === 'n') data = null;
		}
		//main save
		else if(mode === 'main'){
			//save {id} must override save n
			let userdata = this.userdata.get(id);
			userdata = _.cloneDeep(userdata)
			
			userdata.saveDesc = `From : ${userdata.saveTitle}`;
			userdata.saveTitle = 'main save (n)';
			
			this.userdata.save('n', userdata);
			this.userdata.updateCurrent('n');
		}
		
		let saves = this.refresh(data);
		
		let idSelected = mode === 'main' ? 'n' : id;
		let isMainSave = (idSelected === 'n');
		this.attrBinding.isMainSave = isMainSave;
		this.attrBinding.formEnabled = !isMainSave;
		
		saves.find(saveItem => saveItem.id === idSelected).activeItem = true;
		this.attrBinding.saves = saves;
		
		
		this.triggerChanges();
	}
	
	
	private triggerChanges():void
	{
		//trigger route
		let path = '/' + (true ? '#/' : '') + this.userdata.getCurrentData().routePath;
		let url = location.pathname + location.search + path;
		if(url.substr(0, 2) === '//') url = url.substr(1);
		window.history.pushState(null, '', url);
		router.onNavigate(null, true);
		
		//trigger lang change
		SelectLanguageModule.locale = this.userdata.getCurrentData().locale;
		broadcaster.dispatchEvent(Evt.SELECT_LOCALE, [SelectLanguageModule.locale]);
		
	}
}


interface SaveItem{
	title:string,
	desc:string,
	saveType:string,
	activeItem:boolean,
	id:string,
	timestamp:number,
}



let moduleClass:any = DebugUserdataModule;
try{ moduleClass = getDependency<DebugUserdataModule>(require('theme-iso/base/modules/debug/submodules/debug-userdata/debug-userdata.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./debug-userdata.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-userdata/debug-userdata.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./debug-userdata.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/debug/submodules/debug-userdata/debug-userdata.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/debug/submodules/debug-userdata/debug-userdata.config.user').default;} catch(e){}
config = resolveConfig('debug-userdata', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./debug-userdata.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/debug/submodules/debug-userdata/debug-userdata.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'debug-userdata', template, config, anims, css)