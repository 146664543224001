export default {
	
	//default config
	default : {
		baseUrl : 'https://moteur.dowino.com/',
		defaultUID : 'upwjtUqBLS3FDo0wL79Ns8mTQVGxU3ql',
		mock: {
			'api/get_data:post' : null,
			'api/preloadList:post' : null,
		}
		
	},

	dev: {
		/* 
		mock : {
			'api/get_data:get' : "theme-iso/base/assets/mock/getdata.mock.json",
			'api/get_preload_list:get' : "theme-iso/base/assets/mock/get-preload-list.mock.json",
		}
		 */
	}
}