export function getCSSTemplate(obj:any):string
{
	return obj.default.toString();
}

export function injectStyle(cssContent:string):void
{
	let head = document.head || document.getElementsByTagName('head')[0];
	let style = document.createElement('style');
	head.appendChild(style);
	style.type = 'text/css';
	style.appendChild(document.createTextNode(cssContent));
}