export enum Evt{
	
	SELECT_LOCALE = "select_locale",
	UPDATE_FUNCTION_STATE = "update_function_state",
	TEST = "test",
	
	FRONT_COMMAND = 'front_command',
	CHARTFLOW_CONTINUE = 'chartflow_continue',
	CHARTFLOW_JUMP = 'chartflow_jump',
	SET_VARIABLE = 'set_variable'
}