export class FormBinding{

	
	/**
	 * return all the input values from an html form (or container)
	 * todo : only optimized for text inputs at the moment
	 */
	public getData(form:HTMLElement):any
	{
		let output:any = {};
		let inputs = form.querySelectorAll('input');
		inputs.forEach(input => {
			output[input.name] = input.value;
		});
		let textareas = form.querySelectorAll('textarea');
		textareas.forEach(textarea => {
			output[textarea.name] = textarea.value;
		});
		let selects = form.querySelectorAll('select');
		selects.forEach(select => {
			output[select.name] = select.value;
		});
		return output;
	}
	
	
	/**
	 * fill inputs of an html form with provided data
	 */
	public updateView(form:HTMLElement, data:any):void
	{
		for(let k in data){
			let input = form.querySelector('input[name='+k+']');
			if(input && input instanceof HTMLInputElement){
				(<HTMLInputElement>input).value = data[k];
			}
		}
	}
}


const formBinding = new FormBinding();
export default formBinding;