/**
 * Dowimoteur
 * 
 * Base module "media"
 * Created on Feb 1, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './media.config';
import { Command } from 'theme/base/services/flowchart/flowchart.service';
import { Evt } from 'theme/base/config/events';

export class MediaModule extends Module<typeof config>{
	
	override onInit(): void
	{
		
	}

	public update(text:string, container: string, transcript: string, media: string):void
	{
		this.attrBinding.type = 'btn';
		this.attrBinding.text = text;
		this.attrBinding.container = container;
		this.attrBinding.transcript = transcript;
		this.attrBinding.media = media;
	}

	public continue():void
	{
		this.attrBinding.type = 'none';
		let command:Command = { module: 'media', function: 'hide', args:[]};
		
		broadcaster.dispatchEvent(Evt.FRONT_COMMAND, [command]);
		broadcaster.dispatchEvent(Evt.CHARTFLOW_CONTINUE, [-1]);
	}
}



let moduleClass:any = MediaModule;
try{ moduleClass = getDependency<MediaModule>(require('theme-iso/base/services/media/media.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./media.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/services/media/media.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./media.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/services/media/media.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/media/media.config.user').default;} catch(e){}
config = resolveConfig('media', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./media.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/services/media/media.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'media', template, config, anims, css)