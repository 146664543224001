import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './dialog-npc.config';
import { TranslationService } from 'theme/base/services/translation/translation.service';

export class DialogNpcModule extends Module<typeof config>{
	
	protected translate:TranslationService;
	
	override onInit(): void 
	{
		this.translate = this.getServiceInstance<TranslationService>('translation');
		
		
		
	}
	
	public update(talker:string, emote:string, content:string, container:string):void
	{
		this.attrBinding.content = content;
		this.attrBinding.bgClass = container;
		
		this.attrBinding.colorName = this.translate.translate('ui.color.' + talker);
		this.attrBinding.name = this.translate.translate('ui.name.' + talker);
	}
	
	
	scrollDown():void
	{
		
	}
	scrollUp():void
	{
		
	}
}



let moduleClass:any = DialogNpcModule;
try{ moduleClass = getDependency<DialogNpcModule>(require('theme-iso/packages/dialog/dialog-npc/dialog-npc.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./dialog-npc.module.html'));
try{ template = getDependency<string>(require('theme-iso/packages/dialog/dialog-npc/dialog-npc.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./dialog-npc.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/packages/dialog/dialog-npc/dialog-npc.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/packages/dialog/dialog-npc/dialog-npc.config.user').default;} catch(e){}
config = resolveConfig('dialog-npc', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./dialog-npc.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/packages/dialog/dialog-npc/dialog-npc.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'dialog-npc', template, config, anims, css)