import gsap from 'gsap';
export default {
	
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('.header-home', {marginTop:-200}, {marginTop:58, duration:0.45, ease: "back.out(2.0)"})
		.fromTo('.container .btn:nth-child(1)', {scale:0.3, opacity:0}, {scale:1, opacity:1, duration:0.23, ease: "back.out(1.2)"}, '>-0.1')
		.fromTo('.container .btn:nth-child(2)', {scale:0.3, opacity:0}, {scale:1,opacity:1, duration:0.23, ease: "back.out(1.2)"}, '>-0.1')
		.fromTo('.container .btn:nth-child(3)', {scale:0.3, opacity:0}, {scale:1, opacity:1, duration:0.23, ease: "back.out(1.2)"}, '>-0.1')
		.fromTo('.container .btn:nth-child(4)', {scale:0.3, opacity:0}, {scale:1, opacity:1, duration:0.23, ease: "back.out(1.2)"}, '>-0.1')
		.fromTo('.container .btn:nth-child(5)', {scale:0.3, opacity:0}, {scale:1, opacity:1, duration:0.23, ease: "back.out(1.2)"}, '>-0.1'	)
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
	
	
}