/**
 * Dowimoteur
 * 
 * Base service "prompt"
 * Created on Jan 31, 2024
 * 
 */

import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency, getModuleDefByID, ModuleDef, ModuleInstance, registerModuleClass } from 'core/lib/core';
import { TranslationService } from 'theme/base/services/translation/translation.service';

registerModuleClass([
	PromptModule
]);

export class PromptService extends Service<typeof config>{
	
	protected translationService: TranslationService;
	protected mainConfig = getMainConfig();
	protected moduleHandler:ModuleHandler;
	protected viewportPrompt:HTMLElement;
	protected modulePrompt:ModuleInstance;
	protected promptInst:PromptModule;
	protected modulePromptDef:ModuleDef;
	protected container: string;

	override onInit(): void
	{
		this.translationService = this.getServiceInstance<TranslationService>('translation');
		this.viewportPrompt = <HTMLElement>document.querySelector('.prompt-viewport');
		this.moduleHandler = new ModuleHandler();
	}

	public reset(viewportPrompt:HTMLElement):void
	{
		this.viewportPrompt = viewportPrompt;

		this.modulePromptDef = getModuleDefByID('prompt');
		this.modulePrompt = this.moduleHandler.instanciateModule(this.modulePromptDef.class);
		this.viewportPrompt.appendChild(this.modulePrompt.dom);
		this.promptInst = (<PromptModule>this.modulePrompt.classInstance);
		
	}

	public setPrompt(container: string, wording: string, content: string){

		let translatedText = this.translationService.translate(wording);
		if(!translatedText || (this.mainConfig.checkRuntimeErrors && translatedText === wording)) translatedText = '_' + content;
		if(!container) container = this.config.defaultContainer;

		//animation

		let func;
		if (container == "prompt-ellipsis") {
			func = this.modulePromptDef.anims['ellipsis_in'];
		} else {
			func = this.modulePromptDef.anims['transition_in'];
		}

		let t:GSAPTimeline = func(this.promptInst.dom);
		t.eventCallback('onStart', () => {
			this.promptInst.update(translatedText, container);
		})
		animator.addTimeline(t);

		this.viewportPrompt.style.pointerEvents = 'all';

		this.container = container; //used for hide function
		
		return 'prompt';
		
	}

	public hide():string
	{
		//animation
		let func;
		if (this.container == "prompt-ellipsis") {
			func = this.modulePromptDef.anims['ellipsis_out'];
		} else {
			func = this.modulePromptDef.anims['transition_out'];
		}
		let t = func(this.promptInst.dom);
		animator.addTimeline(t);

		this.viewportPrompt.style.pointerEvents = 'none';
		
		return 'prompt';
	}

	public destroy(){
		this.viewportPrompt.removeChild(this.modulePrompt.dom);
		this.moduleHandler.destroyModule(this.modulePrompt);
	}
}



let serviceClass:any = PromptService;
try{ serviceClass = getDependency<PromptService>(require('theme-iso/base/services/prompt/prompt.service.user.ts')); }catch(e){}


import * as configSystem from './prompt.config';
import { PromptModule } from './prompt.module';
import { ModuleHandler } from 'core/lib/modules';
import animator from 'core/lib/animator';
import broadcaster from 'core/lib/broadcaster';
import { Evt } from 'theme/base/config/events';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/prompt/prompt.config.user').default;} catch(e){}
config = resolveConfig('prompt', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'prompt', config)
