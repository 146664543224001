import { registerServiceDef, Service } from 'core/lib/services';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import { getDependency, getModuleDefByID, ModuleDef, ModuleInstance, registerModuleClass } from 'core/lib/core';
import gsap from 'gsap';
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);
registerModuleClass([
	DialogFooterModule,
	DialogNpcModule,
	NpcModule
]);


export class DialogService extends Service<typeof config>{
	
	protected moduleHandler:ModuleHandler;
	
	protected viewportDialog:HTMLElement;
	protected viewportNpcs:HTMLElement;
	
	protected moduleFooter:ModuleInstance;
	
	protected moduleFooterInst:DialogFooterModule;
	protected npcDialogs:{[key:string] : DialogNpcModule};
	protected npcs:{[key:string] : NpcModule};

	
	protected translationService:TranslationService;
	
	protected moduleNpcDialogDef:ModuleDef;
	protected moduleFooterDef:ModuleDef;
	protected moduleNpcDef:ModuleDef;
	
	protected npcWidthMax:number = 0;
	protected npcHeightMax:number = 0;
	protected npcCurrentPositions:{[key:string]:string};
	protected npcCurrentEmotes:{[key:string]:string};
	protected theme:string;
	protected refonFooterContinue:Function;
	
	protected npcDialogVisible:{[name:string]:boolean};
	protected mainConfig = getMainConfig();
	
	
	/**
	 * method called on service creation (only once)
	 */
	public onInit():void
	{
		this.moduleHandler = new ModuleHandler();
		this.translationService = this.getServiceInstance<TranslationService>('translation');
		this.theme = process.env.THEME;
		
		this.moduleNpcDialogDef = getModuleDefByID('dialog-npc');
		// this.moduleBtnNextDef = getModuleDefByID('dialog-btn-next');
		this.moduleFooterDef = getModuleDefByID('dialog-footer');
		this.moduleNpcDef = getModuleDefByID('npc');
		//this.moduleEllipsisDef = getModuleDefByID('ellipsis');
		
		
		//add id to npc position
		for(let k in this.config.npcPositions){
			(<NpcPosition>(<any>this.config.npcPositions)[k]).id = k;
		}
		
		
		//validate animations files
		if(this.mainConfig.checkRuntimeErrors){
			
			let errors = [];
			errors.push(...animator.getErrors(this.moduleNpcDialogDef.anims, ['in', 'out'], ['dom:HTMLElement'], 'dialog-npc.animations.ts'));
			// errors.push(...animator.getErrors(this.moduleBtnNextDef.anims, ['in', 'out'], ['dom:HTMLElement'], 'dialog-btn-next.animations.ts'));
			errors.push(...animator.getErrors(this.moduleFooterDef.anims, ['in', 'out'], ['dom:HTMLElement', 'string'], 'dialog-footer.animations.ts'));
			errors.push(...animator.getErrors(this.moduleNpcDef.anims, ['in', 'out'], ['dom:HTMLElement', 'position:NpcPosition', 'npc:string', 'emote:string', 'offset:any'], 'npc.animations.ts'));
			//errors.push(...animator.getErrors(this.moduleEllipsisDef.anims, ['in', 'out'], ['dom:HTMLElement'], 'ellipsis.animations.ts'));
			if(errors.length) throw new Error(animator.formatError(errors));
			
		}
	}
	
	
	/**
	 * method called every time game module is created
	 * (whenever we navigate to page game)
	 */
	public reset(viewportDialog:HTMLElement, viewportNpcs:HTMLElement, playerName:string):void
	{
		this.viewportDialog = viewportDialog;
		this.viewportNpcs = viewportNpcs;
		
		this.npcs = {};
		this.npcDialogs = {};
		this.npcCurrentPositions = {};
		this.npcCurrentEmotes = {};
		this.npcDialogVisible = {};
		
		//footer dialog
		this.moduleFooter = this.moduleHandler.instanciateModule(this.moduleFooterDef.class);
		this.viewportDialog.appendChild(this.moduleFooter.dom);
		this.moduleFooterInst = (<DialogFooterModule>this.moduleFooter.classInstance);
		this.moduleFooterInst.init(this.theme, playerName);
		
		
		this.refonFooterContinue = this.onFooterContinue.bind(this);
		broadcaster.addListener('DIALOG_FOOTER_VALIDATE', this.refonFooterContinue);
		
	}
	
	public destroy():void
	{
		this.viewportDialog.removeChild(this.moduleFooter.dom);
		this.moduleHandler.destroyModule(this.moduleFooter);
		
		//todo : npcs, npcDialogs ?
		for(let k in this.npcs){
			this.viewportNpcs.removeChild(this.npcs[k].dom);
			this.moduleHandler.destroyModule(this.npcs[k].moduleInstance);
		}
		for(let k in this.npcDialogs){
			this.viewportDialog.removeChild(this.npcDialogs[k].dom);
			this.moduleHandler.destroyModule(this.npcDialogs[k].moduleInstance);
		}
		
		broadcaster.removeListener('DIALOG_FOOTER_VALIDATE', this.refonFooterContinue);
		
	}
	
	
	/**
	 * we initiate (lazily) 1 container per npc
	 * this way, we're not limited to how many we can display simultaneously
	 * 
	 */
	
	public npcAction(npc:string, emote:string, action:NpcAction, position:string):any
	{
		//lazy dom init
		var dom = this.npcAction_lazydominit(npc);
		
		//update with correct emote (only if changed)
		this.npcAction_updateEmote(npc, emote);
		
		//define if "in" is a transition or a in
		let action2 = (action === 'in' && this.npcCurrentPositions[npc]) ? 'transition' : action;
		
		//if out, reuse saved position for this npc
		if(action === 'out') position = this.npcCurrentPositions[npc];
		
		if(!position){
			console.warn(`position not defined for npcAction(${npc}, ${emote}, ${action}), using default value 1`);
			position = this.config.npcPositionDefault;
		}
		
		
		//animation
		this.npcAction_animation(position, action2, npc, emote, dom);
		
		this.npcCurrentPositions[npc] = (action !== 'out') ? position : null;
		
		//state hash
		let type = action2 === 'out' ? 'delete' : 'add';
		return{ hash: `npcAction_${position}`, type };
		
	}
	
	
	
	public npcTalkHide(container:string):{hash:string, type:string}
	{
		if(!container) container = this.config.npcDialogContainerDefault;
		
		let func = this.moduleNpcDialogDef.anims['out'];
		let t:GSAPTimeline = func(this.npcDialogs[container].dom);
		animator.addTimeline(t);
		this.npcDialogVisible[container] = false;
		
		return{ hash: `npcTalk_${container}`, type: 'delete'};
	}
	
	
	
	public npcTalk(talker:string, emote:string, wording:string, text:string, container:string, speed:number, waitUserInput:boolean):string
	{
		var content = this.npcTalk_start(container, wording, text);
		
		//lazy dom init
		var dom = this.npcTalk_lazydominit(container);

		//animation
		this.npcTalk_animation(container, dom, content, speed, talker, emote);
		
		this.npcDialogVisible[container] = true;
		
		//display footer btn (if waitUserInput)
		if(waitUserInput){
					
			let func = this.moduleFooterDef.anims['in'];
			let t:GSAPTimeline = func(this.moduleFooterInst.dom, 'btn');
			t.eventCallback('onStart', () => {
				this.moduleFooterInst.displayBtn();
				
			});
			animator.addTimeline(t);
		}

		this.npcTalk_end();
		
		//state hash
		return `npcTalk_${container}`;
	}
	
	
	public playerTalk(type:PlayerTalkType, wording:string, text:string):string
	{
		var content = this.playerTalk_start(wording, text);
		
		let func = this.moduleFooterDef.anims['in'];
		let t:GSAPTimeline = func(this.moduleFooterInst.dom, type);
		t.eventCallback('onStart', () => {
			this.moduleFooterInst.update(type, content);
		});
		animator.addTimeline(t);

		this.playerTalk_end();
		
		//state hash
		return 'playerTalk';
	}
	
	
	
	public playerTalkChoices(list:InteractiveListItem[]):string
	{
		var choices = this.playerTalkChoices_start(list);

		//animation
		let func = this.moduleFooterDef.anims['in'];
		let t:GSAPTimeline = func(this.moduleFooterInst.dom, 'choice');
		t.eventCallback('onStart', () => this.moduleFooterInst.updateChoices(choices));
		animator.addTimeline(t);

		this.playerTalkChoices_end();
		
		//state hash
		return 'playerTalk';
	}
	
	
	protected onFooterContinue(selectedIndex:number = -1):void
	{
		//we send a command that we trigger manually
		//this behaviour is exceptional, usually, commands are written in the flowchart,
		//the reason why it has to go through the command system is to comply with the flowchartState mechanism (resync at init)
		
		let command:Command;
		command = { module: 'dialog', function: 'playerTalkHide', args:[]};
		
		broadcaster.dispatchEvent(Evt.FRONT_COMMAND, [command]);
		broadcaster.dispatchEvent(Evt.CHARTFLOW_CONTINUE, [selectedIndex]);
		
	}
	
	
	public playerTalkHide():string
	{
		let func = this.moduleFooterDef.anims['out'];
		let t:GSAPTimeline = func(this.moduleFooterInst.dom);
		animator.addTimeline(t);
		return 'playerTalk';
	}
	
	public npcTalk_start(container: string, wording: string, text: string) {
		if(!container) container = this.config.npcDialogContainerDefault;
		
		let content = this.translationService.translate(wording);
		if(!content || (this.mainConfig.checkRuntimeErrors && content === wording)) content = '_' + text;

		return content;
	}

	public npcTalk_lazydominit(container:string): HTMLElement{
		let dom:HTMLElement;
		if(!this.npcDialogs[container]){
			let npcDialogModule = this.moduleHandler.instanciateModule(this.moduleNpcDialogDef.class);
			this.viewportDialog.insertBefore(npcDialogModule.dom, this.viewportDialog.children[0]);
			this.npcDialogs[container] = (<DialogNpcModule>npcDialogModule.classInstance);
			
			dom = this.npcDialogs[container].dom;
			dom.style.opacity = '0';
		}
		else dom = this.npcDialogs[container].dom;

		return dom;
	}

	public npcTalk_animation(container:string, dom:HTMLElement, content:string, speed: number, talker:string, emote:string){

		let wordsTlDurationConst = this.config.npcTalk.wordsTlDurationConst;
		let wordsTlStaggerConst = this.config.npcTalk.wordsTlStaggerConst;
		//if npc dialog visible => hide before show
		let func;
		let t:GSAPTimeline = new gsap.core.Timeline();
		
		if(this.npcDialogVisible[container]){
			func = this.moduleNpcDialogDef.anims['out'];
			let tOut:GSAPTimeline = func(dom);
			//we must update info only between out and in animations
			t.add(tOut);
		}
		

		//display npc dialog
		func = this.moduleNpcDialogDef.anims['in'];
		let tIn:GSAPTimeline = func(dom);

		// we create a div with the current text before the main timeline callback is made to set the delay
		//at the end of the main timeline to prevent conflicts with the flowchartService (which sets animations faster than the animations are executed)
		//the delay must be set at the end of the timeline
		var futurContentElement = <HTMLDivElement>(document.createElement('div'));
		futurContentElement.innerHTML = content;
		var contentWordsLength = new SplitText(futurContentElement, { type: "words" }).words.length;
		tIn.set({}, {}, "+="+(contentWordsLength*wordsTlStaggerConst*speed));

		t.add(tIn);
		
		t.eventCallback('onStart', () => {
			this.npcDialogs[container].update(talker, emote, content, container);

			let wordsTl:GSAPTimeline = new gsap.core.Timeline();
			let textBulleDiv = <HTMLElement>dom.querySelector('.content');
			var splitTextObject = new SplitText(textBulleDiv, { type: "words" });
			var wordsArray = splitTextObject.words;
			wordsTl.from(wordsArray, {
				opacity: 0,
				duration: wordsTlDurationConst,
				stagger: wordsTlStaggerConst * speed
			});

			tIn.add(wordsTl, "-="+(contentWordsLength*wordsTlStaggerConst*speed));
		});

		animator.addTimeline(t);
	}

	public npcTalk_end() {}

	public playerTalkChoices_start(list:InteractiveListItem[]): InteractiveListItem[] {
		let choices:any[] = [];
		for(let k in list){
			let content = this.translationService.translate(list[k].wordingkey);
			if(!content || (this.mainConfig.checkRuntimeErrors && content === list[k].wordingkey)) content = '_' + list[k].text;
			choices.push({
				indexPrefilter:list[k].indexPrefilter, text:content, condition:null, wordingkey:null,
			});
		}

		return choices;
	}

	public playerTalkChoices_end() {}

	public playerTalk_start(wording: string, text: string): string {
		let content = this.translationService.translate(wording);
		if(!content || (this.mainConfig.checkRuntimeErrors && content === wording)) content = '_' + text;

		return content;
	}

	public playerTalk_end() {}

	public npcAction_lazydominit(npc:string): HTMLElement {
		let dom:HTMLElement;
		if(!this.npcs[npc]){
			let npcModule = this.moduleHandler.instanciateModule(this.moduleNpcDef.class);
			this.viewportNpcs.appendChild(npcModule.dom);
			this.npcs[npc] = (<NpcModule>npcModule.classInstance);
			
			dom = this.npcs[npc].dom;
			dom.style.opacity = '0';
			
			this.npcCurrentPositions[npc] = null;
		}
		else dom = this.npcs[npc].dom;

		return dom;
	}

	public npcAction_updateEmote(npc: string, emote: string){
		//update with correct emote (only if changed)
		if(emote && this.npcCurrentEmotes[npc] !== emote){
			this.npcs[npc].update(this.theme, npc, emote);
			this.npcCurrentEmotes[npc] = emote;
		}
	}

	public npcAction_animation(position:string, action2: string, npc:string, emote:string, dom:HTMLElement){
		let func = this.moduleNpcDef.anims[action2];
		let npcPosition:NpcPosition = (<any>this.config.npcPositions)[position];

		// manage offset
		var offsetArray = [0, 0];
		if((<any>this.config.npcOffset)[npc]){
			var offsetX = (<any>this.config.npcOffset)[npc].x;
			var offsetY = (<any>this.config.npcOffset)[npc].y;
			offsetArray = [offsetX, offsetY];
		}
		
		
		if(!npcPosition) throw new Error(`npcPosition "${position}" doesnt exist`);
		let timeline:GSAPTimeline;
		if(action2 === 'transition'){
			let npcPositionPrev:NpcPosition = (<any>this.config.npcPositions)[this.npcCurrentPositions[npc]];
			timeline = func(dom, npcPositionPrev, npcPosition, npc, emote, offsetArray);
		}
		else timeline = func(dom, npcPosition, npc, emote, offsetArray);
		animator.addTimeline(timeline);
	}
	
}





export interface NpcPosition{
	id:string,
	left:number,
	top:number,
	bottom:number,
	right:number
	
}



let serviceClass:any = DialogService;
try{ serviceClass = getDependency<DialogService>(require('theme-iso/packages/dialog/dialog.service.user.ts')); }catch(e){}

import * as configSystem from './dialog.config';
import { ModuleHandler } from 'core/lib/modules';
import { DialogFooterModule } from './dialog-footer/dialog-footer.module';
import { Command, InteractiveListItem, NpcAction, PlayerTalkType } from 'theme/base/services/flowchart/flowchart.service';
import { DialogNpcModule } from './dialog-npc/dialog-npc.module';
import broadcaster from 'core/lib/broadcaster';
import { TranslationService } from 'theme/base/services/translation/translation.service';
import { NpcModule } from './npc/npc.module';
import animator from 'core/lib/animator';
import { Evt } from 'theme/base/config/events';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/packages/dialog/dialog.config.user').default;} catch(e){}
config = resolveConfig('dialog', configSystem.default, configUser, process.env.RUNTIME_ENV)


registerServiceDef(serviceClass, 'dialog', config)
