import gsap from 'gsap';
export default {
	
	transition_in:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline()
		.fromTo('h1', {opacity:0, y:100}, {opacity:1, y:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('input[type="text"]', {opacity:0, y:100}, {opacity:1, y:0, duration:0.35, ease: "back.out(1.5)"}, '>-0.15')
		.fromTo('h2', {opacity:0, y:100}, {opacity:1, y:0, duration:0.3, ease: "back.out(1.5)"}, '>-0.2')
		.fromTo('.btns', {opacity:0, y:100}, {opacity:1, y:0, duration:0.3, ease: "back.out(1.5)"}, '>-0.2')
		.fromTo('.legend', {opacity:0, y:100}, {opacity:1, y:0, duration:0.3, ease: "back.out(1.5)"}, '>-0.2')
		.fromTo('button.validate', {y: 200}, {y: 0, duration:0.43, ease: "back.out(2.5)"}, '>+0.02')
		;
	},
	
	
	transition_out:(dom:HTMLElement):GSAPTimeline =>{
		
		return new gsap.core.Timeline();
	}
}