export default {
	
	default : {
		
		npcPositions:{
			'1' : {bottom:0, right:145},
			'2' : {bottom:0, right:-80},
			'3' : {bottom: 0, left: 20},
			'4' : {top: 0, left: 20},
			'5' : {top: 0, right: 140},
			
		},
		npcPositionDefault: '1',
		npcDialogContainerDefault: 'bulle1',

		npcTalk: {
			wordsTlDurationConst: 0.005,
			wordsTlStaggerConst: 0.05
		},
		
		npcOffset: {
			'npc1': {x: 500, y:0},
		}
		
	},
	
	dev:{
		
	}
}