export default {
	
	//default config
	default : {
		_routes : [
			//root
			
			{	path : 'landing', module : 'landing'},
			{	path : 'loading', module : 'loading'},
			{ path : 'home', module : 'home'},
			{	path : 'game', module : 'game'},
			{	path : 'settings', module : 'settings', outlet: 'modal'}
		],
		defaultPath : 'loading',
		enablePushState: false,
	},
	
	
	dev:{
		_routes:[
			{path : '1', module : 'debug', outlet: 'debug'},
			{path : 'router', module : 'debug-router', outlet: 'debug-content'},
			{path : 'audio', module : 'debug-audio', outlet: 'debug-content'},
			{path : 'userdata-selector', module : 'debug-userdata', outlet: 'debug-content'},
			{path : 'userdata-editor', module : 'debug-userdata-editor', outlet: 'debug-content'},
			{path : 'gsap', module : 'debug-gsap', outlet: 'debug-content'}
		],
		enablePushState: true,
	},
	
}