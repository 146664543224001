export default {
	
	default : {
		
		//default values used for targets commands (like setvariable)
		//(because the flowchart doesn't send anything there)
		chainingParams:{
			global:{delay:0.2},
			setvariable:{delay:0.2},
		}
		
	},
}