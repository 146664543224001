import gsap from 'gsap';
import { FooterType } from './dialog-footer.module';
export default {
	
	in:(dom:HTMLElement, type:FooterType):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline({defaults:{immediateRender: false}});
		t.fromTo(dom, 
			{opacity:0, top:230},
			{opacity:1, top:0, duration:0.3}
		);
		return t;
	},
	
	
	out:(dom:HTMLElement, type:FooterType):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		t.to(dom, 
			{opacity:0, top:230, duration:0.2}
		);
		return t;
	},
}