export default {
	
	//default config
	default : {
		//"test":"hello2",
		param1:"hello",
		param2:"world",
	},
	
	//dev
	dev : {
		param2:"wolrd dev",
	},
	
	
	//prod
	prod : {
		param2:"wolrd prod",
	}
}