/**
 * Dowimoteur
 * 
 * Base module "setup-round"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './setup-round.config';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';

export class SetupRoundModule extends Module<typeof config>{
	
	override onInit(): void
	{
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		let game = userdata.getCurrentGame();
		this.attrBinding.roundIndex = game.roundIndex;
		
		let gameService = this.getServiceInstance<GameService>('game');
		this.attrBinding.cards = gameService.getCardsRound(game.roundIndex);
		this.attrBinding.descKey = gameService.isCollectiveRound(game.roundIndex) ? 'ui.collective.setup.instructions' : 'ui.individual.setup.instructions';
		
		/* 
		if(getMainConfig().debugMode){
			this.attrBinding.cards = [
				{id: 'test'},
				{id: 'test'},
				{id: 'test'},
				{id: 'test'},
				{id: 'test'},
				{id: 'test'},
			];
		}
		 */
		let nbcards = this.attrBinding.cards.length;
		
		if(nbcards > 8) this.attrBinding.widthContainer = 1360;
		else if(nbcards > 6) this.attrBinding.widthContainer = 1160;
		else this.attrBinding.widthContainer = 1000;
		
		this.updateLoop('cards');
	}
	
	clickValidate():void
	{
		router.navigate('card-choice');
	}
}



let moduleClass:any = SetupRoundModule;
try{ moduleClass = getDependency<SetupRoundModule>(require('theme-iso/base/modules/pages/menu/setup-round/setup-round.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./setup-round.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/menu/setup-round/setup-round.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./setup-round.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/menu/setup-round/setup-round.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/menu/setup-round/setup-round.config.user').default;} catch(e){}
config = resolveConfig('setup-round', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./setup-round.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/menu/setup-round/setup-round.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'setup-round', template, config, anims, css)