/**
 * Dowimoteur
 * 
 * Base module "help"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './help.config';
import { PedagogicalContent } from 'theme-iso/base/interface/interfaces';
import { dataGlobalHelp } from './help.global';
import { TranslationService } from 'theme/base/services/translation/translation.service';
import gsap from 'gsap';
import { PipeService } from 'theme/base/services/pipes/pipes.service';


export class HelpModule extends Module<typeof config>{
	
	private indexSlide:number = 0;
	
	//after selection scenario
	private contentTuto:{text:string}[] = [
		{text: 'ui.tuto.rules.1'},
		{text: 'ui.tuto.rules.2'},
		{text: 'ui.tuto.rules.3'},
	];
	//before selection scenario
	private contentIntro:{text:string}[] = [
		{text: 'ui.tuto.presentation.1'},
		{text: 'ui.tuto.presentation.2'},
		{text: 'ui.tuto.presentation.3'},
	];
	
	override onInit(): void
	{
		/* 
		if(getMainConfig().debugMode){
			
			dataGlobalHelp.type = 'FEEDBACK_EDUCATION';
			dataGlobalHelp.pedagogicContents = [
				{displayText: 'ae pazoiur pasoiur pqsiuf mqslkif qsmoiurf qzpoieuf spoiduf qsuiohf lzequ friouqsyh lrfuizyqskl jhf iqzoaeyr oiqsuyef', firstIntention: 'a', displayConditions: ''},
				{displayText: 'b', firstIntention: 'b', displayConditions: ''},
			];
		}
		 */
		
		broadcaster.addListener('UPDATE_HELP', this.onUpdate.bind(this))
		this.onUpdate();
		
	}
	
	public onUpdate():void
	{
		let type = dataGlobalHelp.type;
		
		this.attrBinding.charFile = `character2.png`;
		this.attrBinding.charVisible = true;
		this.attrBinding.btnKey = 'ui.continue';
		this.attrBinding.charX = 100;
		
		
		let translate:TranslationService = this.getServiceInstance<TranslationService>('translation');
		
		this.attrBinding.costStr = translate.translate('ui.cards.cost').replace('[points]', 'XX');
		//BEFORE SELECTION SCENARIO
		
		
		if(type === 'WELCOME'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'normal';
			this.attrBinding.text = translate.translate(this.contentIntro[this.indexSlide].text);
		}
		//AFTER SELECTION SCENARIO
		else if(type === 'TUTORIAL'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'normal';
			this.attrBinding.text = translate.translate(this.contentTuto[this.indexSlide].text);
		}
		else if(type === 'FEEDBACK_EDUCATION'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'normal';
			
			this.attrBinding.charFile = dataGlobalHelp.pedagogicContents[this.indexSlide].character;
			this.attrBinding.title = translate.translate(dataGlobalHelp.pedagogicContents[this.indexSlide].firstIntention);
			this.attrBinding.text = new PipeService().bbcode(translate.translate(dataGlobalHelp.pedagogicContents[this.indexSlide].displayText));
		}
		else if(type === 'HELP_CARD_CHOICE'){
			this.attrBinding.bubble = 'card-choice-help';
			this.attrBinding.button = 'normal';
			this.attrBinding.btnKey = 'ui.close';
			this.attrBinding.charX = -30;
		}
		else if(type === 'FEEDBACK_LOCKED_ROUND'){
			this.attrBinding.bubble = 'locked';
			this.attrBinding.button = 'normal';
			this.attrBinding.btnKey = 'ui.ok';
			this.attrBinding.title = translate.translate('ui.tutorial.warning.locked');
		}
		else if(type === 'FEEDBACK_LOCKED_PARENT'){
			this.attrBinding.bubble = 'locked';
			this.attrBinding.button = 'normal';
			this.attrBinding.btnKey = 'ui.ok';
			let desc = translate.translate('ui.tutorial.warning.unlock');
			desc = desc.replace('[nbCard]', dataGlobalHelp.cardParent);
			this.attrBinding.title = translate.translate('ui.tutorial.warning.locked');
			this.attrBinding.text = desc;
		}
		else if(type === 'FEEDBACK_ALREADY_PLAYED'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'normal';
			this.attrBinding.btnKey = 'ui.ok';
			this.attrBinding.text = translate.translate('ui.tutorial.warning.card_already_selected');
		}
		
		
		else if(type === 'FEEDBACK_POINTS'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'normal';
			this.attrBinding.btnKey = 'ui.ok';
			this.attrBinding.text = translate.translate('ui.tutorial.warning.points');
		}
		else if(type === 'FEEDBACK_LINKED'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'confirm';
			this.attrBinding.btnKeyNo = 'ui.tutorial.warning.linked.no';
			this.attrBinding.btnKeyYes = 'ui.tutorial.warning.linked.yes';
			
			this.attrBinding.title = translate.translate('ui.tutorial.warning.linked.title');
			
			let desc = translate.translate('ui.tutorial.warning.linked.text');
			if(dataGlobalHelp.cardLinks.length === 1) desc = desc.replace('[nbCard]', dataGlobalHelp.cardLinks[0]);	//singular
			else desc = desc.replace('[listCards]', dataGlobalHelp.cardLinks.join(', '));	//plural
			
			this.attrBinding.text = desc;
		}
		else if(type === 'FEEDBACK_CONFIRM'){
			this.attrBinding.bubble = 'normal';
			this.attrBinding.button = 'confirm';
			this.attrBinding.btnKeyNo = 'ui.tutorial.warning.end.no';
			this.attrBinding.btnKeyYes = 'ui.tutorial.warning.end.yes';
			this.attrBinding.text = translate.translate('ui.tutorial.warning.end.text');
			
		}
		else if(type === 'TIME'){
			this.attrBinding.bubble = 'frame';		//todo
			//ui.tutorial.warning.time.text
			// ui.tutorial.warning.time.subtitle
			this.attrBinding.button = 'normal';
			this.attrBinding.charVisible = false;
			this.attrBinding.btnKey = 'ui.tutorial.warning.time.gotit';
			
		}
	}
	
	
	public clickConfirm(answer:boolean):void
	{
		let type = dataGlobalHelp.type;
		let closePopup:boolean = true;
		let contents = dataGlobalHelp.pedagogicContents;
		let navigate = false;
		
		if(type === 'WELCOME'){
			this.indexSlide++;
			if(this.indexSlide < this.contentIntro.length){
				closePopup = false;
				this.transitionAndUpdate(false);
			}
			//last slide
			else{
				if(!dataGlobalHelp.cancelNavigation){
					navigate = true;
					router.navigate('new-game');
				}
				dataGlobalHelp.cancelNavigation = false;
			}
			
		}
		else if(type === 'TUTORIAL'){
			this.indexSlide++;
			if(this.indexSlide < this.contentTuto.length){
				closePopup = false;
				this.transitionAndUpdate(false);
			}
			//last slide
			else{
				if(!dataGlobalHelp.cancelNavigation){
					navigate = true;
					router.navigate('create-players');
				}
				dataGlobalHelp.cancelNavigation = false;
			}
		}
		
		if(answer && type === 'FEEDBACK_CONFIRM'){
			
			closePopup = false;
			
			if(contents.length > 0){
				dataGlobalHelp.type = 'FEEDBACK_EDUCATION';
				this.transitionAndUpdate(true);
			}
			else{
				//close modal
				closePopup = true;
			}
		}
		else if(answer && type === 'FEEDBACK_EDUCATION'){
			this.indexSlide++;
			if(this.indexSlide < contents.length){
				closePopup = false;
				let transitionCharacter = contents[this.indexSlide].character !== contents[this.indexSlide - 1].character;
				this.transitionAndUpdate(transitionCharacter);
			}
			else{
				navigate = true;
				closePopup = true;
			}
		}
		
		
		if(closePopup){
			setTimeout(() => {
				router.navigate(null, 'modal');
			}, navigate ? 180 : 0);
			
			//reset setting to be consumed once only (like arguments)
			if(answer && dataGlobalHelp.callbackConfirm){
				dataGlobalHelp.callbackConfirm();
			}
			else if(!answer && dataGlobalHelp.callbackCancel){
				dataGlobalHelp.callbackCancel();
			}
			dataGlobalHelp.callbackConfirm = null;
			dataGlobalHelp.callbackCancel = null;
		}
		
		dataGlobalHelp.fastTransition = false;
	}
	
	private transitionAndUpdate(transitionCharacter:boolean):void
	{
		const t:GSAPTimeline = new gsap.core.Timeline();
		t.to('.bubble.normal', {y: 250, opacity:0, duration: 0.25});
		if(transitionCharacter) t.to('.character', {y: 450, opacity:0, duration: 0.25}, '>-0.25');
		
		//transition
		setTimeout(() => {
			this.onUpdate();
		}, 260);
		
		if(transitionCharacter){
			t.to('.character', {y: 0, opacity:1, duration: 0.5, ease: "back.out(1.2)"}, '>+0.3');
			t.to('.bubble.normal', { y: 0, opacity: 1, duration: 0.5, ease: "back.out(1.8)" }, '>-0.3');
		}
		else{
			t.to('.bubble.normal', { y: 0, opacity: 1, duration: 0.5, ease: "back.out(1.8)" }, '>+0.3');
		}
	}
	
	public getImgFile(charFile:string):string
	{
		return `url(theme-iso/base/assets/images/characters/${charFile})`;
	}
	
}



let moduleClass:any = HelpModule;
try{ moduleClass = getDependency<HelpModule>(require('theme-iso/base/modules/pages/help/help.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./help.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/help/help.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./help.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/help/help.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/help/help.config.user').default;} catch(e){}
config = resolveConfig('help', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./help.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/help/help.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'help', template, config, anims, css)