import { PedagogicalContent } from "theme-iso/base/interface/interfaces"

export const dataGlobalHelp:HelpConfig = {
	type: null,
}


export interface HelpConfig{
	type:'FEEDBACK_EDUCATION' | 
				'TUTORIAL' | 'WELCOME' | 'HELP_CARD_CHOICE' | 
				'FEEDBACK_LOCKED_ROUND' | 
				'FEEDBACK_LOCKED_PARENT' | 
				'FEEDBACK_ALREADY_PLAYED' | 
				'FEEDBACK_POINTS' | 'FEEDBACK_LINKED' | 'FEEDBACK_CONFIRM' | 'TIME' | 'END',
	pedagogicContents?:PedagogicalContent[],
	cancelNavigation?:boolean,
	callbackConfirm?:Function,
	callbackCancel?:Function,
	fastTransition?:boolean,
	cardParent?:string,
	cardLinks?:string[],
}