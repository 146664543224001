import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './bgmodule.config';

export class BgmoduleModule extends Module<typeof config>{
	
	override onInit(): void
	{
		
		
	}
	
	public update(theme:string, filename:string):void
	{
		let themestr = theme ? `theme-${theme}` : 'theme';
		this.attrBinding.imageStyle = `url('${themestr}/base/assets/flowchart/backgrounds/${filename}')`;
	}
}



let moduleClass:any = BgmoduleModule;
try{ moduleClass = getDependency<BgmoduleModule>(require('theme-iso/base/services/background/bgmodule/bgmodule.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./bgmodule.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/services/background/bgmodule/bgmodule.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./bgmodule.module.scss'));
try{ css += getCSSTemplate(require('theme-iso/base/services/background/bgmodule/bgmodule.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/background/bgmodule/bgmodule.config.user').default;} catch(e){}
config = resolveConfig('bgmodule', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./bgmodule.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/services/background/bgmodule/bgmodule.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'bgmodule', template, config, anims, css)