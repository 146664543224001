export default {

	//default config
	default : {
		baseUrl : 'https://iso.dowino.com/',
		defaultUID : 'upwjtUqBLS3FDo0wL79Ns8mTQVGxU3ql',
		
		mock: {
			'api/get_data:post' : null,
			'api/preloadList:post' : null,
		}
	},
	
	

	dev: {
		/* 
		mock : {
			'api/get_data:post' : "theme-test/base/assets/mock/getdata.mock.json",
			'api/preloadList:post' : "theme-test/base/assets/mock/get-preload-list.mock.json",
		}
		 */
		mock: {
			'api/get_data:post' : null,
			'api/preloadList:post' : null,
		}
		
	},
	prod:{
		baseUrl : 'https://standardquest.iso.org/',
	}
}