/**
 * Dowimoteur
 * 
 * Override service "flowchart"
 * Created on Oct 27, 2023
 * 
 */

import router from "core/lib/router";
import { getServiceByID } from "core/lib/services";
import { PromptService } from "theme/base/services/prompt/prompt.service";
import { AudioService } from "theme/base/services/audio/audio.service";
import { BackgroundService } from "theme/base/services/background/background.service";
import { FlowChartService } from "theme/base/services/flowchart/flowchart.service";
import { UserDataService } from "theme/base/services/userdata/userdata.service";
import { DialogService } from "theme/packages/dialog/dialog.service";
import { MediaService } from "theme/base/services/media/media.service";
import { DataService } from "theme/base/services/data/data.service";

export default class FlowChartServiceSub extends FlowChartService{
	
	userdataService: UserDataService;


	override startSequence():void
	{
		console.log("test-start-sequence");
	}
	override endSequence():void
	{
		this.userdataService = getServiceByID('userdata');
		this.userdataService.resetGame();
		router.navigate('home');
	}

	override goToSequence(index: number): void
	{
		let promptService = this.getServiceInstance<PromptService>('prompt');
		let mediaService = this.getServiceInstance<MediaService>('media');
		let bgService = this.getServiceInstance<BackgroundService>('background');
		let dataService = this.getServiceInstance<DataService>('data');

		let viewportPrompt = <HTMLElement>document.querySelector('.prompt-viewport');
		let viewportMedia = <HTMLElement>document.querySelector('.media-viewport');
		let bgViewport = <HTMLElement>document.querySelector('.background-viewport');
		let pcViewport = <HTMLElement>document.querySelector('.pc-viewport');

		promptService.destroy();
		mediaService.destroy();

		this.userdataService.updateData('indexSequence', index);
		this.reset(dataService.sequences[index].nodes, null, this.flowchartVariables);

		bgService.reset(bgViewport);
		promptService.reset(viewportPrompt);
		mediaService.reset(viewportMedia);
	}


	override getModulesId(){
		return {
			'userdata': this.getServiceInstance<UserDataService>('userdata'),
			'audio': this.getServiceInstance<AudioService>('audio'),
			'dialog': this.getServiceInstance<DialogService>('dialog'),
			'prompt': this.getServiceInstance<PromptService>('prompt'),
			'media': this.getServiceInstance<MediaService>('media'),
			'background': this.getServiceInstance<BackgroundService>('background'),
			'flowchart': this,
			'application': this,
		}
	}
}
