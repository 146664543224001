/**
 * Dowimoteur
 * 
 * Override module "loading"
 * Created on Apr 11, 2024
 * 
 */

import broadcaster from 'core/lib/broadcaster';
import { getMainConfig } from 'core/lib/config';
import { Evt } from 'theme/base/config/events';
import { LoadingModule } from 'theme/base/modules/pages/loading/loading.module';
let mainConfig = getMainConfig();

export default class LoadingSub extends LoadingModule{
	
	private i:NodeJS.Timer;
	override onInit(): void
	{
		let dom = this.dom.querySelector('.center_container p');
		let count = 0;
		
		this.i = setInterval(function() {
			dom.innerHTML = 'Loading';
			for(let i = 0; i<count; i++) dom.innerHTML += '.';
			count++;
			if(count > 3) count = 0;
		}.bind(this), 350);
	}
	public onDestroy(): void {
		clearInterval(this.i);
	}
}