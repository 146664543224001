/**
 * Dowimoteur
 * 
 * Base module "header"
 * Created on Mar 26, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { getMainConfig, resolveConfig } from 'core/lib/config';
import * as configSystem from './header.config';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';
import { dataGlobalHelp } from '../../pages/help/help.global';

export class HeaderModule extends Module<typeof config>{
	
	private interval:NodeJS.Timer;
	private timerValue:number;
	private userdataService:UserDataService;
	private gameService:GameService;
	
	override onInit(): void
	{
		this.timerValue = -1;
		this.attrBinding.timerDisplay = null;
		this.userdataService = this.getServiceInstance<UserDataService>('userdata');
		this.gameService = this.getServiceInstance<GameService>('game');
		
		
		router.addListener((outlet:string, route:string) => {
			let visible:boolean;
			if(outlet === 'root'){
				visible = [
					'new-game', 
					'create-players', 
					'setup-round', 
					'card-choice',
					'results',
				].indexOf(route) > -1;
				
			}
			else if(outlet === 'modal'){
				visible = false;
			}
			
			this.dom.style.display = visible ? 'flex' : 'none';
			
			let hideLeft = (route === 'card-choice' || route === 'results');
			this.setMode(hideLeft);
		});
		
		broadcaster.addListener('RESET_TIMER', function(){
			this.resetTime();
		}.bind(this));
		
		broadcaster.addListener('START_TIMER', function(){
			this.startTimer();
		}.bind(this));
		broadcaster.addListener('SET_TIMER', function(value:number){
			this.resetTime(value);
		}.bind(this));
		
		
		broadcaster.addListener('PAUSE_TIMER', function(){
			this.stopTimer();
		}.bind(this));
	}
	
	public onDestroy(): void {
		broadcaster.removeListenerByInstance('RESET_TIMER', this);
		broadcaster.removeListenerByInstance('START_TIMER', this);
		broadcaster.removeListenerByInstance('SET_TIMER', this);
		broadcaster.removeListenerByInstance('PAUSE_TIMER', this);
	}
	
	setMode(hideLeft:boolean):void
	{
		let btnback:HTMLElement = this.dom.querySelector('.btn-back');
		let line:HTMLElement = this.dom.querySelector('.header-line2');
		btnback.style.display = hideLeft ? 'none' : 'block';
		line.style.display = hideLeft ? 'none' : 'block';
	}
	
	
	clickBack():void
	{
		let state = router.getState('root');
		if(state === 'new-game') router.navigate('home');
		else if(state === 'terms') router.navigate('home');
		else if (state === 'select-game') router.navigate('home');
		else if(state === 'create-players') router.navigate('new-game');
		else if(state === 'card-choice') router.navigate('setup-round');
		else if(state === 'results') router.navigate('card-choice');
		
		else router.prev();
	}
	clickPause():void
	{
		broadcaster.dispatchEvent('CLICK_PAUSE')
	}
	
	
	
	resetTime(value:number = 0):void
	{
		if(this.interval) clearInterval(this.interval);
		this.timerValue = value;
		this.attrBinding.timerDisplay = this.formatTime(this.timerValue);
	}
	
	startTimer():void
	{
		this.interval = setInterval(this.onTimer.bind(this), 1000);
	}
	stopTimer():void
	{
		clearInterval(this.interval);
		this.attrBinding.timerDisplay = this.formatTime(this.timerValue);
	}
	
	
	private onTimer():void
	{
		this.timerValue++;
		this.attrBinding.timerDisplay = this.formatTime(this.timerValue);
		
		//udpate userdata with current time
		let game = this.userdataService.getCurrentGame();
		if(game){
			game.currentTime = this.timerValue;
		}
		
		//trigger time alert
		if(this.timerValue === this.gameService.config.timeAlert){
			dataGlobalHelp.type = 'TIME';
			dataGlobalHelp.fastTransition = true;
			router.navigate('help', 'modal');
		}
	}
	
	
	formatTime(value:number):string
	{
		let hr:string = Math.floor(value / 3600)+'';
		if(hr.length < 2) hr = '0' + hr;
		
		let mn:string = Math.floor(value / 60)+'';
		if(mn.length < 2) mn = '0' + mn;
		
		return `${hr}:${mn}`;
	}
	
	
}



let moduleClass:any = HeaderModule;
try{ moduleClass = getDependency<HeaderModule>(require('theme-iso/base/modules/ui/header/header.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./header.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/ui/header/header.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./header.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/ui/header/header.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/ui/header/header.config.user').default;} catch(e){}
config = resolveConfig('header', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./header.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/ui/header/header.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'header', template, config, anims, css)