import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import { Power0, gsap } from 'gsap';
import { RoughEase } from 'gsap/EasePack';


export class LoadingModule extends Module<typeof config>{
	
	override onInit(): void {
		
	}

	public goToLanding(){
		router.navigate('landing');
	}
	
}



let moduleClass:any = LoadingModule;
try{ moduleClass = getDependency<LoadingModule>(require('theme-iso/base/modules/pages/loading/loading.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./loading.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/loading/loading.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./loading.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/loading/loading.module.user.scss'));} catch(e){}
injectStyle(css);

import * as configSystem from './loading.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/loading/loading.config.user').default;} catch(e){}
config = resolveConfig('loading', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./loading.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/loading/loading.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};




registerModuleDef(moduleClass, 'loading', template, config, anims, css)