export default {
	
	default : {
		historyMock:[
			//jjj1
			[
				[2, 4, 8, 1, 5, 8, 1],
				[3, 6, 9, 3, 10, 10, 4],
				[5, 8, 10, 3, 11, 13, 6],
			],
			//le joueur numéro 2
			[
				[1, 3, 7, 0, 4, 7, 0],				//-1
				[4, 7, 10, 4, 11, 11, 5],			//+1
				[1, 8, 8, 4, 10, 14, 5],			//alternate
				//round 1 : 2nd
				//round 2 : 1st
				//round 3 : 2nd
			],
			//vincenzo
			[
				[2, 4, 8, 1, 5, 8, 1],				//same
				[3, 6, 9, 3, 10, 10, 4],
				[5, 8, 10, 3, 11, 13, 6],
			],
			[
				[5, 8, 10, 4, 7, 9, 3],				//highest
				[3, 6, 9, 3, 10, 10, 4],			//go down ???
				[5, 10, 11, 5, 8, 9, 5],			//a bit up again
			],
			[
				[2, 4, 8, 1, 5, 8, 16],
				[3, 6, 9, 3, 10, 10, 14],
				[5, 8, 10, 3, 11, 13, 16],
			]
		]
	},
}