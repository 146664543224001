export default{
	
	//default config
	default: {
		enableDebugModule: false,
		checkRuntimeErrors: false,
		environments: ['dev', 'prod', 'staging'],
		defaultLang: 'fr_FR',
		
		//base size (before scale)
		uiWidth: 1920,
		uiHeight: 1080,
		debugMode: false,
		
	},
	
	staging: {
		enableDebugModule: false,
		checkRuntimeErrors: true,
		debugMode: false,
	},
	
	dev: {
		enableDebugModule: false,
		checkRuntimeErrors: true,
		debugMode: true,
	}
}