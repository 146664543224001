import gsap from 'gsap';
import { getDependency } from './core';

let routeConfigSystem:any = getDependency<string>(require('theme/base/config/route.transitions'));
let routeConfigUser:any; try{ routeConfigUser = getDependency<string>(require('theme-iso/base/config/route.transitions.user'));} catch(e){}
let routeConfig = {...routeConfigSystem, ...routeConfigUser};



class Animator{
	
	private resolveCombinations:{result:string, score:number, lenSufix:number}[];
	private masks:string[][] = [];
	private chainingDelay:number;
	
	private gTimeline:GSAPTimeline;
	private syncTimeline:GSAPTimeline;
	private timeline:GSAPTimeline;
	
	public transition():void
	{
		
	}
	
	
	/**
	 * match a list of params to the function names defined into animations.ts files
	 * 
	 * resolves a list of params [p1, p2, p3] into a function name p1_p2_p3
	 * 
	 * each of those property can be substituted with an "x" (wildcard)
	 * this will enumerate all the possible combination ordered by priority
	 * 
	 * this is based on a mask 101 result in p1_x_p3 (0 gets replaced by "x")
	 * 
	 * there are 2 caches : 
	 * 1 for the list of masks (1 list per number of params)
	 * 1 for the functions (params => function)
	 * 
	 * there are some priority rules used to order the masks
	 * - the more 1 in a mask means a higher priority
	 * - param at the end of the chain have a higher priority (x_x_p3 has higher priority than x_p2_x)
	 * 
	 * the first param (p1) must always be defined (can't use a wildcard x)
	 * 
	 */
	
	
	private cacheResolveFunction:{[key:string]:Function} = {};
	
	public resolveFunction(obj:any, props:any[]):Function
	{
		//masks are kept in a cache, so generated only once per number of params (n)
		let n = props.length;
		if(!this.masks[n]){
			this.masks[n] = this.createCombinationSeq(n);
		}
		
		//2nd cache that store the result returned by this function
		let output:Function = null;
		let keyCache:string = props.join('_');
		if(this.cacheResolveFunction[keyCache]) return this.cacheResolveFunction[keyCache];
		else{
			for(var i in this.masks[n]){
				let funcName = this.generateFuncFromMask(this.masks[n][i], props);
				if(obj[funcName]){
					output = obj[funcName];
					break;
				}
			}
		}
		
		this.cacheResolveFunction[keyCache] = output;
		return output;
	}
	
	
	
	/**
	 * read a mask of type 101
	 * combine it with a list of parameters (in, position1, nicky)
	 * return a function name where 0 has been replaced by x
	 * => in_x_nicky
	 * (as defined in the animations.ts files)
	 */
	private generateFuncFromMask(mask:string, props:any[]):string
	{
		let output = '';
		for(let i = 0; i<mask.length; i++){
			if(output) output += '_';
			output += (mask[i] === '1') ? props[i] : 'x';
		}
		return output;
	}
	
	
	
	
	
	private createCombinationSeq(n:number):string[]
	{
		let result:string = '';
		this.resolveCombinations = [];
		this.createCombinationSeqRec(0, n, false, 1, 1, result);
		this.resolveCombinations.sort((a, b) => a.score < b.score ? 1 : -1);
		
		let combinations:string[] = [];
		for(let j in this.resolveCombinations){
			let obj = this.resolveCombinations[j];
			combinations.push(obj.result);
			
			for(let k = 1; k <= obj.lenSufix; k++){
				combinations.push(obj.result.substr(0, obj.result.length - k));
			}
		}
		return combinations;
	}
	
	
	
	
	
	private createCombinationSeqRec(n:number, total:number, useWildCard:boolean, score:number, lenSufix:number, result:string):void
	{
		if(n === total) return;
		if(!useWildCard) lenSufix = 0;
		result += (useWildCard ? '0' : '1');
		n++;
		
		if(n === total && score > 0){
			console.log('result : '+result+', score : '+score+', suf : '+lenSufix);
			this.resolveCombinations.push({result, score, lenSufix});
		}
		
		this.createCombinationSeqRec(n, total, true, score, lenSufix + 1, result);
		this.createCombinationSeqRec(n, total, false, score + 1 + n * 0.1, lenSufix, result);
	}
	
	
	
	
	/**
	 * id-module => idModule
	 */
	private toCamelCase(str:string)
	{
		return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		}).replace(/[\s-]+/g, '');
	}
	
	
	/**
	 * return a list of errors
	 * props are the required
	 */
	public getErrors(obj:any, props:string[], args:string[], filename:string):string[]
	{
		let errors = [];
		for(let i in props){
			let prop = props[i];
			if(!obj[prop]) errors.push(`File "${filename}" must contain function : ${prop}:(${args.join(', ')}):GSAPTimeline`);
			else if(obj[prop].length !== args.length){
				errors.push(`${filename} function ${prop}() must contain ${args.length} arguments (${args.join(', ')})`);
			}
		}
		return errors;
	}
	
	
	/**
	 * todo : centralize with templateMacro
	 */
	public formatError(errors:string[]):string
	{
		let output = `${errors.length} errors in animations files ::\n\n`;
		output += '- ' + errors.join('\n- ') + '\n.';
		return output;
	}
	
	
	
	public setChainingDelay(delay:number):void
	{
		this.chainingDelay = delay;
	}
	
	
	public reset():void
	{
		this.gTimeline = new gsap.core.Timeline();
		this.syncTimeline = new gsap.core.Timeline();
	}
	
	public setSyncMode(value:boolean):void
	{
		this.timeline = value ? this.syncTimeline : this.gTimeline;
	}
	
	
	public addTimeline(tl:GSAPTimeline, chainingDelay:number = NaN):void
	{
		if(!this.timeline) this.timeline = new gsap.core.Timeline();
		
		if(isNaN(chainingDelay)) chainingDelay = this.chainingDelay;
		if(!chainingDelay) chainingDelay = 0;
		let position = '>' + chainingDelay;
		this.timeline.add(tl, position);
	}
	
	public completeAnimations():void
	{
		this.syncTimeline.totalProgress(1);
	}
	
	
}

const animator = new Animator()
export default animator;