import { registerServiceDef, Service } from 'core/lib/services';
import { resolveConfig } from 'core/lib/config';
import { getDependency } from 'core/lib/core';


export class TplFunctionsService extends Service<typeof config>{
	
	
	multiply(a:number, b:number):number
	{
		return a*b;
	}
	
	
	
}



let serviceClass:any = TplFunctionsService;
try{ serviceClass = getDependency<TplFunctionsService>(require('theme-iso/base/services/tpl-functions/tpl-functions.service.user.ts')); }catch(e){}


import * as configSystem from './tpl-functions.config';
let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/services/tpl-functions/tpl-functions.config.user').default;} catch(e){}
config = resolveConfig('tpl-functions', configSystem.default, configUser, process.env.RUNTIME_ENV)

registerServiceDef(serviceClass, 'tpl-functions', config)
