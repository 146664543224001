// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../assets/images/logo-iso.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../assets/images/logo-dowino.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../assets/images/logo-game.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"background purple-bottom-left\">\r\n\r\n\t<div class=\"center_container container container-iso\">\r\n\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" />\r\n\t\t<p>presents...</p>\r\n\t</div>\r\n\t\r\n\t \r\n\t\r\n\t<div class=\"center_container container container-dowino\">\r\n\t\t<p>...a game developed by</p>\r\n\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" />\r\n\t</div>\r\n\t\r\n\t\r\n\t<div class=\"center_container container container-select-lang\">\r\n\t\t<img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" />\r\n\t\t<div class=\"lang\">\r\n\t\t\t<div [import]=\"select-language\"></div>\r\n\t\t\t<button (click)=\"clickOK()\" class=\"btn-square clickarea-20\">ok</button>\r\n\t\t</div>\r\n\t</div>\r\n</div>";
// Exports
export default code;