/**
 * Dowimoteur
 * 
 * Override service "api"
 * Created on Mar 4, 2024
 * 
 */

import { ApiService } from "theme/base/services/api/api.service";

export default class ApiServiceSub extends ApiService{
	
	override onViewReady(): void {
		console.log('ApiServiceSub.onViewReady');
	}
}
