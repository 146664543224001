/**
 * Dowimoteur
 * 
 * Base module "new-game"
 * Created on Mar 15, 2024
 * 
 */

import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './new-game.config';
import { UserDataService } from 'theme/base/services/userdata/userdata.service';
import { GameService } from 'theme-iso/base/services/game/game.service';
import { dataGlobalHelp } from '../../help/help.global';
import gsap from 'gsap';
import { TranslationService } from 'theme/base/services/translation/translation.service';
export class NewGameModule extends Module<typeof config>{
	
	private gameService:GameService;
	private translateService:TranslationService;
	
	override onInit(): void
	{
		this.translateService = this.getServiceInstance<TranslationService>('translation');
		this.gameService = this.getServiceInstance('game');
		this.attrBinding.indexGame = -1;
		this.attrBinding.name = "";
		this.attrBinding.legend = this.translateService.translate('ui.newgame.scenario.text2');
		
		//start timer
		broadcaster.dispatchEvent('RESET_TIMER');
		broadcaster.dispatchEvent('START_TIMER');
	}
	
	onUpdateName(evt:Event):void
	{
		this.attrBinding.name = (<any>evt.currentTarget).value;
	}
	
	select(index:number):void
	{
		this.attrBinding.indexGame = index;
		this.attrBinding.legend = this.translateService.translate('ui.tuto.scenario.' + (index+1));
	}
	
	private btnEnabled:boolean;
	isFormComplete(indexGame:number, name:string):boolean
	{
		let btnEnabled = indexGame !== -1 && name.length > 0;
		
		//if becomes enabled, play a small animation
		if(!this.btnEnabled && btnEnabled){
			new gsap.core.Timeline()
			.fromTo('button.btn', {scale:0.88}, {scale:1, duration:0.2, ease: "back.out(2.0)"})
		}
		
		this.btnEnabled = btnEnabled;
		return btnEnabled;
	}
	
	
	
	
	clickValidate():void
	{
		let userdata = this.getServiceInstance<UserDataService>('userdata');
		let game = userdata.addGame();
		game.name = this.attrBinding.name;
		game.typeGame = this.attrBinding.indexGame === 0 ? 'A' : 'B';
		
		game.scenarioFactors = this.gameService.config.scenarioFactors[<('A'|'B')>game.typeGame];
		game.ctFactorCost = this.gameService.config.ctFactorCost;
		game.ctFactorReward1 = this.gameService.config.ctFactorReward1;
		game.ctFactorReward2 = this.gameService.config.ctFactorReward2;
		
		
		this.gameService.initGame();
		
		dataGlobalHelp.type = 'TUTORIAL';
		router.navigate('help', 'modal');
		
	}
}



let moduleClass:any = NewGameModule;
try{ moduleClass = getDependency<NewGameModule>(require('theme-iso/base/modules/pages/menu/new-game/new-game.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./new-game.module.html'));
try{ template = getDependency<string>(require('theme-iso/base/modules/pages/menu/new-game/new-game.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./new-game.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/base/modules/pages/menu/new-game/new-game.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/base/modules/pages/menu/new-game/new-game.config.user').default;} catch(e){}
config = resolveConfig('new-game', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./new-game.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/base/modules/pages/menu/new-game/new-game.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};



registerModuleDef(moduleClass, 'new-game', template, config, anims, css)