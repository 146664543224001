import gsap, { Back } from 'gsap';
export default {
	
	in:(dom:HTMLElement):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		t.to(dom, 
			{opacity:1, top:0, duration:0.55, ease:Back.easeOut}
		);
		return t;
	},
	
	out:(dom:HTMLElement):GSAPTimeline =>{
		
		const t:GSAPTimeline = new gsap.core.Timeline();
		t.to(dom, 
			{opacity:0, top:230, duration:0.3}
		);
		return t;
	},
}