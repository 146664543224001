import { getDependency, Module, registerModuleDef } from 'core/lib/core';
import broadcaster from 'core/lib/broadcaster';
import router from 'core/lib/router';
import { getCSSTemplate, injectStyle } from 'core/lib/styles';
import { resolveConfig } from 'core/lib/config';
import * as configSystem from './dialog-footer.config';
import { InteractiveListItem, PlayerTalkType } from 'theme/base/services/flowchart/flowchart.service';
import { Evt } from 'theme/base/config/events';

export class DialogFooterModule extends Module<typeof config>{
	
	protected playerName:string;
	protected theme:string;
	override onInit(): void {
		
		this.attrBinding.content_thought = null;
		this.attrBinding.content_simple = null;
		this.attrBinding.playerName = null;
		this.attrBinding.type = null;
		
	}
	
	public init(theme:string, playerName:string):void
	{
		this.theme = theme;
		this.attrBinding.playerName = playerName;
	}
	
	
	public update(type:PlayerTalkType, content:string):void
	{
		console.log('update '+type+', '+content);
		this.attrBinding.type = type;
		if(type === 'thought') this.attrBinding.content_thought = content;
		else if(type === 'simple') this.attrBinding.content_simple = content;
		else throw new Error(`type can't be equal to ${type}`)
	}
	
	public updateChoices(choices:InteractiveListItem[]):void
	{
		this.attrBinding.type = 'choice';
		this.attrBinding.choices = choices;
	}
	
	public displayBtn():void
	{
		if(!this.attrBinding){
			return;
		}
		this.attrBinding.type = "btn";
	}
	
	public getCharacterImg(playerName:string):string
	{
		let themestr = this.theme ? `theme-${this.theme}` : 'theme';
		return `url(${themestr}/base/assets/flowchart/npc/icon_${playerName}.png)`;
	}
	
	protected getChoiceLabel(i:number):string
	{
		return String.fromCharCode(i + 97).toUpperCase();
	}
	
	protected getChoiceColor(i:number):string
	{
		if(i === 0) return '#2b3860';
		else if(i === 1) return '#602b42';
		else if(i === 2) return '#33602b';
		else throw new Error('only 3 possibilities of answers were planned');
		
	}
	
	
	public validate():void
	{
		broadcaster.dispatchEvent('DIALOG_FOOTER_VALIDATE', [-1]);
        this.attrBinding.type = "none";
	}
	public clickChoice(index:number):void
	{
		broadcaster.dispatchEvent('DIALOG_FOOTER_VALIDATE', [index]);
        this.attrBinding.type = "none";
	}
}


export enum FooterType{
	choice = 'choice',
	thought = 'thought',
	simple = 'simple',
	btn = 'btn',
}



let moduleClass:any = DialogFooterModule;
try{ moduleClass = getDependency<DialogFooterModule>(require('theme-iso/packages/dialog/dialog-footer/dialog-footer.module.user.ts')); }catch(e){}

let template:string = getDependency<string>(require('./dialog-footer.module.html'));
try{ template = getDependency<string>(require('theme-iso/packages/dialog/dialog-footer/dialog-footer.module.user.html'));} catch(e){}

let css = getCSSTemplate(require('./dialog-footer.module.scss'));
try{ css = getCSSTemplate(require('theme-iso/packages/dialog/dialog-footer/dialog-footer.module.user.scss'));} catch(e){}
injectStyle(css);

let config = configSystem.default.default;
let configUser; try{ configUser = require('theme-iso/packages/dialog/dialog-footer/dialog-footer.config.user').default;} catch(e){}
config = resolveConfig('dialog-footer', configSystem.default, configUser, process.env.RUNTIME_ENV)

let animsSystem:any = getDependency<string>(require('./dialog-footer.animations'));
let animsUser:any; try{ animsUser = getDependency<string>(require('theme-iso/packages/dialog/dialog-footer/dialog-footer.animations.user'));} catch(e){}
let anims = {...animsSystem, ...animsUser};




registerModuleDef(moduleClass, 'dialog-footer', template, config, anims, css)