import { Evt } from "theme/base/config/events";
import debugAudioConfig from "theme/base/modules/debug/submodules/debug-audio/debug-audio.config";
import broadcaster from "./broadcaster";
import { mergeConfigEnv } from "./config";
import { MacroDef, BindingService, getModuleDefByClass, getModuleDefByID, ModuleDef, ModuleInstance, TplExpr, Module } from "./core";
import { getPipe, getTplFunc, TplFunction } from "./services";
import { applyEventBinding, parseMacro, resolveMacroBinding, updateDomAttr } from "./templateMacro";
export class ModuleHandler{
	
	
	/**
	 * instanciate module class
	 * define config based on env
	 * parse view and setup for data-bindings
	 * call onInit hook
	 * returns a module instance object
	 */
	instanciateModule(_class:any, parent:HTMLElement = null, doInit:boolean = true):ModuleInstance
	{
		if(!parent) parent = document.createElement('div');
		
		//instanciate muodule class
		let moduleDef:ModuleDef = getModuleDefByClass(_class);
		let output:ModuleInstance = {
			classInstance: new _class(),
			dom: parent,
			childrens:{},
		};
		
		
		//listen for changes on the module.classInstance.attrBinding
		//when a property changes, find the list of ? for that module
		
		let _this = this;
		let targetObj:any = {};
		output.classInstance.attrBinding = new Proxy(targetObj, {
			set(obj, prop, value):boolean {
				// console.log('proxy set '+(<string>prop)+', '+value);
				targetObj[prop] = value;
				
				//get the list of macro bindings for which the list of args contains the property that has been upated
				//(attr binding can only have 1 expression (validated at parsing time))
				
				let macroUpdates:MacroDef[] = [];
				for(let i in moduleDef.macroBindAttr){
					let macroDef = moduleDef.macroBindAttr[i];
					let mustUpdate = (macroDef.expressions[0]).variables.includes(prop);
					if(mustUpdate) macroUpdates.push(macroDef);
				}
				
				
				
				//update those macro
				for(let i in macroUpdates){
					_this.updateMacroBinding(macroUpdates[i], output);
				}
				
				//update loops
				for(let i in moduleDef.macroLoop){
					let macroDef = moduleDef.macroLoop[i];
					if (macroDef.value.substring(0, prop.toString().length) === prop.toString()) {
						output.classInstance.updateLoop(prop.toString());
					}
				}
				
				return true;
			}
		});
		
		
		
		
		
		//register an event that listens to external state update (UPDATE_FUNCTION_STATE)
		//and update the list of macro that use the associated func
		//(inputs might not change, but some external state element of the function could change)
		
		let callback = (func:string) => {
			//get all the macro having an expression that uses func
			
			let macroUpdates:MacroDef[] = [];
			for(let i in moduleDef.macroBindAttr){
				let macroDef = moduleDef.macroBindAttr[i];
				if((macroDef.expressions[0]).functions.includes(func)) macroUpdates.push(macroDef);
			}
			
			for(let i in macroUpdates){
				this.updateMacroBinding(macroUpdates[i], output);
			}
		}
		broadcaster.addListener(Evt.UPDATE_FUNCTION_STATE, callback, output.classInstance);
		
		
		
		output.dom.classList.add(moduleDef.id);
		output.dom.innerHTML = moduleDef.template;
		
		//apply event association
		//here value can be an array (separated by ,)
		for(let i in moduleDef.macroEvents){
			let macroDef = moduleDef.macroEvents[i];
			applyEventBinding(output.classInstance, output.dom, macroDef.id, macroDef.key, macroDef, output.classInstance.attrBinding, null, 0);
		}
		
		//apply import
		for(let i in moduleDef.macroImports){
			let macroDef = moduleDef.macroImports[i];
			let submodule = this.applyImport(output.dom, macroDef.id, macroDef.key, macroDef.value);
			output.childrens[macroDef.id] = submodule;
			
			//to allow selector element[import=id_module]
			submodule.dom.setAttribute('import', macroDef.value);
		}
		
		//associate dom data to module instance
		output.classInstance.def = moduleDef;
		output.classInstance.config = moduleDef.config;
		output.classInstance.setDomElements(output.dom, output.childrens);
		output.classInstance.moduleInstance = output;
		
		
		//call on init hook
		if(doInit) output.classInstance.onInit();
		
		
		
		//for every macro binding expressions that has a function
		//initialize binding
		//todo : this should normally be only for tplFunction ?
		//or for function that rely on external state (mark them ?)
		
		let map:any = {};
		for(let i in moduleDef.macroBindAttr){
			//todo migration
			let macroDef = moduleDef.macroBindAttr[i];
			if(macroDef.expressions[0].functions){
				this.updateMacroBinding(macroDef, output);
			}
		}
		
		
		//call on init hook
		if(doInit) output.classInstance.onViewReady();
		
		
		return output;
	}
	
	
	
	
	/**
	 * this function is called in 2 scenarios :
	 * - instance.attrBinding has changed and proxy event is triggered
	 * - Evt.UPDATE_FUNCTION_STATE was dispatched 
	 */
	updateMacroBinding(macro:MacroDef, module:ModuleInstance):void
	{
		//todo migrationzetzertz
		let value = resolveMacroBinding(module.classInstance.def, macro, module.classInstance, module.classInstance.attrBinding, null, 0);
		
		//if macro element is a child module and has declared property
		//pass it recursively to the child
		let child:ModuleInstance = module.childrens[macro.id];
		if(child && child.classInstance.attrBinding[macro.key] !== undefined){
			child.classInstance.attrBinding[macro.key] = value;
		}
		//else, update dom value
		else{
			let dom = <HTMLElement>module.dom.querySelector(`#${macro.id}`);
			updateDomAttr(dom, macro.key, value);
		}
	}
	
	
	
	
	
	
	
	/**
	 * free memory of module (instance + dom)
	 * recursively call childrens
	 */
	
	destroyModule(module:ModuleInstance)
	{
		module.classInstance.onDestroy();
		
		//efficient removal of events (linear time)
		broadcaster.removeListenerByInstance(Evt.UPDATE_FUNCTION_STATE, module.classInstance);
		
		module.classInstance.attrBinding = null;
		module.classInstance.config = null;
		
		module.classInstance = null;
		module.dom = null;
		module.childrens = {};
		
		for(let id in module.childrens){
			this.destroyModule(module.childrens[id]);
		}
		
	}
	
	
	moduleExists(id:string):boolean
	{
		return getModuleDefByID(id) !== undefined;
	}
	
	
	
	/**
	 * for testing only
	 */
	public processAllMacro(tpl:string):MacroDef[]
	{
		let output:MacroDef[] = [];
		let macroDef = null;
		while(macroDef = parseMacro(tpl, 'import')){
			output.push(macroDef);
			tpl = macroDef.tpl;
		}
		while(macroDef = parseMacro(tpl, 'event')){
			output.push(macroDef);
			tpl = macroDef.tpl;
		}
		while(macroDef = parseMacro(tpl, 'attr')){
			output.push(macroDef);
			tpl = macroDef.tpl;
		}
		return output;
	}
	
	
	
	
	/**
	 * remove all previous nodes in the container except 1 (for handling stacked transitions)
	 * and add the new node view
	 */
	inject(module:ModuleInstance|HTMLElement, container:HTMLElement = null)
	{
		if(!container) container = document.body;
		
		//secu : shouldn't happen, but if more than 1 (fast navigation maybe) : remove oldest element
		while (container.childElementCount > 1) container.removeChild(container.firstChild);
		
		let dom = (<any>module).dom || module;	//if static route : module.dom is null
		container.appendChild(dom);
		
	}
	
	empty(container:HTMLElement)
	{
		while (container.firstChild) container.removeChild(container.lastChild);
	}
	
	
	private applyImport(dom:HTMLElement, id:string, key:string, value:string):ModuleInstance
	{
		let container = <HTMLElement>dom.querySelector(`#${id}`);
		let moduleDef = getModuleDefByID(value);
		if(!moduleDef) throw new Error(`Module "${value}" doesnt exist`);
		let module = this.instanciateModule(moduleDef.class, container);
		return module;
	}
	
	
}

